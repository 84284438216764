import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import '../styles/PaymentsForm.css';
import { 
  FiDollarSign, 
  FiFileText, 
  FiSave, 
  FiTrash2, 
  FiArrowLeft,
  FiRefreshCw,
  FiAlertTriangle,
  FiCheck,
  FiInfo,
  FiUser,
  FiCalendar
} from 'react-icons/fi';

const PaymentsForm = () => {
  const { id } = useParams();
  const { getCookie, APP_URL } = useAuth();
  const { error, success, spinner, choice } = useNotify();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const navigate = useNavigate();

  // Validation schema
  const validationSchema = Yup.object({
    payment_amount: Yup.number()
      .required('Payment amount is required')
      .positive('Payment amount must be positive')
      .typeError('Payment amount must be a number')
  });

  // Initialize formik
  const formik = useFormik({
    initialValues: {
      invoice_id: '',
      payment_amount: '',
      payment_date: new Date().toISOString().split('T')[0] // Default to today
    },
    validationSchema,
    onSubmit: handleUpdatePayment
  });

  // Fetch payment details
  useEffect(() => {
    fetchPaymentDetails();
  }, [id]);

  // Fetch payment data
  const fetchPaymentDetails = async () => {
    setLoading(true);
    const { complete } = spinner('Loading payment details...');
    
    try {
      const response = await fetch(`${APP_URL}/payments/${id}`, {
        credentials: 'include',
      });
      
      if (response.ok) {
        const data = await response.json();
        
        formik.setValues({
          invoice_id: data.invoice_id,
          payment_amount: data.payment_amount,
          payment_date: data.payment_date || new Date().toISOString().split('T')[0]
        });
        
        // Fetch invoice details for additional context
        fetchInvoiceDetails(data.invoice_id);
        complete(true, 'Payment loaded successfully');
      } else {
        complete(false);
        error('Failed to load payment details');
        navigate('/error');
      }
    } catch (e) {
      complete(false);
      error(e);
      navigate('/error');
    }
  };

  // Fetch invoice details for context
  const fetchInvoiceDetails = async (invoiceId) => {
    try {
      const response = await fetch(`${APP_URL}/invoices/${invoiceId}`, {
        credentials: 'include',
      });
      
      if (response.ok) {
        const data = await response.json();
        setInvoice(data);
      }
    } catch (e) {
      console.error('Error fetching invoice:', e);
    } finally {
      setLoading(false);
    }
  };

  // Handle payment update
  function handleUpdatePayment(values) {
    if (!formik.isValid) return;
    
    setSubmitting(true);
    const { complete } = spinner('Updating payment...');
    
    fetch(`${APP_URL}/payments/${id}`, {
      method: 'PATCH',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token')
      },
      body: JSON.stringify(values),
    })
      .then(async (response) => {
        if (response.ok) {
          complete(true, 'Payment updated successfully');
          success('Payment updated successfully!');
          
          // Refresh the data
          fetchPaymentDetails();
        } else {
          complete(false);
          const errorData = await response.json();
          error(errorData.message || 'Failed to update payment');
        }
      })
      .catch((e) => {
        complete(false);
        error(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }

  // Handle payment deletion
  const handleDeletePayment = () => {
    choice({
      title: 'Delete Payment',
      message: 'Are you sure you want to delete this payment? This action cannot be undone.',
      confirmText: 'Delete Payment',
      cancelText: 'Cancel',
      onConfirm: confirmDelete
    });
  };

  // Confirm and process deletion
  const confirmDelete = () => {
    setSubmitting(true);
    const { complete } = spinner('Deleting payment...');
    
    fetch(`${APP_URL}/payments/${id}`, {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token')
      },
    })
      .then(async (response) => {
        if (response.ok) {
          complete(true, 'Payment deleted successfully');
          success('Payment deleted successfully!');
          navigate('/clients/manage');
        } else {
          complete(false);
          const errorData = await response.json();
          error(errorData.message || 'Failed to delete payment');
        }
      })
      .catch((e) => {
        complete(false);
        error(e);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  // Handle go back
  const handleGoBack = () => {
    navigate(-1);
  };

  // Calculate payment status
  const getPaymentStatus = () => {
    if (!invoice) return { status: 'Unknown', color: 'gray' };
    
    const totalPaid = parseFloat(formik.values.payment_amount) || 0;
    const totalDue = invoice.total || 0;
    
    if (totalPaid >= totalDue) return { status: 'Paid in Full', color: '#28a745' };
    if (totalPaid > 0) return { status: 'Partial Payment', color: '#ffc107' };
    return { status: 'Unpaid', color: '#dc3545' };
  };

  const paymentStatus = getPaymentStatus();

  if (loading) {
    return (
      <div className="payments-loading-container">
        <div className="payments-loading-content">
          <FiRefreshCw className="payments-loading-spinner" />
          <h2>Loading payment details...</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="payments-page-container">
      <div className="payments-card">
        <div className="payments-header">
          <button 
            className="payments-back-button" 
            onClick={handleGoBack}
            disabled={submitting}
          >
            <FiArrowLeft />
          </button>
          <div className="payments-title-section">
            <h1 className="payments-title">
              <FiDollarSign className="payments-title-icon" />
              Edit Payment
            </h1>
            <p className="payments-subtitle">
              Payment #{id} for Invoice #{formik.values.invoice_id}
            </p>
          </div>
        </div>

        {invoice && (
          <div className="invoice-summary-card">
            <div className="invoice-summary-header">
              <FiFileText className="invoice-summary-icon" />
              <h3>Invoice Information</h3>
            </div>
            <div className="invoice-summary-content">
              <div className="invoice-summary-item">
                <div className="summary-item-label">
                  <FiUser /> Client
                </div>
                <div className="summary-item-value client-name">
                  {invoice.client?.legal_name || 'Unknown'}
                </div>
              </div>
              
              <div className="invoice-summary-item">
                <div className="summary-item-label">
                  <FiDollarSign /> Invoice Total
                </div>
                <div className="summary-item-value invoice-total">
                  ${invoice.total ? invoice.total.toFixed(2) : '0.00'}
                </div>
              </div>
              
              <div className="invoice-summary-item">
                <div className="summary-item-label">
                  <FiCheck /> Status
                </div>
                <div className="summary-item-value">
                  <span 
                    className="payment-status-badge"
                    style={{ backgroundColor: paymentStatus.color }}
                  >
                    {paymentStatus.status}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <form onSubmit={formik.handleSubmit} className="payments-form">
          <div className="form-content">
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="payment_amount" className="form-label">
                  <FiDollarSign className="form-icon" />
                  Payment Amount
                </label>
                <div className="payment-amount-input-wrapper">
                  <span className="currency-symbol">$</span>
                  <input
                    type="number"
                    id="payment_amount"
                    name="payment_amount"
                    className={`payment-amount-input ${formik.touched.payment_amount && formik.errors.payment_amount ? 'input-error' : ''}`}
                    value={formik.values.payment_amount}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={submitting}
                    step="0.01"
                    placeholder="0.00"
                  />
                </div>
                {formik.touched.payment_amount && formik.errors.payment_amount ? (
                  <div className="form-error">
                    <FiAlertTriangle className="error-icon" />
                    {formik.errors.payment_amount}
                  </div>
                ) : (
                  <div className="form-hint">Enter the payment amount</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="payment_date" className="form-label">
                  <FiCalendar className="form-icon" />
                  Payment Date
                </label>
                <input
                  type="date"
                  id="payment_date"
                  name="payment_date"
                  className="form-control"
                  value={formik.values.payment_date}
                  onChange={formik.handleChange}
                  disabled={submitting}
                />
                <div className="form-hint">Date the payment was received</div>
              </div>
            </div>
            
            <div className="form-info-box">
              <FiInfo className="info-icon" />
              <div className="info-content">
                <p>Editing this payment will update the invoice payment status. The system calculates the status based on the total of all payments compared to the invoice total.</p>
              </div>
            </div>
          </div>

          <div className="form-actions">
            <div className="action-buttons">
              <button
                type="button"
                className="delete-button"
                onClick={handleDeletePayment}
                disabled={submitting}
              >
                <FiTrash2 className="button-icon" />
                Delete Payment
              </button>
              
              <button
                type="submit"
                className="submit-button"
                disabled={submitting || !formik.isValid || formik.isSubmitting}
              >
                <FiSave className="button-icon" />
                Save Changes
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PaymentsForm;