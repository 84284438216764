import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import '../styles/AdminPage.css';
import { 
  FiUsers, 
  FiUserPlus, 
  FiUserCheck, 
  FiBriefcase, 
  FiPlusCircle, 
  FiList, 
  FiActivity,
  FiDollarSign, 
  FiFileText, 
  FiPieChart, 
  FiEdit, 
  FiDatabase,
  FiGrid,
  FiLayers,
  FiSettings,
  FiBook
} from 'react-icons/fi';

const AdminPage = () => {
  const { DEV } = useAuth();

  // Admin card data structure for easier rendering
  const adminCards = [
    {
      id: 'user-ops',
      title: 'User Operations',
      icon: <FiUsers />,
      color: 'blue',
      links: [
        { to: '/users/create', text: 'Create User', icon: <FiUserPlus /> },
        { to: '/users/manage', text: 'Manage Users', icon: <FiUserCheck /> }
      ]
    },
    {
      id: 'client-ops',
      title: 'Client Operations',
      icon: <FiBriefcase />,
      color: 'green',
      links: [
        { to: '/clients/create', text: 'Create Client', icon: <FiPlusCircle /> },
        { to: '/clients/manage', text: 'Manage Clients', icon: <FiList /> },
        { to: '/activity-types', text: 'Activity Types', icon: <FiActivity /> }
      ]
    },
    {
      id: 'monetary-ops',
      title: 'Monetary Operations',
      icon: <FiDollarSign />,
      color: 'purple',
      links: [
        { to: '/reports', text: 'Reports', icon: <FiPieChart /> },
        { to: '/ledger', text: 'AR Ledger', icon: <FiBook /> },
        { to: '/billing', text: 'Billing', icon: <FiDollarSign /> }
      ]
    },
    {
      id: 'invoice-ops',
      title: 'Invoice Operations',
      icon: <FiFileText />,
      color: 'orange',
      links: [
        { to: '/invoice_texts/create', text: 'Create Invoice Text', icon: <FiEdit /> },
        { to: '/invoice_texts/manage', text: 'Manage Invoice Text', icon: <FiFileText /> }
      ]
    }
  ];

  // Conditional admin operations card for DEV users
  const devAdminCard = {
    id: 'admin-ops',
    title: 'Admin Operations',
    icon: <FiSettings />,
    color: 'red',
    links: [
      { to: '/admin/logs', text: 'View Backups', icon: <FiDatabase /> }
    ]
  };

  // Add dev card if DEV is true
  const allCards = DEV ? [...adminCards, devAdminCard] : adminCards;

  return (
    <div className="admin-container">
      <div className="admin-header">
        <h1 className="admin-title"><FiGrid /> Administration</h1>
        <p className="admin-subtitle">Manage users, clients, and system settings</p>
      </div>

      <div className="admin-card-grid">
        {allCards.map(card => (
          <div className={`admin-card card-${card.color}`} key={card.id}>
            <div className="admin-card-header">
              <span className="card-icon">{card.icon}</span>
              <h2 className="card-title">{card.title}</h2>
            </div>
            
            <div className="admin-card-content">
              <ul className="admin-link-list">
                {card.links.map((link, index) => (
                  <li key={index}>
                    <Link to={link.to} className="admin-link">
                      <span className="link-icon">{link.icon}</span>
                      <span className="link-text">{link.text}</span>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdminPage;