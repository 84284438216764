import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import '../styles/UserManagement.css';
import { 
  FiUsers, 
  FiUserPlus, 
  FiSearch, 
  FiEdit2, 
  FiTrash2, 
  FiMail, 
  FiCalendar, 
  FiDollarSign,
  FiGrid,
  FiList,
  FiArrowUp,
  FiArrowDown,
  FiRefreshCw,
  FiInfo,
  FiTag
} from 'react-icons/fi';

const UserManagement = () => {
  const { getCookie, APP_URL } = useAuth();
  const { error, success, choice, spinner } = useNotify();
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [viewMode, setViewMode] = useState('table');
  const [sortColumn, setSortColumn] = useState('username');
  const [sortOrder, setSortOrder] = useState('asc');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // Load users data
  const loadUsers = () => {
    setLoading(true);
    const { complete } = spinner('Loading users...');
    
    fetch(`${APP_URL}/users`, {
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => {
            setUsers(data);
            setFilteredUsers(data);
            sortUsers(data, sortColumn, sortOrder);
            complete(true, 'Users loaded successfully');
          });
        } else {
          complete(false);
          if (resp.status !== 401) {
            error(resp);
            navigate('/error');
          }
        }
        setLoading(false);
      })
      .catch((e) => {
        complete(false);
        error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Handle search
  const handleSearch = () => {
    if (!searchQuery.trim()) {
      setFilteredUsers(users);
      return;
    }
    
    const query = searchQuery.toLowerCase().trim();
    const filtered = users.filter((user) =>
      user.username.toLowerCase().includes(query) || 
      user.id.toString().includes(query) ||
      user.email.toLowerCase().includes(query) ||
      user.role.toLowerCase().includes(query)
    );
    
    setFilteredUsers(filtered);
  };

  // Clear search and reset filters
  const clearSearch = () => {
    setSearchQuery('');
    setFilteredUsers(users);
  };

  // Handle edit user
  const handleEdit = (user) => {
    navigate(`/users/${user.id}/edit`);
  };

  // Handle delete user
  const handleDelete = (user) => {
    choice({
      title: 'Delete User',
      message: `Are you sure you want to delete user: ${user.username}?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: () => handleConfirmDelete(user)
    });
  };

  // Confirm delete user
  const handleConfirmDelete = (user) => {
    const { complete } = spinner(`Deleting user ${user.username}...`);
    
    fetch(`${APP_URL}/users/${user.id}`, {
      method: "DELETE",
      credentials: 'include',
      headers: {
        "X-CSRF-TOKEN": getCookie('csrf_access_token'),
      }
    })
      .then(resp => {
        if (resp.ok) {
          const updatedUsers = users.filter(u => u.id !== user.id);
          setUsers(updatedUsers);
          setFilteredUsers(updatedUsers);
          complete(true, "User deleted successfully!");
        } else {
          complete(false);
          resp.json().then(error);
        }
      })
      .catch(e => {
        complete(false);
        error(e);
      });
  };

  // Sort users
  const sortUsers = (usersList, column, order) => {
    const sorted = [...usersList].sort((a, b) => {
      let valueA = a[column];
      let valueB = b[column];
      
      // Handle null or undefined values
      if (valueA === null || valueA === undefined) valueA = '';
      if (valueB === null || valueB === undefined) valueB = '';
      
      // Convert to strings for comparison if they're not already
      if (typeof valueA !== 'string') valueA = String(valueA);
      if (typeof valueB !== 'string') valueB = String(valueB);
      
      // Case-insensitive string comparison
      if (order === 'asc') {
        return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
      } else {
        return valueB.toLowerCase().localeCompare(valueA.toLowerCase());
      }
    });
    
    setFilteredUsers(sorted);
  };

  // Handle sort
  const handleSort = (column) => {
    const newOrder = sortColumn === column && sortOrder === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortOrder(newOrder);
    sortUsers(filteredUsers, column, newOrder);
  };

  // Get role badge class
  const getRoleBadgeClass = (role) => {
    switch (role) {
      case 'Partner':
        return 'role-badge role-partner';
      case 'Staff Mgr':
        return 'role-badge role-manager';
      case 'Staff':
        return 'role-badge role-staff';
      default:
        return 'role-badge';
    }
  };

  // Get status badge class
  const getStatusBadgeClass = (status) => {
    return status === 'Active' ? 'status-badge status-active' : 'status-badge status-inactive';
  };

  return (
    <div className="user-management-container">
      <div className="user-management-header">
        <div className="header-title">
          <FiUsers className="header-icon" />
          <h2>User Management</h2>
        </div>
        
        <div className="header-actions">
          <button 
            className="refresh-button" 
            onClick={loadUsers} 
            title="Refresh Users"
            disabled={loading}
          >
            <FiRefreshCw className={loading ? 'spin' : ''} />
          </button>
          
          <button 
            className="view-toggle-button" 
            onClick={() => setViewMode(viewMode === 'table' ? 'card' : 'table')}
            title={viewMode === 'table' ? 'Switch to Card View' : 'Switch to Table View'}
          >
            {viewMode === 'table' ? <FiGrid /> : <FiList />}
          </button>
          
          <button 
            className="create-user-button" 
            onClick={() => navigate('/users/create')}
          >
            <FiUserPlus className="button-icon" />
            <span>Create User</span>
          </button>
        </div>
      </div>
      
      <div className="search-container">
        <div className="search-input-group">
          <FiSearch className="search-icon" />
          <input
            type="text"
            className="search-input"
            placeholder="Search by username, ID, email or role..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          {searchQuery && (
            <button className="clear-search-button" onClick={clearSearch}>
              ×
            </button>
          )}
        </div>
        <button className="search-button" onClick={handleSearch}>
          <FiSearch className="button-icon" />
          <span>Search</span>
        </button>
      </div>

      {filteredUsers.length === 0 ? (
        <div className="no-results">
          <FiInfo className="no-results-icon" />
          <p>No users found. Try adjusting your search criteria.</p>
          {searchQuery && (
            <button className="clear-search-button secondary" onClick={clearSearch}>
              Clear Search
            </button>
          )}
        </div>
      ) : viewMode === 'table' ? (
        <div className="table-container">
          <table className="users-table">
            <thead>
              <tr>
                <th 
                  onClick={() => handleSort('id')} 
                  className={sortColumn === 'id' ? 'sorted' : ''}
                >
                  <div className="th-content-u">
                    <FiTag className="th-icon" />
                    <span>ID</span>
                    {sortColumn === 'id' && (
                      sortOrder === 'asc' ? <FiArrowUp className="sort-icon" /> : <FiArrowDown className="sort-icon" />
                    )}
                  </div>
                </th>
                <th 
                  onClick={() => handleSort('username')} 
                  className={sortColumn === 'username' ? 'sorted' : ''}
                >
                  <div className="th-content-u">
                    <FiUsers className="th-icon" />
                    <span>Username</span>
                    {sortColumn === 'username' && (
                      sortOrder === 'asc' ? <FiArrowUp className="sort-icon" /> : <FiArrowDown className="sort-icon" />
                    )}
                  </div>
                </th>
                <th 
                  onClick={() => handleSort('email')} 
                  className={sortColumn === 'email' ? 'sorted' : ''}
                >
                  <div className="th-content-u">
                    <FiMail className="th-icon" />
                    <span>Email</span>
                    {sortColumn === 'email' && (
                      sortOrder === 'asc' ? <FiArrowUp className="sort-icon" /> : <FiArrowDown className="sort-icon" />
                    )}
                  </div>
                </th>
                <th 
                  onClick={() => handleSort('role')} 
                  className={sortColumn === 'role' ? 'sorted' : ''}
                >
                  <div className="th-content-u">
                    <FiUsers className="th-icon" />
                    <span>Role</span>
                    {sortColumn === 'role' && (
                      sortOrder === 'asc' ? <FiArrowUp className="sort-icon" /> : <FiArrowDown className="sort-icon" />
                    )}
                  </div>
                </th>
                <th 
                  onClick={() => handleSort('status')} 
                  className={sortColumn === 'status' ? 'sorted' : ''}
                >
                  <div className="th-content-u">
                    <FiInfo className="th-icon" />
                    <span>Status</span>
                    {sortColumn === 'status' && (
                      sortOrder === 'asc' ? <FiArrowUp className="sort-icon" /> : <FiArrowDown className="sort-icon" />
                    )}
                  </div>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredUsers.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td className="username-cell">{user.username}</td>
                  <td>{user.email}</td>
                  <td>
                    <span className={getRoleBadgeClass(user.role)}>
                      {user.role}
                    </span>
                  </td>
                  <td>
                    <span className={getStatusBadgeClass(user.status)}>
                      {user.status}
                    </span>
                  </td>
                  <td className="actions-cell">
                    <button 
                      className="action-button-um edit" 
                      onClick={() => handleEdit(user)}
                      title="Edit User"
                    >
                      <FiEdit2 />
                    </button>
                    <button 
                      className="action-button-um delete" 
                      onClick={() => handleDelete(user)}
                      title="Delete User"
                    >
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="user-cards-grid">
          {filteredUsers.map((user) => (
            <div key={user.id} className="user-card">
              <div className="user-card-header">
                <div className="user-avatar">
                  {user.username.charAt(0).toUpperCase()}
                </div>
                <div className="user-info">
                  <h3 className="user-name">{user.username}</h3>
                  <span className={getRoleBadgeClass(user.role)}>
                    {user.role}
                  </span>
                </div>
                <div className="user-status">
                  <span className={getStatusBadgeClass(user.status)}>
                    {user.status}
                  </span>
                </div>
              </div>
              
              <div className="user-card-content">
                <div className="user-detail">
                  <FiTag className="detail-icon" />
                  <span className="detail-label">ID:</span>
                  <span className="detail-value">{user.id}</span>
                </div>
                
                <div className="user-detail">
                  <FiMail className="detail-icon" />
                  <span className="detail-label">Email:</span>
                  <span className="detail-value">{user.email}</span>
                </div>
                
                {user.start_date && (
                  <div className="user-detail">
                    <FiCalendar className="detail-icon" />
                    <span className="detail-label">Start Date:</span>
                    <span className="detail-value">{user.start_date}</span>
                  </div>
                )}
                
                <div className="user-rates">
                  <div className="rates-header">
                    <FiDollarSign className="detail-icon" />
                    <span>Billing Rates</span>
                  </div>
                  
                  {user.rates && user.rates.length > 0 ? (
                    <ul className="rates-list">
                      {user.rates.map((rate, index) => (
                        <li key={index} className="rate-item">
                          <span className="rate-name">{rate.name}:</span>
                          <span className="rate-value">${rate.value}</span>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    <p className="no-rates">No billing rates defined</p>
                  )}
                </div>
              </div>
              
              <div className="user-card-footer">
                <button 
                  className="card-action-button edit" 
                  onClick={() => handleEdit(user)}
                  title="Edit User"
                >
                  <FiEdit2 className="button-icon" />
                  <span>Edit</span>
                </button>
                <button 
                  className="card-action-button delete" 
                  onClick={() => handleDelete(user)}
                  title="Delete User"
                >
                  <FiTrash2 className="button-icon" />
                  <span>Delete</span>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UserManagement;