import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useUtils } from '../context/UtilContext';
import { useAuth } from '../context/AuthContext';
import '../styles/ToolBar.css';
import { 
  FiArrowLeft, 
  FiPrinter, 
  FiUsers
} from 'react-icons/fi';

const Toolbar = () => {
  const navigate = useNavigate();
  const { settingsObj } = useUtils();
  const { user } = useAuth();
  const { isToolBarEnabled } = settingsObj;

  // Don't render if toolbar is disabled
  if (!isToolBarEnabled) {
    return null;
  }

  const isAdmin = user?.role === "Partner" || user?.role === 'Staff Mgr';

  const handleGoBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  const handlePrint = () => {
    window.print(); // Trigger browser print dialog
  };

  const handleManageUsers = () => {
    navigate('/users/manage'); // Navigate to users management page
  };

  return (
    <div className="toolbar">
      <div 
        className="toolbar-item"
        onClick={handleGoBack}
      >
        <div className="toolbar-icon"><FiArrowLeft /></div>
        <div className="toolbar-label">Go Back</div>
      </div>

      <div 
        className="toolbar-item"
        onClick={handlePrint}
      >
        <div className="toolbar-icon"><FiPrinter /></div>
        <div className="toolbar-label">Print</div>
      </div>

      {isAdmin && (
        <div 
          className="toolbar-item"
          onClick={handleManageUsers}
        >
          <div className="toolbar-icon"><FiUsers /></div>
          <div className="toolbar-label">Manage Users</div>
        </div>
      )}
    </div>
  );
};

export default Toolbar;