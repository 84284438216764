import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import '../styles/TimeEntries.css';
import { 
  FiClock, 
  FiCalendar, 
  FiSearch, 
  FiPlus, 
  FiRefreshCw, 
  FiFilter,
  FiEdit2,
  FiArrowUp,
  FiArrowDown,
  FiUser,
  FiDollarSign,
  FiTag,
  FiFileText,
  FiUsers
} from 'react-icons/fi';

const MyTimeEntries = () => {
  // Context and hooks
  const { user, APP_URL } = useAuth();
  const { error, spinner } = useNotify();
  const { formatDate, formatCurrency, sum } = useUtils();
  const navigate = useNavigate();

  // State for time entries and filtering
  const [timeEntries, setTimeEntries] = useState([]);
  const [filteredEntries, setFilteredEntries] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);
  
  // State for sorting
  const [sortConfig, setSortConfig] = useState({ 
    key: 'start_date', 
    direction: 'desc' 
  });
  
  // State for date filtering
  const [useSheetDate, setUseSheetDate] = useState(true);
  const [sheetDate, setSheetDate] = useState(getCurrentFriday());
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  // State for user selection (for partners/managers)
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState({ 
    id: user?.id, 
    username: user?.username, 
    role: user?.role 
  });
  const [viewAllUsers, setViewAllUsers] = useState(false);

  // Get current friday for default sheet date
  function getCurrentFriday() {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const distanceToFriday = (5 - dayOfWeek + 7) % 7;
    const friday = new Date(today);
    friday.setDate(today.getDate() + distanceToFriday);
    friday.setHours(0, 0, 0, 0);
    return friday;
  }

  // Get sheet date range (Saturday to Friday)
  const getSheetDateRange = (selectedDate) => {
    const sheetEnd = new Date(selectedDate);
    sheetEnd.setHours(23, 59, 59, 999);

    // Find previous Saturday
    const sheetStart = new Date(sheetEnd);
    sheetStart.setDate(sheetEnd.getDate() - 6);
    sheetStart.setHours(0, 0, 0, 0);

    return { sheetStart, sheetEnd };
  };

  // Check if a date is a Friday (for date picker)
  const isWeekday = (date) => {
    const day = date.getDay();
    return day === 5;
  };

  // Fetch users if current user is a partner or manager
  useEffect(() => {
    if (user && (user.role === 'Partner' || user.role === 'Staff Mgr')) {
      fetchUsers();
    }
  }, [user]);

  // Fetch time entries when selected user or date filters change
  useEffect(() => {
    if (user) {
      if (viewAllUsers) {
        fetchAllTimeEntries();
      } else if (selectedUser?.id) {
        fetchTimeEntries();
      }
    }
  }, [selectedUser, sheetDate, startDate, endDate, viewAllUsers]);

  // Apply search filter when search query changes
  useEffect(() => {
    if (searchQuery.trim() === '') {
      applyDateFilters(timeEntries);
    } else {
      performSearch();
    }
  }, [searchQuery, timeEntries]);

  // Apply date filters when date-related state changes
  useEffect(() => {
    applyDateFilters(timeEntries);
  }, [useSheetDate, sheetDate, startDate, endDate, timeEntries]);

  // Apply sort when sort configuration changes
  useEffect(() => {
    if (filteredEntries.length > 0) {
      const sorted = sortEntries([...filteredEntries]);
      setFilteredEntries(sorted);
    }
  }, [sortConfig]);

  // Fetch users from API
  const fetchUsers = async () => {
    try {
      const response = await fetch(`${APP_URL}/users`, {
        credentials: 'include'
      });
      
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        error('Failed to load users');
      }
    } catch (e) {
      error(e);
    }
  };

  // Fetch time entries for a specific user
  const fetchTimeEntries = async () => {
    setLoading(true);
    const { complete } = spinner('Loading time entries...');
    
    try {
      const response = await fetch(`${APP_URL}/users/${selectedUser.id}`, {
        credentials: 'include'
      });
      
      if (response.ok) {
        const data = await response.json();
        const entries = data.created_time_entries || [];
        
        setTimeEntries(entries);
        applyDateFilters(entries);
        complete(true, '', true);
      } else {
        error(response)
      }
    } catch (e) {
      error(e);
      complete(false);
    } finally {
      setLoading(false);
    }
  };

  // Fetch time entries for all users
  const fetchAllTimeEntries = async () => {
    setLoading(true);
    const { complete } = spinner('Loading all time entries...');
    
    try {
      const response = await fetch(`${APP_URL}/time_entries/all`, {
        credentials: 'include'
      });
      
      if (response.ok) {
        const entries = await response.json();
        
        setTimeEntries(entries);
        applyDateFilters(entries);
        complete(true, 'All time entries loaded');
      } else {
        error(response)
      }
    } catch (e) {
      error(e);
      complete(false);
    } finally {
      setLoading(false);
    }
  };

  // Apply date filters to time entries
  const applyDateFilters = (entries) => {
    if (!entries || entries.length === 0) return;
    
    let filtered = [...entries];
    
    if (useSheetDate && sheetDate) {
      const { sheetStart, sheetEnd } = getSheetDateRange(sheetDate);
      filtered = filtered.filter(entry => {
        const entryDate = new Date(entry.start_date);
        return entryDate >= sheetStart && entryDate <= sheetEnd;
      });
    } else if (startDate && endDate) {
      // Adjust start date to be one day earlier
      const startDateTime = new Date(startDate);
      startDateTime.setDate(startDateTime.getDate() - 1);
      startDateTime.setHours(0, 0, 0, 0);
      
      const endDateTime = new Date(endDate);
      endDateTime.setHours(23, 59, 59, 999);
      
      filtered = filtered.filter(entry => {
        const entryDate = new Date(entry.start_date);
        return entryDate >= startDateTime && entryDate <= endDateTime;
      });
    }
    
    // Apply search filter if there is a search query
    if (searchQuery.trim() !== '') {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(entry => {
        return (
          entry.client.client_id.toString().includes(query) ||
          entry.client.legal_name.toLowerCase().includes(query) ||
          entry.activity_type.name.toLowerCase().includes(query) ||
          (entry.user && entry.user.username.toLowerCase().includes(query))
        );
      });
    }
    
    // Apply current sort
    const sorted = sortEntries(filtered);
    setFilteredEntries(sorted);
  };

  // Perform search on time entries
  const performSearch = () => {
    const query = searchQuery.toLowerCase().trim();
    
    const filtered = timeEntries.filter(entry => {
      return (
        entry.client.client_id.toString().includes(query) ||
        entry.client.legal_name.toLowerCase().includes(query) ||
        entry.activity_type.name.toLowerCase().includes(query) ||
        (entry.user && entry.user.username.toLowerCase().includes(query))
      );
    });
    
    applyDateFilters(filtered);
  };

  // Handle search form submission
  const handleSearch = (e) => {
    e.preventDefault();
    performSearch();
  };

  // Sort time entries based on current sort configuration
  const sortEntries = (entries) => {
    const { key, direction } = sortConfig;
    
    return entries.sort((a, b) => {
      let valueA, valueB;
      
      // Handle nested properties and special cases
      if (key === 'client_name') {
        valueA = a.client.legal_name;
        valueB = b.client.legal_name;
      } else if (key === 'client_id') {
        valueA = a.client.client_id;
        valueB = b.client.client_id;
      } else if (key === 'activity') {
        valueA = a.activity_type.name;
        valueB = b.activity_type.name;
      } else if (key === 'user') {
        valueA = a.user?.username || '';
        valueB = b.user?.username || '';
      } else {
        valueA = a[key];
        valueB = b[key];
      }
      
      // Handle different data types
      if (key === 'start_date') {
        valueA = new Date(valueA);
        valueB = new Date(valueB);
      }
      
      // Determine sort order
      let comparison = 0;
      if (valueA > valueB) {
        comparison = 1;
      } else if (valueA < valueB) {
        comparison = -1;
      }
      
      return direction === 'asc' ? comparison : -comparison;
    });
  };

  // Handle sort request
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  // Toggle between sheet date and date range
  const handleToggleView = () => {
    setUseSheetDate(!useSheetDate);
  };

  // Reset all filters
  const handleClearFilters = () => {
    setSearchQuery('');
    setStartDate(null);
    setEndDate(null);
    setSheetDate(getCurrentFriday());
    applyDateFilters(timeEntries);
  };

  // Handle user selection change
  const handleUserChange = (e) => {
    const userId = e.target.value;
    
    // If "All Users" option is selected
    if (userId === "all") {
      setViewAllUsers(true);
      setSelectedUser({ id: "all", username: "All Users", role: "" });
      return;
    }
    
    setViewAllUsers(false);
    
    const select = document.querySelector("#userSelect");
    const username = select.options[select.selectedIndex].getAttribute('data-username');
    const role = select.options[select.selectedIndex].getAttribute('data-role');
    
    setSelectedUser({ id: userId, username, role });
  };

  // Calculate total hours and amount
  const totalHours = sum(filteredEntries, 'hours', 0);
  const totalAmount = sum(filteredEntries, 'total', 0);

  // Helper to render sort icon
  const renderSortIcon = (key) => {
    if (sortConfig.key !== key) return null;
    return sortConfig.direction === 'asc' ? <FiArrowUp className="sort-icon" /> : <FiArrowDown className="sort-icon" />;
  };

  return (
    <div className="time-entries-container">
      <div className="time-entries-header">
        <div className="header-title-section">
          <div className="header-title">
            <FiClock className="header-icon" />
            <h1>Time Entries</h1>
          </div>
          <p className="header-subtitle">
            Viewing entries for{" "}
            <span className="user-highlight">
              {viewAllUsers ? "All Users" : selectedUser.username}
            </span>
          </p>
        </div>
        
        <div className="header-actions">
          <button 
            className="action-button create-button" 
            onClick={() => navigate('/time_entries/create')}
          >
            <FiPlus /> Enter Time
          </button>

          
          <button 
            className="action-button toggle-button" 
            onClick={handleToggleView}
          >
            <FiCalendar /> {useSheetDate ? 'Use Date Range' : 'Use Sheet Date'}
          </button>
          <button 
              className="filter-action-button refresh-button" 
              onClick={viewAllUsers ? fetchAllTimeEntries : fetchTimeEntries}
              disabled={loading}
            >
              <FiRefreshCw className={loading ? "spinner" : ""} /> Refresh
            </button>
        </div>
      </div>

      <div className="time-entries-filters">
        <div className="filters-card">
          <div className="filters-row">
            {/* Date filters */}
            <div className="date-filters">
              {useSheetDate ? (
                <div className="sheet-date-picker">
                  <label className="filter-label">
                    <FiCalendar /> Sheet Week (ending Friday)
                  </label>
                  <DatePicker
                    selected={sheetDate}
                    onChange={(date) => setSheetDate(date)}
                    filterDate={isWeekday}
                    placeholderText="Select a Friday"
                    className="date-input"
                    dateFormat="MMM d, yyyy"
                  />
                </div>
              ) : (
                <div className="date-range-picker">
                  <label className="filter-label">
                    <FiCalendar /> Date Range
                  </label>
                  <div className="date-range-inputs">
                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText="Start Date"
                      className="date-input"
                      dateFormat="MMM d, yyyy"
                    />
                    <span className="date-range-separator">to</span>
                    <DatePicker
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      selectsEnd
                      startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText="End Date"
                      className="date-input"
                      dateFormat="MMM d, yyyy"
                    />
                  </div>
                </div>
              )}
            </div>

            {/* User selection (for partners/managers) */}
            {user && (user.role === 'Partner' || user.role === 'Staff Mgr') && (
              <div className="user-filter">
                <label htmlFor="userSelect" className="filter-label">
                  <FiUser /> Select Staff
                </label>
                <select
                  className="user-select"
                  id="userSelect"
                  value={viewAllUsers ? "all" : selectedUser.id}
                  onChange={handleUserChange}
                >
                  <option value="" disabled>Select Staff</option>
                  <option value="all" data-username="All Users" data-role="">
                    All Users
                  </option>
                  {users
                    .filter(u => (u.role !== 'Partner' || u.id === user.id))
                    .map((u) => (
                      <option 
                        key={u.id} 
                        value={u.id} 
                        data-username={u.username}
                        data-role={u.role}
                      >
                        {`${u.username} - ${u.role}`}
                      </option>
                    ))
                  }
                </select>
              </div>
            )}

            {/* Search filter */}
            <div className="search-filter">
              <label className="filter-label">
                <FiSearch /> Search
              </label>
              <form onSubmit={handleSearch} className="search-form">
                <input
                  type="text"
                  placeholder="Search by client, activity or user"
                  className="search-input"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
                <button type="submit" className="search-button">
                  <FiSearch />
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="time-entries-summary">
        <div className="summary-card">
          <div className="summary-label">Total Hours</div>
          <div className="summary-value hours-value">
            <FiClock className="summary-icon" /> {totalHours.toFixed(2)}
          </div>
        </div>
        <div className="summary-card">
          <div className="summary-label">Total Value</div>
          <div className="summary-value amount-value">
              {formatCurrency(totalAmount)}
          </div>
        </div>
        <div className="summary-card">
          <div className="summary-label">Entries</div>
          <div className="summary-value entries-value">
            <FiFileText className="summary-icon" /> {filteredEntries.length}
          </div>
        </div>
      </div>

      <div className="time-entries-table-container">
        {loading ? (
          <div className="loading-indicator">
            <FiRefreshCw className="spinner" />
            <p>Loading time entries...</p>
          </div>
        ) : filteredEntries.length > 0 ? (
          <table className="time-entries-table">
            <thead>
              <tr>
                <th onClick={() => handleSort('client_id')}>
                  <div className="th-content">
                    Client ID {renderSortIcon('client_id')}
                  </div>
                </th>
                <th onClick={() => handleSort('client_name')}>
                  <div className="th-content">
                    Client Name {renderSortIcon('client_name')}
                  </div>
                </th>
                <th onClick={() => handleSort('activity')}>
                  <div className="th-content">
                    Activity {renderSortIcon('activity')}
                  </div>
                </th>
                {viewAllUsers && (
                  <th onClick={() => handleSort('user')}>
                    <div className="th-content">
                      Staff {renderSortIcon('user')}
                    </div>
                  </th>
                )}
                <th onClick={() => handleSort('start_date')}>
                  <div className="th-content">
                    Date {renderSortIcon('start_date')}
                  </div>
                </th>
                <th onClick={() => handleSort('total')}>
                  <div className="th-content">
                    Amount {renderSortIcon('total')}
                  </div>
                </th>
                <th onClick={() => handleSort('hours')}>
                  <div className="th-content">
                    Hours {renderSortIcon('hours')}
                  </div>
                </th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredEntries.map((entry) => (
                <tr key={entry.id} className="entry-row">
                  <td>{entry.client.client_id}</td>
                  <td>{entry.client.legal_name}</td>
                  <td>
                    <span className="activity-badge">
                      <FiTag className="activity-icon" />
                      {entry.activity_type?.name}
                    </span>
                  </td>
                  {viewAllUsers && (
                    <td>
                      <span className="user-badge">
                        <FiUser className="user-icon" />
                        {entry.user?.username || "Unknown"}
                      </span>
                    </td>
                  )}
                  <td>{formatDate(entry.start_date)}</td>
                  <td className="amount-cell">{formatCurrency(entry.total)}</td>
                  <td className="hours-cell">{entry.hours.toFixed(2)}</td>
                  <td>
                    <button 
                      className="table-action-button edit-button" 
                      onClick={() => navigate(`/time_entries/${entry.id}/edit`)}
                    >
                      <FiEdit2 /> Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="empty-state">
            <FiClock className="empty-icon" />
            <h3>No Time Entries Found</h3>
            <p>Try adjusting your filters or create a new time entry</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyTimeEntries;