import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import logo from '../assets/clockwise.png';
import '../styles/Login.css';
import { 
  FiUser, 
  FiLock, 
  FiMail, 
  FiX, 
  FiLogIn, 
  FiHelpCircle,
  FiShield,
  FiArrowRight,
  FiEye,
  FiEyeOff
} from 'react-icons/fi';

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const popup = searchParams.get('popup') ? true : false;
  const [showLoginForm, setShowLoginForm] = useState(popup);
  const [showContactForm, setShowContactForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showResetPasswordForm, setShowResetPasswordForm] = useState(false);
  const [show2FAModal, setShow2FAModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { error, success, spinner } = useNotify();
  const navigate = useNavigate();
  const { login, APP_URL, getCookie, handle2FASubmit, user } = useAuth();

  useEffect(() => {
    if(getCookie('csrf_access_token') && user){
      console.log(user)
      navigate('/')
    }
  }, [user, getCookie]);

  const handleContactClick = () => {
    setShowContactForm(true);
  };

  const handleCloseContactForm = () => {
    setShowContactForm(false);
  };

  const handleShowResetPasswordForm = () => {
    setShowLoginForm(false);
    setShowResetPasswordForm(true);
  };

  const handleCloseResetPasswordForm = () => {
    setShowResetPasswordForm(false);
    setShowLoginForm(true);
  };

  const loginFormik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(5, 'Username must be at least 5 characters')
        .max(15, 'Username must be at most 15 characters')
        .required('Username is required'),
      password: Yup.string()
        .min(5, 'Password must be at least 5 characters')
        .max(15, 'Password must be at most 15 characters')
        .required('Password is required'),
    }),
    onSubmit: (values) => {
      login(values.username, values.password, (message) => {
        if(message.success){
          setShow2FAModal(true);
        } else {
          navigate('/');
        }
      });
    },
  });

  const resetPasswordFormik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const { complete } = spinner("Sending password reset email");
      
      fetch(`${APP_URL}/reset_password/send`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify({ email: values.email }),
      })
        .then((resp) => {
          if (resp.ok) {
            complete(true, "Password reset email sent!");
            setLoading(false);
            setTimeout(() => {
              handleCloseResetPasswordForm();
            }, 2000);
          } else {
            complete(false);
            error(resp);
            setLoading(false);
          }
        })
        .catch((e) => {
          complete(false);
          error(e);
          setLoading(false);
        });
    },
  });

  const submit2FA = (e) => {
    e.preventDefault();
    const code = e.target.elements.code.value;
    if (!code) {
      error({ error: "Authentication code is required" });
      return;
    }
    
    const { complete } = spinner("Verifying code");
    
    handle2FASubmit(code).then(data => {
      if(data && !data.error){
        complete(true, "", true);
        setTimeout(() => {
          navigate('/');
        }, 1000);
      } else if(data.error){
        complete(false);
        error(data);
      }
    }).catch(err => {
      complete(false);
      error(err);
    });
  };

  return (
    <div className="login-page">
      {/* Top Navigation Bar */}
      <nav className="login-nav">
        <div className="login-nav-container">
          <div className="login-logo">
            <img src={logo} alt="Clockwise Logo" className="login-logo-img" />
            <span className="login-logo-text">Clockwise</span>
          </div>
          <a className="contact-button" href='mailto:danner.baumgartner+clockwisecpa@gmail.com'>
          Contact Us
          </a>
        </div>
      </nav>

      {/* Hero Section */}
      <div className="login-hero">
        <div className="login-hero-content">
          <h1 className="login-title">Clockwise</h1>
          <p className="login-subtitle">As time flies, work Clockwise</p>
          <div className="login-logo-container">
            <img src={logo} alt="Clockwise" className="login-hero-logo" />
          </div>
          <button
            className="login-button"
            onClick={() => setShowLoginForm(true)}
          >
            <FiLogIn className="login-button-icon" />
            <span>Log In</span>
          </button>
        </div>
      </div>

      {/* Login Form Modal */}
      {showLoginForm && (
        <div className="modal-overlay">
          <div className="login-modal">
            <button
              className="modal-close"
              onClick={() => setShowLoginForm(false)}
            >
              <FiX />
            </button>
            
            <div className="login-form-header">
              <h2>Welcome Back</h2>
              <p>Sign in to your account</p>
            </div>
            
            <form onSubmit={loginFormik.handleSubmit} className="login-form">
              <div className="form-group">
                <div className="input-icon-wrapper">
                  <FiUser className="input-icon" />
                  <input
                    type="text"
                    id="username"
                    name="username"
                    placeholder="Username"
                    className={`form-input-login ${loginFormik.touched.username && loginFormik.errors.username ? 'input-error' : ''}`}
                    value={loginFormik.values.username}
                    onChange={loginFormik.handleChange}
                    onBlur={loginFormik.handleBlur}
                  />
                </div>
                {loginFormik.touched.username && loginFormik.errors.username && (
                  <div className="error-message">{loginFormik.errors.username}</div>
                )}
              </div>
              
              <div className="form-group">
                <div className="input-icon-wrapper">
                  <FiLock className="input-icon" />
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    placeholder="Password"
                    className={`form-input-login ${loginFormik.touched.password && loginFormik.errors.password ? 'input-error' : ''}`}
                    value={loginFormik.values.password}
                    onChange={loginFormik.handleChange}
                    onBlur={loginFormik.handleBlur}
                  />
                  <button 
                    type="button" 
                    className="password-toggle-button" 
                    onClick={() => setShowPassword(!showPassword)}
                    aria-label={showPassword ? "Hide password" : "Show password"}
                  >
                    {showPassword ? <FiEyeOff /> : <FiEye />}
                  </button>
                </div>
                {loginFormik.touched.password && loginFormik.errors.password && (
                  <div className="error-message">{loginFormik.errors.password}</div>
                )}
              </div>
              
              <button type="submit" className="submit-button">
                Sign In
              </button>
            </form>
            
            <div className="login-form-footer">
              <button
                className="forgot-password-link"
                onClick={handleShowResetPasswordForm}
              >
                <FiHelpCircle className="link-icon" />
                Forgot Password?
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Reset Password Modal */}
      {showResetPasswordForm && (
        <div className="modal-overlay">
          <div className="login-modal">
            <button
              className="modal-close"
              onClick={handleCloseResetPasswordForm}
            >
              <FiX />
            </button>
            
            <div className="login-form-header">
              <h2>Reset Password</h2>
              <p>Enter your email to receive a reset link</p>
            </div>
            
            <form onSubmit={resetPasswordFormik.handleSubmit} className="login-form">
              <div className="form-group">
                <div className="input-icon-wrapper">
                  <FiMail className="input-icon" />
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    className={`form-input-login ${resetPasswordFormik.touched.email && resetPasswordFormik.errors.email ? 'input-error' : ''}`}
                    value={resetPasswordFormik.values.email}
                    onChange={resetPasswordFormik.handleChange}
                    onBlur={resetPasswordFormik.handleBlur}
                  />
                </div>
                {resetPasswordFormik.touched.email && resetPasswordFormik.errors.email && (
                  <div className="error-message">{resetPasswordFormik.errors.email}</div>
                )}
              </div>
              
              <button 
                type="submit" 
                className="submit-button"
                disabled={loading}
              >
                {loading ? 'Sending...' : 'Send Reset Link'}
              </button>
            </form>
            
            <div className="login-form-footer">
              <button
                className="back-to-login-link"
                onClick={handleCloseResetPasswordForm}
              >
                <FiArrowRight className="link-icon" />
                Back to Login
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 2FA Modal */}
      {show2FAModal && (
        <div className="modal-overlay">
          <div className="login-modal">
            <button
              className="modal-close"
              onClick={() => setShow2FAModal(false)}
            >
              <FiX />
            </button>
            
            <div className="login-form-header">
              <h2>Two-Factor Authentication</h2>
              <p>Enter the code sent to your device</p>
            </div>
            
            <form onSubmit={submit2FA} className="login-form">
              <div className="form-group">
                <div className="input-icon-wrapper">
                  <FiShield className="input-icon" />
                  <input
                    type="text"
                    id="code"
                    name="code"
                    placeholder="Authentication Code"
                    className="form-input-login"
                    autoComplete="off"
                  />
                </div>
              </div>
              
              <button type="submit" className="submit-button">
                Verify
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;