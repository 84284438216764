import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/InvoiceTextForm.css';
import { 
  FiFileText, 
  FiSave, 
  FiArrowLeft, 
  FiType, 
  FiAlignLeft 
} from 'react-icons/fi';

const InvoiceTextForm = () => {
  const { APP_URL, getCookie } = useAuth();
  const { success, error, spinner } = useNotify();
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(id ? true : false);
  
  const [initialValues, setInitialValues] = useState({
    name: '',
    text: '',
  });

  useEffect(() => {
    if (id) {
      const { complete } = spinner('Loading invoice text...');
      
      fetch(`${APP_URL}/invoice_texts/${id}`, {
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            return resp.json();
          } else {
            complete(false);
            error(resp);
            navigate('/invoice_texts/manage');
            return null;
          }
        })
        .then((data) => {
          if (data) {
            setInitialValues({
              name: data.name,
              text: data.text,
            });
            complete(true, 'Invoice text loaded');
          }
          setLoading(false);
        })
        .catch((e) => {
          complete(false);
          error(e);
          setLoading(false);
          navigate('/invoice_texts/manage');
        });
    }
  }, [id, APP_URL, error, navigate, spinner]);

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, 'Name must be 50 characters or less')
        .required('Name is required'),
      text: Yup.string().required('Text is required'),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const url = id ? `${APP_URL}/invoice_texts/${id}` : `${APP_URL}/invoice_texts`;
      const method = id ? 'PATCH' : 'POST';
      const message = id ? 'Updating invoice text...' : 'Creating invoice text...';
      
      const { complete } = spinner(message);

      fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify(values),
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            complete(true, id ? 'Invoice text updated successfully' : 'Invoice text created successfully');
            setTimeout(() => {
              navigate('/invoice_texts/manage');
            }, 1000);
          } else {
            complete(false);
            error(resp);
          }
          setLoading(false);
        })
        .catch((e) => {
          complete(false);
          error(e);
          setLoading(false);
        });
    },
  });

  return (
    <div className="invoice-text-form-container">
      <div className="invoice-text-form-header">
        <button 
          className="back-button" 
          onClick={() => navigate('/invoice_texts/manage')}
          aria-label="Go back"
        >
          <FiArrowLeft />
        </button>
        <h2 className="form-title">
          <FiFileText className="form-title-icon" />
          {id ? 'Edit Invoice Text' : 'Create New Invoice Text'}
        </h2>
      </div>

      <div className="invoice-text-form-card">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" className="form-label">
              <FiType className="form-label-icon" />
              Template Name
            </label>
            <input
              id="name"
              name="name"
              type="text"
              className={`form-input ${formik.touched.name && formik.errors.name ? 'input-error' : ''}`}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              placeholder="Enter a name for this invoice text"
              disabled={loading}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="error-message">{formik.errors.name}</div>
            ) : null}
          </div>
          
          <div className="form-group">
            <label htmlFor="text" className="form-label">
              <FiAlignLeft className="form-label-icon" />
              Template Text
            </label>
            <div className="text-area-container">
              <textarea
                id="text"
                name="text"
                className={`form-textarea ${formik.touched.text && formik.errors.text ? 'input-error' : ''}`}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.text}
                placeholder="Enter the invoice text"
                rows={10}
                disabled={loading}
              />
              
            </div>
            {formik.touched.text && formik.errors.text ? (
              <div className="error-message">{formik.errors.text}</div>
            ) : null}
          </div>
          
          <div className="form-actions">
            <button 
              type="button" 
              className="cancel-button"
              onClick={() => navigate('/invoice_texts/manage')}
              disabled={loading}
            >
              Cancel
            </button>
            <button 
              type="submit" 
              className="submit-button"
              disabled={loading}
            >
              <FiSave className="button-icon" />
              {id ? 'Update Template' : 'Save Template'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvoiceTextForm;