import React, { useState, useEffect } from 'react';
import { FaFile, FaSpinner, FaCode } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs';
import 'prismjs/themes/prism.css';
import '../styles/AdminLogs.css';

const AdminLogs = () => {
  const [logFiles, setLogFiles] = useState([]);
  const [selectedLog, setSelectedLog] = useState(null);
  const [logContent, setLogContent] = useState('');
  const [loading, setLoading] = useState(false);
  const [contentLoading, setContentLoading] = useState(false);

  const { error } = useNotify();
  const { APP_URL } = useAuth();

  useEffect(() => {
    const fetchLogFiles = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${APP_URL}/AWS/logs/all`, {
          headers: {
            'Content-Type': 'application/json'
          },
          credentials: 'include'
        });

        if (!response.ok) {
          error(response)
        }

        const data = await response.json();
        setLogFiles(data.slice(1));
      } catch (err) {
        error(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLogFiles();
  }, []);

  const handleLogSelect = async (filename) => {
    try {
      const fileName = filename.slice(8);
      setSelectedLog(filename);
      setContentLoading(true);
      setLogContent('');

      const response = await fetch(`${APP_URL}/AWS/logs/${fileName}`, {
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        error(response)
      }

      const data = await response.json();
      setLogContent(data);
    } catch (err) {
      error(err.message);
      setLogContent('');
    } finally {
      setContentLoading(false);
    }
  };

  return (
    <div className="admin-logs-container">
      <div className="admin-logs-header">
        <FaCode className="header-icon" />
        <h2>Admin Logs</h2>
      </div>

      <div className="admin-logs-content">
        <div className="logs-sidebar">
          <div className="logs-sidebar-header">
            <h3>Log Files</h3>
            {loading && <FaSpinner className="loading-icon" />}
          </div>

          {loading ? (
            <div className="logs-loading">
              <FaSpinner className="loading-icon" />
              <p>Loading log files...</p>
            </div>
          ) : logFiles.length === 0 ? (
            <div className="logs-empty-state">
              <FaFile className="empty-icon" />
              <p>No log files found</p>
            </div>
          ) : (
            <ul className="logs-list">
              {logFiles.map((filename, index) => (
                <li
                  key={index}
                  className={`logs-list-item ${
                    selectedLog === filename ? 'selected' : ''
                  }`}
                  onClick={() => handleLogSelect(filename)}
                >
                  <FaFile className="file-icon" />
                  <span>{filename}</span>
                </li>
              ))}
            </ul>
          )}
        </div>

        <div className="logs-viewer">
          <div className="logs-viewer-header">
            <h3>Log Viewer</h3>
            {contentLoading && <FaSpinner className="loading-icon" />}
          </div>

          {!selectedLog ? (
            <div className="logs-empty-state">
              <FaCode className="empty-icon" />
              <p>Select a log file to view its content</p>
            </div>
          ) : contentLoading ? (
            <div className="logs-loading">
              <FaSpinner className="loading-icon" />
              <p>Loading log content...</p>
            </div>
          ) : (
            <div className="logs-editor">
              <Editor
                value={logContent}
                onValueChange={(code) => setLogContent(code)}
                highlight={(code) => highlight(code, languages.js, 'javascript')}
                padding={10}
                className="code-editor"
                style={{
                  fontFamily: 'monospace',
                  fontSize: '0.875rem',
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminLogs;