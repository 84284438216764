import React, { useState, useEffect } from 'react';
import { FaPlus, FaEdit, FaTrash, FaSave, FaTimes } from 'react-icons/fa';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import '../styles/ActivityTypes.css';

const ActivityTypesPage = () => {
  const { error, choice, success } = useNotify();
  const { getCookie, APP_URL } = useAuth();
  const [activityTypes, setActivityTypes] = useState([]);
  const [editableActivityType, setEditableActivityType] = useState(null);
  const [newActivityType, setNewActivityType] = useState('');
  const [newChargeType, setNewChargeType] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch activity types
  const fetchActivityTypes = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${APP_URL}/activity-types`, {
        credentials: 'include'
      });

      if (!response.ok) {
        error(response)
      }

      const data = await response.json();
      setActivityTypes(data);
    } catch (err) {
      error(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchActivityTypes();
  }, []);

  // Handle editing an activity type
  const handleEdit = (id, name, value, charge_type) => {
    setEditableActivityType({ id, name, value, charge_type });
  };

  // Update activity type
  const handleUpdate = async (id) => {
    try {
      const response = await fetch(`${APP_URL}/activity-types/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include',
        body: JSON.stringify({
          name: editableActivityType.name,
          value: parseFloat(editableActivityType.value),
          charge_type: editableActivityType.charge_type,
        }),
      });

      if (!response.ok) {
        error(response)
      }

      success('Activity type updated successfully');
      setEditableActivityType(null);
      await fetchActivityTypes();
    } catch (err) {
      error(err.message);
    }
  };

  // Delete activity type
  const handleDeleteClick = (activityType) => {
    choice(`Do you want to delete activity type: ${activityType.name}?`, () => handleDelete(activityType.id));
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${APP_URL}/activity-types/${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include'
      });

      if (!response.ok) {
        error(response)
      }

      success('Activity type deleted successfully');
      await fetchActivityTypes();
    } catch (err) {
      error(err.message);
    }
  };

  // Add new activity type
  const handleAdd = async () => {
    if (!newActivityType.trim()) {
      error('Activity type name cannot be empty');
      return;
    }

    try {
      const response = await fetch(`${APP_URL}/activity-types`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify({ 
          name: newActivityType.trim(), 
          charge_type: newChargeType 
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        error(response)
      }

      success('Activity type added successfully');
      setNewActivityType('');
      setNewChargeType(false);
      await fetchActivityTypes();
    } catch (err) {
      error(err.message);
    }
  };

  // Render loading state
  if (isLoading) {
    return (
      <div className="activity-types-loading">
        <div className="spinner"></div>
        <p>Loading activity types...</p>
      </div>
    );
  }

  return (
    <div className="activity-types-container">
      <div className="activity-types-header">
        <h2>Activity Types</h2>
      </div>

      <div className="activity-types-add-section">
        <div className="add-type-input-group">
          <input
            type="text"
            placeholder="Enter activity type name"
            value={newActivityType}
            onChange={(e) => setNewActivityType(e.target.value)}
          />
          <select
            value={newChargeType}
            onChange={(e) => setNewChargeType(e.target.value === 'true')}
          >
            <option value="false">Non-Chargeable</option>
            <option value="true">Chargeable</option>
          </select>
          <button onClick={handleAdd}>
            <FaPlus /> Add
          </button>
        </div>
      </div>

      {activityTypes.length === 0 ? (
        <div className="activity-types-empty-state">
          <p>No activity types available. Add a new activity type above.</p>
        </div>
      ) : (
        <div className="activity-types-list">
          {activityTypes.map((activityType) => (
            <div 
              key={activityType.id} 
              className="activity-type-item"
            >
              {editableActivityType && editableActivityType.id === activityType.id ? (
                <div className="activity-type-edit-mode">
                  <input
                    type="text"
                    value={editableActivityType.name}
                    onChange={(e) => setEditableActivityType({ 
                      ...editableActivityType, 
                      name: e.target.value 
                    })}
                  />
                  <div className="charge-type-toggle">
                    <input
                      type="checkbox"
                      id={`charge-type-${activityType.id}`}
                      checked={editableActivityType.charge_type}
                      onChange={(e) => setEditableActivityType({ 
                        ...editableActivityType, 
                        charge_type: e.target.checked 
                      })}
                    />
                    <label htmlFor={`charge-type-${activityType.id}`}>
                      Chargeable
                    </label>
                  </div>
                  <div className="edit-actions">
                    <button 
                      className="save-btn" 
                      onClick={() => handleUpdate(editableActivityType.id)}
                    >
                      <FaSave /> Save
                    </button>
                    <button 
                      className="cancel-btn" 
                      onClick={() => setEditableActivityType(null)}
                    >
                      <FaTimes /> Cancel
                    </button>
                  </div>
                </div>
              ) : (
                <div className="activity-type-view-mode">
                  <div className="activity-type-details">
                    <span className="activity-name">{activityType.name}</span>
                    <span className={`activity-charge-type ${activityType.charge_type ? 'chargeable' : 'non-chargeable'}`}>
                      {activityType.charge_type ? 'Chargeable' : 'Non-Chargeable'}
                    </span>
                  </div>
                  <div className="activity-type-actions">
                    <button 
                      className="edit-btn" 
                      onClick={() => handleEdit(
                        activityType.id, 
                        activityType.name, 
                        activityType.value, 
                        activityType.charge_type
                      )}
                    >
                      <FaEdit /> Edit
                    </button>
                    <button 
                      className="delete-btn" 
                      onClick={() => handleDeleteClick(activityType)}
                    >
                      <FaTrash /> Delete
                    </button>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ActivityTypesPage;