import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FaCheck, FaTimes, FaSync } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import '../styles/ClientActivities.css';

const ClientActivities = () => {
    const { id } = useParams();
    const { getCookie, APP_URL } = useAuth();
    const { success, error } = useNotify();
    const [client, setClient] = useState({});
    const [selectedTypes, setSelectedTypes] = useState(new Set());
    const [loading, setLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const [activityTypes, setActivityTypes] = useState([])
    const navigate = useNavigate();

    // // The predefined 15 activity types
    // const activityTypes = [
    //     { id: 1, chargeType: 'Chargeable', name: 'Advisory' },
    //     { id: 2, chargeType: 'Chargeable', name: 'Audit' },
    //     { id: 3, chargeType: 'Chargeable', name: 'Agreed Upon Procedure' },
    //     { id: 4, chargeType: 'Chargeable', name: 'Bookkeeping' },
    //     { id: 5, chargeType: 'Chargeable', name: 'Compilation' },
    //     { id: 6, chargeType: 'Chargeable', name: 'Review' },
    //     { id: 7, chargeType: 'Chargeable', name: 'Tax Return Preparation' },
    //     { id: 8, chargeType: 'Chargeable', name: 'Exempt' },
    //     { id: 9, chargeType: 'Non-Chargeable', name: 'General' },
    //     { id: 10, chargeType: 'Non-Chargeable', name: 'Vacation' },
    //     { id: 11, chargeType: 'Non-Chargeable', name: 'Sick' },
    //     { id: 12, chargeType: 'Non-Chargeable', name: 'Comp Time' },
    //     { id: 13, chargeType: 'Non-Chargeable', name: 'Continuing Professional Education' },
    //     { id: 14, chargeType: 'Non-Chargeable', name: 'Authorized Time Off' },
    //     { id: 15, chargeType: 'Non-Chargeable', name: 'Holiday' }
    // ];

    useEffect(() => {

        const fetchActivityTypes = async () => {
            try {
              const response = await fetch(`${APP_URL}/activity-types`, {
                credentials: 'include'
              });
        
              if (!response.ok) {
                error(response)
              }
        
              const data = await response.json();
              setActivityTypes(data);
            } catch (err) {
              error(err.message);
            } finally {
            }
          };

        const fetchClientData = async () => {
            try {
                // Fetch activity types
                const activityTypesResp = await fetch(`${APP_URL}/clients/${id}/activity-types`, {
                    credentials: 'include'
                });

                if (!activityTypesResp.ok) {
                    error(activityTypesResp)
                }

                const activityTypesData = await activityTypesResp.json();
                const selected = new Set(activityTypesData.map(type => type.id));
                setSelectedTypes(selected);

                // Fetch client details
                const clientResp = await fetch(`${APP_URL}/clients/${id}`, {
                    credentials: 'include'
                });

                if (!clientResp.ok) {
                    error(clientResp)
                }

                const clientData = await clientResp.json();
                setClient(clientData);
                setLoading(false);
            } catch (err) {
                error(err.message);
                navigate('/error');
                setLoading(false);
            }
        };

        fetchActivityTypes()
        fetchClientData();

    }, [id, APP_URL, error, navigate]);

    const handleCheckboxChange = (typeId) => {
        setSelectedTypes(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(typeId)) {
                newSelected.delete(typeId);
            } else {
                newSelected.add(typeId);
            }
            return newSelected;
        });
    };

    const handleSelectAll = () => {
        if (selectedTypes.size === activityTypes.length) {
            setSelectedTypes(new Set());
            return;
        }

        const ids = activityTypes.map(activity => activity.id);
        setSelectedTypes(new Set(ids));
    };

    const handleSubmit = async () => {
        setIsSaving(true);
        const activities = activityTypes.map(type => ({
            activity_id: type.id,
            enabled: selectedTypes.has(type.id),
        }));

        try {
            const response = await fetch(`${APP_URL}/clients/${id}/activity-types`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRF-TOKEN': getCookie('csrf_access_token'),
                },
                body: JSON.stringify({ activities }),
                credentials: 'include'
            });

            if (!response.ok) {
                error(response)
            }

            success('Activity types updated successfully!');
            navigate(`/clients/${id}`);
        } catch (err) {
            error(err.message);
        } finally {
            setIsSaving(false);
        }
    };

    if (loading) {
        return (
            <div className="client-activities-loading">
                <FaSync className="loading-icon" />
                <p>Loading client activities...</p>
            </div>
        );
    }

    // Group activities by charge type
    const chargeableActivities = activityTypes.filter(type => 
        type.charge_type === true && (parseInt(id) !== 0 || true)
    );
    const nonChargeableActivities = activityTypes.filter(type => 
        type.charge_type === false && (parseInt(id) !== 0 || true)
    );

    return (
        <div className="client-activities-container">
            <div className="client-activities-header">
                <h2>Activity Types for {client.legal_name}</h2>
                <button 
                    className="select-all-btn" 
                    onClick={handleSelectAll}
                >
                    {selectedTypes.size === activityTypes.length ? 'Deselect All' : 'Select All'}
                </button>
            </div>

            <div className="activities-grid">
                <div className="activities-section">
                    <h3>Chargeable Activities</h3>
                    <div className="activities-list">
                        {chargeableActivities.map(type => (
                            <div 
                                key={type.id} 
                                className={`activity-item ${selectedTypes.has(type.id) ? 'selected' : ''}`}
                                onClick={() => handleCheckboxChange(type.id)}
                            >
                                <div className="activity-details">
                                    <span className="activity-name">{type.name}</span>
                                    <span className="activity-type">Chargeable</span>
                                </div>
                                <div className="activity-checkbox">
                                    {selectedTypes.has(type.id) ? <FaCheck /> : <FaTimes />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="activities-section">
                    <h3>Non-Chargeable Activities</h3>
                    <div className="activities-list">
                        {nonChargeableActivities.map(type => (
                            <div 
                                key={type.id} 
                                className={`activity-item ${selectedTypes.has(type.id) ? 'selected' : ''}`}
                                onClick={() => handleCheckboxChange(type.id)}
                            >
                                <div className="activity-details">
                                    <span className="activity-name">{type.name}</span>
                                    <span className="activity-type">Non-Chargeable</span>
                                </div>
                                <div className="activity-checkbox">
                                    {selectedTypes.has(type.id) ? <FaCheck /> : <FaTimes />}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>

            <div className="client-activities-footer">
                <button 
                    className="submit-btn" 
                    onClick={handleSubmit}
                    disabled={isSaving}
                >
                    {isSaving ? (
                        <>
                            <FaSync className="loading-icon" />
                            Saving...
                        </>
                    ) : (
                        'Save Activity Types'
                    )}
                </button>
            </div>
        </div>
    );
};

export default ClientActivities;