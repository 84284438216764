import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import '../styles/InvoiceTextManagement.css';
import { 
  FiFileText, 
  FiPlus, 
  FiEdit2, 
  FiTrash2, 
  FiEye, 
  FiSearch,
  FiRefreshCw,
  FiInfo
} from 'react-icons/fi';

const InvoiceTextManagement = () => {
  const { APP_URL, getCookie } = useAuth();
  const { success, error, choice, spinner } = useNotify();
  const [invoiceTexts, setInvoiceTexts] = useState([]);
  const [filteredTexts, setFilteredTexts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [previewText, setPreviewText] = useState(null);

  const loadInvoiceTexts = () => {
    setLoading(true);
    const { complete } = spinner('Loading invoice templates...');
    
    fetch(`${APP_URL}/invoice_texts`, {
      credentials: 'include',
    })
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        } else {
          complete(false);
          error(resp);
          return null;
        }
      })
      .then((data) => {
        if (data) {
          setInvoiceTexts(data);
          setFilteredTexts(data);
          complete(true, 'Templates loaded successfully');
        }
        setLoading(false);
      })
      .catch((e) => {
        complete(false);
        error(e);
        setLoading(false);
      });
  };

  useEffect(() => {
    loadInvoiceTexts();
  }, [APP_URL]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = () => {
    if (!searchQuery.trim()) {
      setFilteredTexts(invoiceTexts);
      return;
    }
    
    const query = searchQuery.toLowerCase().trim();
    const filtered = invoiceTexts.filter(text => 
      text.name.toLowerCase().includes(query) || 
      text.text.toLowerCase().includes(query)
    );
    
    setFilteredTexts(filtered);
  };

  const clearSearch = () => {
    setSearchQuery('');
    setFilteredTexts(invoiceTexts);
  };

  const handleDelete = (text) => {
    choice({
      title: 'Delete Invoice Template',
      message: `Are you sure you want to delete the template "${text.name}"?`,
      confirmText: 'Delete',
      cancelText: 'Cancel',
      onConfirm: () => handleConfirmDelete(text.id)
    });
  };

  const handleConfirmDelete = (id) => {
    const { complete } = spinner('Deleting template...');
    
    fetch(`${APP_URL}/invoice_texts/${id}`, {
      method: 'DELETE',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      credentials: 'include',
    })
      .then((resp) => {
        if (resp.ok) {
          const updatedTexts = invoiceTexts.filter((text) => text.id !== id);
          setInvoiceTexts(updatedTexts);
          setFilteredTexts(updatedTexts);
          complete(true, 'Template deleted successfully');
        } else {
          complete(false);
          error(resp);
        }
      })
      .catch((e) => {
        complete(false);
        error(e);
      });
  };

  const handlePreview = (text) => {
    setPreviewText(text);
  };

  const closePreview = () => {
    setPreviewText(null);
  };

  // Truncate text for display in the table
  const truncateText = (text, maxLength = 100) => {
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + '...';
  };

  return (
    <div className="invoice-management-container">
      <div className="invoice-management-header">
        <div className="header-title">
          <FiFileText className="header-icon" />
          <h2>Invoice Templates</h2>
        </div>
        
        <div className="header-actions">
          <button 
            className="refresh-button" 
            onClick={loadInvoiceTexts}
            title="Refresh Templates"
            disabled={loading}
          >
            <FiRefreshCw className={loading ? 'spin' : ''} />
          </button>
          
          <Link to="/invoice_texts/create" className="create-template-button">
            <FiPlus className="button-icon" />
            <span>Create Template</span>
          </Link>
        </div>
      </div>
      
      <div className="search-container">
        <div className="search-input-group">
          <FiSearch className="search-icon" />
          <input
            type="text"
            className="search-input"
            placeholder="Search templates by name or content..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
          />
          {searchQuery && (
            <button className="clear-search-button" onClick={clearSearch}>
              ×
            </button>
          )}
        </div>
        <button className="search-button" onClick={handleSearch}>
          <FiSearch className="button-icon" />
          <span>Search</span>
        </button>
      </div>

      {loading ? (
        <div className="loading-container">
          <FiRefreshCw className="loading-icon spin" />
          <p>Loading templates...</p>
        </div>
      ) : filteredTexts.length === 0 ? (
        <div className="no-results">
          <FiInfo className="no-results-icon" />
          <p>No invoice templates found.</p>
          {searchQuery ? (
            <>
              <p>Try adjusting your search criteria or create a new template.</p>
              <button className="clear-search-button secondary" onClick={clearSearch}>
                Clear Search
              </button>
            </>
          ) : (
            <p>Get started by creating your first invoice template.</p>
          )}
        </div>
      ) : (
        <div className="templates-table-container">
          <table className="templates-table">
            <thead>
              <tr>
                <th className="template-name-column">Template Name</th>
                <th className="template-content-column">Content Preview</th>
                <th className="template-actions-column">Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredTexts.map((text) => (
                <tr key={text.id}>
                  <td className="template-name-cell">{text.name}</td>
                  <td className="template-content-cell">{truncateText(text.text)}</td>
                  <td className="template-actions-cell">
                    <button 
                      className="action-button preview" 
                      onClick={() => handlePreview(text)}
                      title="Preview Template"
                    >
                      <FiEye />
                    </button>
                    <Link 
                      to={`/invoice_texts/${text.id}/edit`} 
                      className="action-button edit"
                      title="Edit Template"
                    >
                      <FiEdit2 />
                    </Link>
                    <button 
                      className="action-button delete" 
                      onClick={() => handleDelete(text)}
                      title="Delete Template"
                    >
                      <FiTrash2 />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Template Preview Modal */}
      {previewText && (
        <div className="template-preview-overlay" onClick={closePreview}>
          <div className="template-preview-modal" onClick={e => e.stopPropagation()}>
            <div className="preview-header">
              <h3>{previewText.name}</h3>
              <button className="close-preview-button" onClick={closePreview}>×</button>
            </div>
            <div className="preview-content">
              <pre>{previewText.text}</pre>
            </div>
            <div className="preview-footer">
              <div className="preview-actions">
                <Link 
                  to={`/invoice_texts/${previewText.id}/edit`} 
                  className="edit-button"
                >
                  <FiEdit2 className="button-icon" />
                  Edit Template
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvoiceTextManagement;