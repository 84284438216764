import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import '../styles/ResetPassword.css';
import { 
  FiLock, 
  FiEye, 
  FiEyeOff, 
  FiCheck, 
  FiAlertTriangle,
  FiArrowLeft
} from 'react-icons/fi';
import logo from '../assets/clockwise.png';

const ResetPasswordPage = () => {
  const { resetCode } = useParams();
  const { APP_URL, getCookie } = useAuth();
  const { error, success, spinner } = useNotify();
  const navigate = useNavigate();
  
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Password strength indicators
  const getPasswordStrength = (password) => {
    if (!password) return { score: 0, text: 'None', color: '#e2e8f0' };
    
    let score = 0;
    // Length check
    if (password.length >= 8) score += 1;
    // Contains number
    if (/\d/.test(password)) score += 1;
    // Contains lowercase letter
    if (/[a-z]/.test(password)) score += 1;
    // Contains uppercase letter
    if (/[A-Z]/.test(password)) score += 1;
    // Contains special character
    if (/[^A-Za-z0-9]/.test(password)) score += 1;

    const strengthMap = [
      { score: 0, text: 'None', color: '#e2e8f0' },
      { score: 1, text: 'Weak', color: '#fc8181' },
      { score: 2, text: 'Fair', color: '#f6ad55' },
      { score: 3, text: 'Good', color: '#68d391' },
      { score: 4, text: 'Strong', color: '#38a169' },
      { score: 5, text: 'Very Strong', color: '#2f855a' }
    ];

    return strengthMap[score];
  };

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(5, 'Password must be at least 5 characters long')
        .required('Password is required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Passwords must match')
        .required('Please confirm your password'),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      const { complete } = spinner("Resetting your password...");
      
      fetch(`${APP_URL}/reset_password/${resetCode}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          password: values.password
        })
      })
      .then(resp => {
        if (resp.ok) {
          complete(true, 'Your password has been reset! Please log in with your new credentials.');
          setTimeout(() => {
            navigate('/login');
          }, 1500);
        } else {
          complete(false);
          error(resp);
        }
      })
      .catch(e => {
        complete(false);
        error(e);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
    },
  });

  const passwordStrength = getPasswordStrength(formik.values.password);

  return (
    <div className="reset-password-wrapper">
      <div className="reset-password-container">
        <div className="reset-logo-container">
          <img src={logo} alt="Company Logo" className="reset-logo" />
        </div>
        
        <div className="reset-card">
          <Link to="/login" className="back-to-login">
            <FiArrowLeft /> Back to Login
          </Link>
          
          <h1 className="reset-title">Reset Your Password</h1>
          <p className="reset-description">
            Please create a strong, unique password for your account.
          </p>
          
          <form onSubmit={formik.handleSubmit} className="reset-form">
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                <FiLock className="input-icon" />
                New Password
              </label>
              <div className="password-input-container">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className={`password-input ${formik.touched.password && formik.errors.password ? 'input-error' : ''}`}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter your new password"
                  disabled={isSubmitting}
                />
                <button 
                  type="button" 
                  className="password-toggle" 
                  onClick={() => setShowPassword(!showPassword)}
                  aria-label={showPassword ? "Hide password" : "Show password"}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </button>
              </div>
              {formik.touched.password && formik.errors.password && (
                <div className="error-message">
                  <FiAlertTriangle className="error-icon" />
                  {formik.errors.password}
                </div>
              )}
              
              {/* Password strength meter */}
              {formik.values.password && (
                <div className="password-strength">
                  <div className="strength-label">Password Strength</div>
                  <div className="strength-meter">
                    <div className="strength-bar" style={{ 
                      width: `${(passwordStrength.score / 5) * 100}%`,
                      backgroundColor: passwordStrength.color 
                    }}></div>
                  </div>
                  <div className="strength-text" style={{ color: passwordStrength.color }}>
                    {passwordStrength.text}
                  </div>
                </div>
              )}
            </div>

            <div className="form-group">
              <label htmlFor="confirmPassword" className="form-label">
                <FiCheck className="input-icon" />
                Confirm Password
              </label>
              <div className="password-input-container">
                <input
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  className={`password-input ${formik.touched.confirmPassword && formik.errors.confirmPassword ? 'input-error' : ''}`}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Confirm your new password"
                  disabled={isSubmitting}
                />
                <button 
                  type="button" 
                  className="password-toggle" 
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  aria-label={showConfirmPassword ? "Hide password" : "Show password"}
                >
                  {showConfirmPassword ? <FiEyeOff /> : <FiEye />}
                </button>
              </div>
              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className="error-message">
                  <FiAlertTriangle className="error-icon" />
                  {formik.errors.confirmPassword}
                </div>
              )}
            </div>

            <div className="password-requirements">
              <h3 className="requirements-title">Password Requirements:</h3>
              <ul className="requirements-list">
                <li className={formik.values.password.length >= 5 ? 'requirement-met' : ''}>
                  <FiCheck className="requirement-icon" /> At least 5 characters long
                </li>
                {/* <li className={/[A-Z]/.test(formik.values.password) ? 'requirement-met' : ''}>
                  <FiCheck className="requirement-icon" /> Include at least one uppercase letter
                </li>
                <li className={/\d/.test(formik.values.password) ? 'requirement-met' : ''}>
                  <FiCheck className="requirement-icon" /> Include at least one number
                </li> */}
              </ul>
            </div>

            <button
              type="submit"
              className="reset-button"
              disabled={isSubmitting || !formik.isValid}
            >
              {isSubmitting ? 'Resetting...' : 'Reset Password'}
            </button>
          </form>
        </div>
        
        <div className="reset-footer">
          <p>
            Need assistance? <a href="mailto:admin@clockwisecpa.app" className="support-link">Contact Support</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;