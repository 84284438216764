import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { 
  FiDollarSign, 
  FiFileText, 
  FiSave, 
  FiTrash2, 
  FiArrowLeft,
  FiLoader,
  FiAlertCircle
} from 'react-icons/fi';
import '../styles/InvoiceForm.css';

const InvoiceForm = () => {
  const { id } = useParams();
  const { getCookie, APP_URL } = useAuth();
  const { error: notifyError, success } = useNotify();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [clientInfo, setClientInfo] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  // Form validation schema
  const validationSchema = Yup.object({
    total: Yup.number()
      .required('Total amount is required')
      .positive('Total must be a positive number'),
    text: Yup.string()
      .required('Invoice text is required')
  });

  const formik = useFormik({
    initialValues: {
      total: '',
      text: '',
    },
    validationSchema,
    onSubmit: (values) => {
      handleUpdate(values);
    },
  });

  useEffect(() => {
    const fetchInvoiceData = async () => {
      try {
        setLoading(true);
        setError(null);
        
        const response = await fetch(`${APP_URL}/invoices/${id}`, {
          credentials: 'include',
        });

        if (!response.ok) {
          error(response)
        }

        const data = await response.json();
        
        formik.setValues({
          total: data.total || '',
          text: data.text || ''
        });
        
        // If the invoice has client information, store it
        if (data.client_id || data.client_name) {
          setClientInfo({
            id: data.client_id,
            name: data.client_name,
            created_date: data.created_at || data.date,
            invoice_number: data.invoice_number || id
          });
        }
        
      } catch (err) {
        setError(err.message || 'An error occurred while fetching invoice data');
        notifyError(err.message || 'An error occurred while fetching invoice data');
      } finally {
        setLoading(false);
      }
    };

    fetchInvoiceData();
  }, [id, APP_URL, notifyError]);

  const handleUpdate = async (values) => {
    try {
      setSubmitting(true);
      
      const response = await fetch(`${APP_URL}/invoices/${id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
        body: JSON.stringify(values),
      });

      if (!response.ok) {
        error(response)
      }
      
      success('Invoice updated successfully!');
      
      // Optionally navigate back or refresh data
      // navigate(`/invoices/${id}`);
      
    } catch (err) {
      setError(err.message || 'An error occurred while updating the invoice');
      notifyError(err.message || 'An error occurred while updating the invoice');
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    // First click just shows confirmation, second click performs delete
    if (!deleteConfirm) {
      setDeleteConfirm(true);
      return;
    }
    
    try {
      setSubmitting(true);
      
      const response = await fetch(`${APP_URL}/invoices/${id}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
      });

      if (!response.ok) {
        error(response)
      }
      
      success('Invoice deleted successfully!');
      
      // Navigate back to client management or invoices list
      navigate('/clients/manage');
      
    } catch (err) {
      setError(err.message || 'An error occurred while deleting the invoice');
      notifyError(err.message || 'An error occurred while deleting the invoice');
    } finally {
      setSubmitting(false);
      setDeleteConfirm(false);
    }
  };

  const cancelDelete = () => {
    setDeleteConfirm(false);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  // Format currency display
  const formatCurrency = (amount) => {
    if (!amount) return '';
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  // Format date display
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  if (loading) {
    return (
      <div className="invoice-form-loading">
        <FiLoader className="loading-spinner" />
        <span>Loading invoice details...</span>
      </div>
    );
  }

  return (
    <div className="invoice-form-container">
      <div className="invoice-form-header">
        <button 
          type="button" 
          className="back-button"
          onClick={navigateBack}
        >
          <FiArrowLeft />
          <span>Back</span>
        </button>
        <h1>Edit Invoice</h1>
        {clientInfo && (
          <div className="client-badge">
            Client: {clientInfo.name || `#${clientInfo.id}`}
          </div>
        )}
      </div>

      {error && (
        <div className="invoice-form-error">
          <FiAlertCircle />
          <span>{error}</span>
        </div>
      )}

      {clientInfo && (
        <div className="invoice-summary">
          <div className="invoice-summary-item">
            <span className="label">Invoice #:</span>
            <span className="value">{clientInfo.invoice_number}</span>
          </div>
          {clientInfo.created_date && (
            <div className="invoice-summary-item">
              <span className="label">Date:</span>
              <span className="value">{formatDate(clientInfo.created_date)}</span>
            </div>
          )}
          {formik.values.total && (
            <div className="invoice-summary-item">
              <span className="label">Amount:</span>
              <span className="value">{formatCurrency(formik.values.total)}</span>
            </div>
          )}
        </div>
      )}

      <form onSubmit={formik.handleSubmit} className="invoice-form">
        <div className="form-group">
          <label htmlFor="total">
            <FiDollarSign />
            <span>Invoice Total</span>
          </label>
          <div className="input-wrapper">
            <input
              id="total"
              name="total"
              type="number"
              step="0.01"
              value={formik.values.total}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter invoice amount"
              className={formik.touched.total && formik.errors.total ? 'input-error' : ''}
            />
            {formik.touched.total && formik.errors.total && (
              <div className="error-message">{formik.errors.total}</div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="text">
            <FiFileText />
            <span>Invoice Text Value</span>
          </label>
          <div className="input-wrapper">
            <textarea
              id="text"
              name="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter invoice description or details"
              rows={5}
              className={formik.touched.text && formik.errors.text ? 'input-error' : ''}
            />
            {formik.touched.text && formik.errors.text && (
              <div className="error-message">{formik.errors.text}</div>
            )}
          </div>
        </div>

        <div className="invoice-form-actions">
          <button
            type="submit"
            className="update-button"
            disabled={submitting || !formik.isValid}
          >
            <FiSave />
            <span>{submitting ? 'Updating...' : 'Update Invoice'}</span>
          </button>

          {deleteConfirm ? (
            <div className="delete-confirmation">
              <span>Are you sure?</span>
              <button 
                type="button" 
                className="confirm-delete-button"
                onClick={handleDelete}
                disabled={submitting}
              >
                Yes, Delete
              </button>
              <button 
                type="button" 
                className="cancel-delete-button"
                onClick={cancelDelete}
                disabled={submitting}
              >
                Cancel
              </button>
            </div>
          ) : (
            <button
              type="button"
              className="delete-button"
              onClick={handleDelete}
              disabled={submitting}
            >
              <FiTrash2 />
              <span>Delete Invoice</span>
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default InvoiceForm;