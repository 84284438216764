import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNotify } from '../context/NotificationContext';
import StateDropdown from '../components/StateDropdown';
import '../styles/ClientForm.css';
import { 
  FiBriefcase, 
  FiUser, 
  FiMail, 
  FiPhone, 
  FiMapPin, 
  FiCalendar,
  FiTag, 
  FiHash, 
  FiSave, 
  FiTrash2, 
  FiX,
  FiCheck,
  FiRefreshCw
} from 'react-icons/fi';

const ClientForm = () => {
  const { id } = useParams();
  const { error, success, spinner, choice } = useNotify();
  const { getCookie, APP_URL } = useAuth();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(!!id);
  const navigate = useNavigate();

  // Form validation schema
  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format'),
    phone: Yup.string(),
    partner: Yup.string().required('Partner is required'),
    clientId: Yup.number().required('Client ID is required').typeError('Client ID must be a number'),
    legalName: Yup.string().required('Legal Name is required'),
    startDate: Yup.date().required('Start Date is required'),
    status: Yup.string(),
    address: Yup.string(),
    city: Yup.string(),
    state: Yup.string(),
    zip: Yup.string(),
    group: Yup.string(),
    EIN: Yup.string(), // Changed from number to string to allow for formatted EINs
    mainContact: Yup.string(),
    mainEmail: Yup.string().email('Invalid email format'),
    billingContact: Yup.string(),
    billingEmail: Yup.string().email('Invalid email format')
  });

  // Initialize formik
  const formik = useFormik({
    initialValues: {
      email: '',
      phone: '',
      partner: '',
      clientId: '',
      legalName: '',
      startDate: '',
      status: 'Active',
      address: '',
      city: '',
      state: '',
      zip: '',
      group: '',
      EIN: '',
      mainContact: '',
      mainEmail: '',         
      billingContact: '',    
      billingEmail: ''       
    },
    validationSchema,
    onSubmit: handleSubmit
  });

  // Fetch partners list
  useEffect(() => {
    fetchPartners();
  }, []);

  // Fetch client data if editing
  useEffect(() => {
    if (id) {
      setIsEditing(true);
      fetchClientData();
    }
  }, [id]);

  // Fetch partners from API
  const fetchPartners = async () => {
    try {
      const response = await fetch(`${APP_URL}/users`, {
        credentials: 'include'
      });
      
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        error('Failed to load partners');
      }
    } catch (e) {
      error(e);
    }
  };

  // Fetch client data for editing
  const fetchClientData = async () => {
    setLoading(true);
    const { complete } = spinner('Loading client data...');
    
    try {
      const response = await fetch(`${APP_URL}/clients/${id}`, {
        credentials: 'include'
      });
      
      if (response.ok) {
        const data = await response.json();
        
        formik.setValues({
          email: data.email || '',
          phone: data.phone || '',
          partner: data.partner_id || '',
          clientId: data.client_id || '',
          legalName: data.legal_name || '',
          startDate: data.start_date || '',
          status: data.status || 'Active',
          address: data.address || '',
          city: data.city || '',
          state: data.state || '',
          zip: data.zip_code || '',
          group: data.group || '',
          EIN: data.ein || '',
          mainContact: data.main_contact || '',
          mainEmail: data.main_email || '',
          billingContact: data.billing_contact || '',
          billingEmail: data.billing_email || ''
        });
        
        complete(true, 'Client data loaded successfully');
      } else {
        complete(false);
        error('Failed to load client data');
      }
    } catch (e) {
      complete(false);
      error(e);
    } finally {
      setLoading(false);
    }
  };

  // Handle form submission
  function handleSubmit(values) {
    setLoading(true);
    const { complete } = spinner(id ? 'Updating client...' : 'Creating client...');
    
    const payload = {
      email: values.email,
      phone: values.phone,
      partner_id: values.partner,
      client_id: values.clientId,
      legal_name: values.legalName,
      start_date: values.startDate,
      status: values.status,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      group: values.group,
      ein: values.EIN,
      main_contact: values.mainContact,  
      main_email: values.mainEmail,      
      billing_contact: values.billingContact,  
      billing_email: values.billingEmail  
    };

    const requestOptions = {
      method: id ? 'PATCH' : 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
      },
      credentials: 'include',
      body: JSON.stringify(payload),
    };

    fetch(`${APP_URL}/clients${id ? `/${id}` : ''}`, requestOptions)
      .then(async (resp) => {
        if (resp.ok) {
          const data = await resp.json();
          complete(true, id ? 'Client updated!' : 'Client created!');
          navigate(`/clients/${id || data['success']}`);
        } else {
          complete(false);
          error(await resp.json());
        }
      })
      .catch((e) => {
        complete(false);
        error(e);
      })
      .finally(() => setLoading(false));
  }

  // Handle client deletion
  const handleDeleteClient = () => {
    choice(
      'Are you sure you want to delete this client? This action cannot be undone.',
      confirmDeleteClient
    );
  };

  // Confirm and process client deletion
  const confirmDeleteClient = async () => {
    setLoading(true);
    const { complete } = spinner('Deleting client...');
    
    try {
      const response = await fetch(`${APP_URL}/clients/${id}`, {
        method: 'DELETE',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        credentials: 'include'
      });
      
      if (response.ok) {
        complete(true, 'Client deleted!');
        navigate('/clients/manage');
      } else {
        complete(false);
        error('Failed to delete client');
      }
    } catch (e) {
      complete(false);
      error(e);
    } finally {
      setLoading(false);
    }
  };

  if (loading && !formik.values.legalName) {
    return (
      <div className="loading-container">
        <FiRefreshCw className="spinner" />
        <h2>Loading client data...</h2>
      </div>
    );
  }

  return (
    <div className="client-form-container">
      <div className="client-form-header">
        <div className="form-title-section">
          <div className="form-title">
            <FiBriefcase className="form-icon" />
            <h1>{isEditing ? 'Edit Client' : 'Add New Client'}</h1>
          </div>
          <p className="form-subtitle">
            {isEditing 
              ? `Updating information for ${formik.values.legalName}`
              : 'Create a new client in the system'
            }
          </p>
        </div>
      </div>

      <form onSubmit={formik.handleSubmit} className="client-form">
        <div className="form-sections">
          {/* Basic Information Section */}
          <div className="form-section">
            <h2 className="section-title">
              <FiBriefcase className="section-icon" /> Basic Information
            </h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="clientId" className="form-label">
                  <FiHash /> Client ID
                  <span className="required-indicator">*</span>
                </label>
                <input
                  type="text"
                  id="clientId"
                  name="clientId"
                  className={`form-control ${formik.touched.clientId && formik.errors.clientId ? 'is-invalid' : ''}`}
                  value={formik.values.clientId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter client ID"
                />
                {formik.touched.clientId && formik.errors.clientId && (
                  <div className="error-message">{formik.errors.clientId}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="legalName" className="form-label">
                  <FiBriefcase /> Legal Name
                  <span className="required-indicator">*</span>
                </label>
                <input
                  type="text"
                  id="legalName"
                  name="legalName"
                  className={`form-control ${formik.touched.legalName && formik.errors.legalName ? 'is-invalid' : ''}`}
                  value={formik.values.legalName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter legal name"
                />
                {formik.touched.legalName && formik.errors.legalName && (
                  <div className="error-message">{formik.errors.legalName}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="EIN" className="form-label">
                  <FiTag /> EIN
                </label>
                <input
                  type="text"
                  id="EIN"
                  name="EIN"
                  className={`form-control ${formik.touched.EIN && formik.errors.EIN ? 'is-invalid' : ''}`}
                  value={formik.values.EIN}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="XX-XXXXXXX"
                />
                {formik.touched.EIN && formik.errors.EIN && (
                  <div className="error-message">{formik.errors.EIN}</div>
                )}
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="startDate" className="form-label">
                  <FiCalendar /> Start Date
                  <span className="required-indicator">*</span>
                </label>
                <input
                  type="date"
                  id="startDate"
                  name="startDate"
                  className={`form-control ${formik.touched.startDate && formik.errors.startDate ? 'is-invalid' : ''}`}
                  value={formik.values.startDate}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.startDate && formik.errors.startDate && (
                  <div className="error-message">{formik.errors.startDate}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="status" className="form-label">
                  <FiCheck /> Status
                </label>
                <select
                  id="status"
                  name="status"
                  className="form-select"
                  value={formik.values.status}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="Active">Active</option>
                  <option value="Inactive">Inactive</option>
                </select>
              </div>
              
              <div className="form-group">
                <label htmlFor="group" className="form-label">
                  <FiTag /> Group
                </label>
                <input
                  type="text"
                  id="group"
                  name="group"
                  className={`form-control ${formik.touched.group && formik.errors.group ? 'is-invalid' : ''}`}
                  value={formik.values.group}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter group"
                />
                {formik.touched.group && formik.errors.group && (
                  <div className="error-message">{formik.errors.group}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="partner" className="form-label">
                  <FiUser /> Partner
                  <span className="required-indicator">*</span>
                </label>
                <select
                  id="partner"
                  name="partner"
                  className={`form-select ${formik.touched.partner && formik.errors.partner ? 'is-invalid' : ''}`}
                  value={formik.values.partner}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select Partner</option>
                  {users
                    .filter(user => user.role === 'Partner')
                    .map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.username}
                      </option>
                    ))
                  }
                </select>
                {formik.touched.partner && formik.errors.partner && (
                  <div className="error-message">{formik.errors.partner}</div>
                )}
              </div>
            </div>
          </div>
          
          {/* Contact Information Section */}
          <div className="form-section">
            <h2 className="section-title">
              <FiUser className="section-icon" /> Contact Information
            </h2>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="mainContact" className="form-label">
                  <FiUser /> Main Contact
                </label>
                <input
                  type="text"
                  id="mainContact"
                  name="mainContact"
                  className={`form-control ${formik.touched.mainContact && formik.errors.mainContact ? 'is-invalid' : ''}`}
                  value={formik.values.mainContact}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter main contact name"
                />
                {formik.touched.mainContact && formik.errors.mainContact && (
                  <div className="error-message">{formik.errors.mainContact}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="mainEmail" className="form-label">
                  <FiMail /> Main Email
                </label>
                <input
                  type="email"
                  id="mainEmail"
                  name="mainEmail"
                  className={`form-control ${formik.touched.mainEmail && formik.errors.mainEmail ? 'is-invalid' : ''}`}
                  value={formik.values.mainEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter main email"
                />
                {formik.touched.mainEmail && formik.errors.mainEmail && (
                  <div className="error-message">{formik.errors.mainEmail}</div>
                )}
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="billingContact" className="form-label">
                  <FiUser /> Billing Contact
                </label>
                <input
                  type="text"
                  id="billingContact"
                  name="billingContact"
                  className={`form-control ${formik.touched.billingContact && formik.errors.billingContact ? 'is-invalid' : ''}`}
                  value={formik.values.billingContact}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter billing contact name"
                />
                {formik.touched.billingContact && formik.errors.billingContact && (
                  <div className="error-message">{formik.errors.billingContact}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="billingEmail" className="form-label">
                  <FiMail /> Billing Email
                </label>
                <input
                  type="email"
                  id="billingEmail"
                  name="billingEmail"
                  className={`form-control ${formik.touched.billingEmail && formik.errors.billingEmail ? 'is-invalid' : ''}`}
                  value={formik.values.billingEmail}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter billing email"
                />
                {formik.touched.billingEmail && formik.errors.billingEmail && (
                  <div className="error-message">{formik.errors.billingEmail}</div>
                )}
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  <FiMail /> General Email
                </label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter general email"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error-message">{formik.errors.email}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="phone" className="form-label">
                  <FiPhone /> Phone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  className={`form-control ${formik.touched.phone && formik.errors.phone ? 'is-invalid' : ''}`}
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="(XXX) XXX-XXXX"
                />
                {formik.touched.phone && formik.errors.phone && (
                  <div className="error-message">{formik.errors.phone}</div>
                )}
              </div>
            </div>
          </div>
          
          {/* Address Section */}
          <div className="form-section">
            <h2 className="section-title">
              <FiMapPin className="section-icon" /> Address
            </h2>
            <div className="form-row">
              <div className="form-group full-width">
                <label htmlFor="address" className="form-label">
                  <FiMapPin /> Street Address
                </label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  className={`form-control ${formik.touched.address && formik.errors.address ? 'is-invalid' : ''}`}
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter street address"
                />
                {formik.touched.address && formik.errors.address && (
                  <div className="error-message">{formik.errors.address}</div>
                )}
              </div>
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="city" className="form-label">
                  <FiMapPin /> City
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  className={`form-control ${formik.touched.city && formik.errors.city ? 'is-invalid' : ''}`}
                  value={formik.values.city}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter city"
                />
                {formik.touched.city && formik.errors.city && (
                  <div className="error-message">{formik.errors.city}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="state" className="form-label">
                  <FiMapPin /> State
                </label>
                <select 
                  id="state"
                  name="state"
                  className={`form-select ${formik.touched.state && formik.errors.state ? 'is-invalid' : ''}`}
                  value={formik.values.state}
                  onChange={(e) => formik.setFieldValue('state', e.target.value)}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select State</option>
                  <StateDropdown />
                </select>
                {formik.touched.state && formik.errors.state && (
                  <div className="error-message">{formik.errors.state}</div>
                )}
              </div>
              
              <div className="form-group">
                <label htmlFor="zip" className="form-label">
                  <FiMapPin /> Zip Code
                </label>
                <input
                  type="text"
                  id="zip"
                  name="zip"
                  className={`form-control ${formik.touched.zip && formik.errors.zip ? 'is-invalid' : ''}`}
                  value={formik.values.zip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter zip code"
                />
                {formik.touched.zip && formik.errors.zip && (
                  <div className="error-message">{formik.errors.zip}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="form-actions">
          <button 
            type="submit" 
            className="submit-button"
            disabled={loading}
          >
            <FiSave /> {isEditing ? 'Update Client' : 'Create Client'}
          </button>
          
          {isEditing && (
            <button
              type="button"
              className="delete-button"
              onClick={handleDeleteClient}
              disabled={loading}
            >
              <FiTrash2 /> Delete Client
            </button>
          )}
          
          <button
            type="button"
            className="cancel-button"
            onClick={() => navigate(-1)}
            disabled={loading}
          >
            <FiX /> Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientForm;