import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import '../styles/ClientManagement.css';
import { 
  FiBriefcase, 
  FiSearch, 
  FiPlusCircle, 
  FiGrid, 
  FiList, 
  FiRefreshCw,
  FiEdit2,
  FiTrash2,
  FiChevronUp,
  FiChevronDown,
  FiInfo
} from 'react-icons/fi';

const ClientManagement = () => {
    const [clients, setClients] = useState([]);
    const [filteredClients, setFilteredClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [viewMode, setViewMode] = useState('table'); // 'card' or 'table'
    const [sortConfig, setSortConfig] = useState({ key: 'legal_name', direction: 'asc', secondKey: null });
    const navigate = useNavigate();
    const { error, success, choice, spinner } = useNotify();
    const { getCookie, APP_URL } = useAuth();
    
    useEffect(() => {
        // Load clients on first render
        // handleLoadAll();
    }, []);

    // Handle sorting when sortConfig changes
    useEffect(() => {
        if (clients.length > 0 && sortConfig.key) {
            sortClients();
        }
    }, [sortConfig]);

    // Apply search filter
    useEffect(() => {
        if (searchQuery.trim() === '') {
            setFilteredClients(clients);
        } else {
            performLocalSearch();
        }
    }, [searchQuery, clients]);

    // Local search function for quick filtering
    const performLocalSearch = () => {
        const term = searchQuery.toLowerCase().trim();
        const filtered = clients.filter(client => 
            client.legal_name?.toLowerCase().includes(term) ||
            client.client_id?.toLowerCase().includes(term) ||
            client.ein?.toLowerCase().includes(term)
        );
        setFilteredClients(filtered);
    };

    // Server-side search for more advanced searching
    const handleSearch = () => {
        const trimmedQuery = searchQuery.trim();
        if (!trimmedQuery) {
            setFilteredClients(clients);
            return;
        }

        setLoading(true);
        const { complete } = spinner("Searching clients...");
        
        fetch(`${APP_URL}/clients?search_term=${trimmedQuery}`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    error(resp)
                }
            })
            .then(data => {
                setFilteredClients(data);
                complete(true, "Search completed");
            })
            .catch(e => {
                error(e);
                complete(false);
            })
            .finally(() => setLoading(false));
    };

    // Handle Enter key press in search field
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    // Load all clients from the server
    const handleLoadAll = () => {
        setLoading(true);
        const { complete } = spinner("Loading all clients...");
        
        fetch(`${APP_URL}/clients`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    error(resp)
                }
            })
            .then(data => {
                setClients(data);
                setFilteredClients(data);
                sortClients(data);
                complete(true, `${data.length} clients loaded`);
            })
            .catch(e => {
                error(e);
                complete(false);
            })
            .finally(() => setLoading(false));
    };

    // Navigate to client edit page
    const handleEdit = (e, client) => {
        e.stopPropagation();
        navigate(`/clients/${client.id}/edit`);
    };

    // Prompt user to confirm client deletion
    const handleDelete = (e, client) => {
        e.stopPropagation();
        choice(`Are you sure you want to delete client ${client.legal_name} (${client.client_id})?`, 
            () => handleConfirmDelete(client));
    };

    // Delete client after confirmation
    const handleConfirmDelete = (client) => {
        const { complete } = spinner(`Deleting client ${client.client_id}...`);
        
        fetch(`${APP_URL}/clients/${client.id}`, {
            method: "DELETE",
            headers: {
                "X-CSRF-TOKEN": getCookie('csrf_access_token'),
            },
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    setClients(prev => prev.filter(c => c.id !== client.id));
                    setFilteredClients(prev => prev.filter(c => c.id !== client.id));
                    complete(true, "Client deleted successfully");
                } else {
                    error(resp)
                }
            })
            .catch(e => {
                error(e);
                complete(false);
            });
    };

    // Sort clients based on the current sort configuration
    const sortClients = (clientsToSort = filteredClients) => {
        const { key, direction, secondKey } = sortConfig;
        
        if (!key) return clientsToSort;

        const sortedClients = [...clientsToSort].sort((a, b) => {
            let valueA = a[key] ?? '';
            let valueB = b[key] ?? '';
            
            if (secondKey && typeof a[key] === 'object' && typeof b[key] === 'object') {
                valueA = a[key][secondKey] ?? 0;
                valueB = b[key][secondKey] ?? 0;
            }
            
            if (typeof valueA === 'string') {
                return direction === 'asc' 
                    ? valueA.localeCompare(valueB) 
                    : valueB.localeCompare(valueA);
            }
            
            return direction === 'asc' ? valueA - valueB : valueB - valueA;
        });
        
        setFilteredClients(sortedClients);
        return sortedClients;
    };

    // Set sort configuration when a column header is clicked
    const handleSort = (key, secondKey = null) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction, secondKey });
    };


    // Render a sort indicator icon based on current sort configuration
    const renderSortIcon = (key) => {
        if (sortConfig.key !== key) return null;
        
        return sortConfig.direction === 'asc' 
            ? <FiChevronUp className="sort-icon" /> 
            : <FiChevronDown className="sort-icon" />;
    };

    return (
        <div className="client-management-container">
            <div className="client-management-header">
                <div className="header-title-section">
                    <div className="header-title">
                        <FiBriefcase className="header-icon" />
                        <h1>Business Clients</h1>
                    </div>
                    <p className="header-subtitle">Manage and organize your business clients</p>
                </div>
                
                <div className="header-actions">
                    <button 
                        className="action-button create-button" 
                        onClick={() => navigate('/clients/create')}
                    >
                        <FiPlusCircle /> Create Client
                    </button>
                    {/* <button 
                        className="action-button view-toggle-button" 
                        onClick={toggleViewMode}
                    >
                        {viewMode === 'card' ? <FiList /> : <FiGrid />}
                        {viewMode === 'card' ? 'Table View' : 'Card View'}
                    </button> */}
                </div>
            </div>

            <div className="client-management-filters">
                <div className="search-container-cm">
                    <div className="search-input-group">
                        <input
                            type="text"
                            className="search-input"
                            placeholder="Search by legal name, client ID, or EIN"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        <button className="search-button" onClick={handleSearch}>
                            <FiSearch />
                        </button>
                    </div>
                    
                    <button 
                        className="refresh-button" 
                        onClick={handleLoadAll}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Load All"}
                    </button>
                </div>
            </div>

            {filteredClients.length === 0 && !loading ? (
                <div className="no-clients-message">
                    <FiInfo className="message-icon" />
                    <p>No clients found. Try adjusting your search or create a new client.</p>
                </div>
            ) : (
                <>
                    {
                        <div className="client-table-container">
                            <table className="client-table">
                                <thead>
                                    <tr>
                                        <th onClick={() => handleSort('client_id')}>
                                            <div className="th-content">
                                                Client ID {renderSortIcon('client_id')}
                                            </div>
                                        </th>
                                        <th onClick={() => handleSort('legal_name')}>
                                            <div className="th-content">
                                                Legal Name {renderSortIcon('legal_name')}
                                            </div>
                                        </th>
                                        <th onClick={() => handleSort('ein')}>
                                            <div className="th-content">
                                                EIN {renderSortIcon('ein')}
                                            </div>
                                        </th>
                                        <th onClick={() => handleSort('num_entries')}>
                                            <div className="th-content">
                                                Time Entries {renderSortIcon('num_entries')}
                                            </div>
                                        </th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredClients.map(client => (
                                        <tr 
                                            key={client.id} 
                                            className="table-row" 
                                            onClick={() => navigate(`/clients/${client.id}`)}
                                        >
                                            <td>{client.client_id}</td>
                                            <td>{client.legal_name}</td>
                                            <td>{client.ein || 'N/A'}</td>
                                            <td>{client.num_entries}</td>
                                            <td className="actions-cell">
                                                <button 
                                                    className="action-button-d edit" 
                                                    onClick={(e) => handleEdit(e, client)}
                                                >
                                                    <FiEdit2 />
                                                </button>
                                                <button 
                                                    className="action-button-d delete" 
                                                    onClick={(e) => handleDelete(e, client)}
                                                >
                                                    <FiTrash2 />
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    }
                </>
            )}

            {loading && filteredClients.length === 0 && (
                <div className="loading-indicator">
                    <FiRefreshCw className="spinner" />
                    <p>Loading clients...</p>
                </div>
            )}
        </div>
    );
};

export default ClientManagement;