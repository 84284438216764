import React, { useEffect, useState, useCallback } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './context/AuthContext';
import './styles/App.css';
import SideBar from './components/SideBar';
import { useNotify } from './context/NotificationContext';
import { useUtils } from './context/UtilContext';
import UserFeedbackModal from './components/UserFeedbackModal';
import ToolBar from './components/ToolBar';

// Constants for timing
const SESSION_CHECK_INTERVAL = 1000 * 60 * 1; // Check session every 10 minutes
const WARNING_BEFORE_TIMEOUT = 1000 * 60 * 30; // Show warning 30 minutes before timeout
const COUNTDOWN_DURATION = 60; // 60 seconds countdown (changed from 15)

// TESTING VARIABLES - set to true to enable testing mode
const TESTING_MODE = {
  enabled: false,                   // Master switch for testing mode
  showTimeoutImmediately: false,   // Show timeout modal immediately on component mount
  simulateTimeout: false,          // Simulate timeout after delay
  timeoutDelay: 3000,              // Delay before showing timeout modal (in ms)
  shortCountdown: true,            // Use a shorter countdown for testing
  testCountdownDuration: 30        // Test countdown duration in seconds
};

function App() {
  const { refreshUser, user, logout } = useAuth();
  const navigate = useNavigate();
  const { serverDisconnect } = useNotify();
  const { APP_URL } = useAuth();
  const { isToolBarEnabled } = useUtils();
  const {error} = useNotify()
  const location = useLocation();
  
  // Modal states
  const [isUserFeedbackModalOpen, setIsUserFeedbackModalOpen] = useState(false);
  const [isTimeoutModalOpen, setIsTimeoutModalOpen] = useState(false);
  const [countdown, setCountdown] = useState(
    TESTING_MODE.shortCountdown ? TESTING_MODE.testCountdownDuration : COUNTDOWN_DURATION
  );
  const [isServerDisconnected, setIsServerDisconnected] = useState(false);

  // Check if we're on the password reset page
  const isPasswordResetPage = location.pathname.startsWith('/reset_password/');
  
  // User feedback modal handlers
  const openFeedbackModal = () => setIsUserFeedbackModalOpen(true);
  const closeFeedbackModal = () => setIsUserFeedbackModalOpen(false);

  // Timeout modal handlers
  const startCountdown = useCallback(() => {
    setCountdown(
      TESTING_MODE.shortCountdown ? TESTING_MODE.testCountdownDuration : COUNTDOWN_DURATION
    );
    setIsTimeoutModalOpen(true);
  }, []);

  // Testing function to force show the timeout modal
  const testShowTimeoutModal = useCallback(() => {
    startCountdown();
  }, [startCountdown]);

  const handleLogout = () => {
    logout().then((data) => {
        navigate("/login");
        console.log(1928391023809)
        if (data && data.hasOwnProperty("error")) {
            error(data);
        }
    });
};

  const handleStayLoggedIn = useCallback(() => {
    refreshUser().then((loggedIn) => {
      if (loggedIn) {
        setIsTimeoutModalOpen(false);
        // No need to reset countdown here as we'll close the modal
      } else {
        document.cookie = ''
        navigate('/login');
      }
    });
  }, []);

  // Initial auth check
  useEffect(() => {
    if (!isPasswordResetPage) {
      refreshUser().then((loggedIn) => {
        if (!loggedIn) {
          document.cookie = ''
          navigate('/login');
        }
      });
    }
  }, [ isPasswordResetPage]);

  // Regular session checking and timeout warning - only if not in testing mode
  useEffect(() => {
    if (isPasswordResetPage || TESTING_MODE.enabled) return;

    // Regular session refresh
    const sessionCheckInterval = setInterval(() => {
      refreshUser().then((loggedIn) => {
        if (!loggedIn) {
          document.cookie = ''
          navigate('/login');
        }
      });
    }, SESSION_CHECK_INTERVAL);

    // Set timeout for warning
    const warningTimeout = setTimeout(() => {
      startCountdown();
    }, WARNING_BEFORE_TIMEOUT);

    return () => {
      clearInterval(sessionCheckInterval);
      clearTimeout(warningTimeout);
    };
  }, [isPasswordResetPage]);

  // Countdown timer effect
  useEffect(() => {
    if (isTimeoutModalOpen && countdown > 0) {
      const timer = setTimeout(() => {
        setCountdown(countdown - 1);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (isTimeoutModalOpen && countdown === 0) {
      handleLogout();
    }
  }, [isTimeoutModalOpen, countdown]);

  // TESTING: Simulate timeout immediately or after delay
  useEffect(() => {
    if (!TESTING_MODE.enabled) return;
    
    // Show timeout modal immediately or after delay based on testing settings
    if (TESTING_MODE.showTimeoutImmediately) {
      startCountdown();
    } else if (TESTING_MODE.simulateTimeout) {
      const testTimeout = setTimeout(() => {
        startCountdown();
      }, TESTING_MODE.timeoutDelay);
      return () => clearTimeout(testTimeout);
    }
  }, []);

  // Format the countdown time nicely (MM:SS)
  const formatCountdown = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  // Add a test button to the UI when in testing mode
  const renderTestingControls = () => {
    if (!TESTING_MODE.enabled) return null;
    
    return (
      <div className="testing-controls">
        <h4>Testing Controls</h4>
        <button 
          onClick={testShowTimeoutModal}
          className="testing-button"
        >
          Show Timeout Modal
        </button>
      </div>
    );
  };

  if (!user && !isPasswordResetPage) {
    return null; // Don't render anything until authentication is confirmed
  }

  return (
    <div className="App">
      <SideBar />
      <div className="main-content">
        <Outlet />
      </div>
      
      {/* Only show toolbar if enabled */}
      {isToolBarEnabled && <ToolBar openModal={openFeedbackModal} />}
      
      {/* User Feedback Modal */}
      <UserFeedbackModal 
        isOpen={isUserFeedbackModalOpen} 
        onClose={closeFeedbackModal} 
      />
      
      {/* Session Timeout Modal */}
      {isTimeoutModalOpen && (
        <div className="session-timeout-overlay">
          <div className="session-timeout-modal">
            <div className="session-timeout-header">
              <h2 className="session-timeout-title">
                <span className="warning-icon" role="img" aria-label="warning">⚠️</span>
                Session Timeout Warning
              </h2>
            </div>
            <div className="session-timeout-content">
              <div className="session-timeout-message">
                <span className="alert-icon" role="img" aria-label="alert">🔔</span>
                Your session is about to expire due to inactivity.
              </div>
              <p className="session-timeout-description">
                For security reasons, you will be automatically logged out in
                <span 
                  className={`session-timeout-countdown ${countdown < 10 ? 'countdown-warning' : ''}`}
                >
                  {formatCountdown(countdown)}
                </span>
                unless you choose to stay logged in.
              </p>
              <div className="session-timeout-buttons">
                <button 
                  onClick={handleLogout} 
                  className="logout-button"
                >
                  Logout Now
                </button>
                <button 
                  onClick={handleStayLoggedIn} 
                  className="stay-button"
                  autoFocus
                >
                  Stay Logged In
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      
      {/* Testing Controls */}
      {renderTestingControls()}
    </div>
  );
}

export default App;