import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';  // Import for navigation
import '../styles/ReportsPage.css';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import ReportTable from '../components/ReportTable';
import {
  FiFileText,
  FiCalendar,
  FiPrinter,
  FiRefreshCw,
  FiDollarSign,
  FiActivity,
  FiPieChart,
  FiCreditCard,
  FiArrowRight,
  FiClock,
  FiTrendingUp,
  FiFilter,
  FiInfo,
  FiBook,
  FiExternalLink
} from 'react-icons/fi';

const ReportsPage = () => {
  const [selectedReport, setSelectedReport] = useState('');
  const [reportData, setReportData] = useState(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();  // Hook for navigation
  const { APP_URL, getCookie } = useAuth();
  const { formatCurrency } = useUtils();
  const { error, spinner } = useNotify();

  const reportsOptions = [
    { 
      id: 'Accounts Receivable Aging', 
      label: 'Accounts Receivable Aging', 
      icon: <FiCreditCard />,
      description: 'Shows outstanding customer balances categorized by age',
      needsDateRange: false,
      color: '#4299e1'
    },
    { 
      id: 'Invoice Journal', 
      label: 'Invoice Journal', 
      icon: <FiFileText />,
      description: 'Detailed record of all invoices for a specified period',
      needsDateRange: true,
      color: '#48bb78'
    },
    { 
      id: 'Activity Type', 
      label: 'Activity Type', 
      icon: <FiActivity />,
      description: 'Breakdown of activities by type and associated revenue',
      needsDateRange: true,
      color: '#ed8936'
    },
    { 
      id: 'Profit and Loss', 
      label: 'Profit and Loss', 
      icon: <FiDollarSign />,
      description: 'Financial performance summary showing revenue and expenses',
      needsDateRange: true,
      color: '#9f7aea'
    },
  ];

  const fetchReport = async () => {
    setLoading(true);
    const requestBody = { report_name: selectedReport };

    if (!selectedReport) {
      error('Please select a report');
      setLoading(false);
      return;
    }

    if (selectedReport !== 'Accounts Receivable Aging' && (!startDate || !endDate)) {
      error('Start and end date must be selected');
      setLoading(false);
      return;
    }

    if (startDate && endDate) {
      requestBody.start_date = startDate;
      requestBody.end_date = endDate;
    }

    const { complete } = spinner("Creating report...")

    fetch(`${APP_URL}/reports`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    }).then(resp => {
      if (resp.ok) {
        resp.json().then(setReportData)
        complete(true, "Success!")
      } else {
        complete(false)
        error(resp);
      }
    }).catch(e => error(e))
      .finally(() => setLoading(false));
  };

  // Handler to navigate to the AR Ledger page
  const goToArLedger = () => {
    navigate('/ledger');
  };

  // Format current date for report header
  const currentDate = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  // Find the selected report object
  const selectedReportObj = reportsOptions.find(r => r.id === selectedReport);
  
  // Calculate date ranges for quick selections
  const today = new Date();
  const getDateString = (date) => date.toISOString().split('T')[0];
  
  const getQuickDateRange = (range) => {
    const endDate = getDateString(today);
    let startDate;
    
    switch(range) {
      case 'week':
        const weekAgo = new Date(today);
        weekAgo.setDate(today.getDate() - 7);
        startDate = getDateString(weekAgo);
        break;
      case 'month':
        const monthAgo = new Date(today);
        monthAgo.setMonth(today.getMonth() - 1);
        startDate = getDateString(monthAgo);
        break;
      case 'quarter':
        const quarterAgo = new Date(today);
        quarterAgo.setMonth(today.getMonth() - 3);
        startDate = getDateString(quarterAgo);
        break;
      case 'year':
        const yearAgo = new Date(today);
        yearAgo.setFullYear(today.getFullYear() - 1);
        startDate = getDateString(yearAgo);
        break;
      default:
        startDate = '';
    }
    
    return { startDate, endDate };
  };
  
  const handleQuickDateSelect = (range) => {
    const { startDate: newStartDate, endDate: newEndDate } = getQuickDateRange(range);
    setStartDate(newStartDate);
    setEndDate(newEndDate);
  };

  return (
    <div className="reports-container">
      <div className="reports-header print">
        <div className="reports-title-section ">
          <FiPieChart className="reports-header-icon" />
          <h1 className="reports-title">Financial Reports</h1>
        </div>
        <p className="reports-subtitle">Generate and analyze key financial data across your business</p>
      </div>

      {/* AR Ledger Button Section */}
      <div className="reports-ledger-link element-to-hide">
        <button 
          className="reports-ledger-button"
          onClick={goToArLedger}
        >
          <FiBook className="ledger-button-icon" />
          <span>View Accounts Receivable Ledger</span>
          <FiExternalLink className="ledger-button-icon-right" />
        </button>
        <p className="ledger-description">
          Access detailed chronological records of all invoices and payments
        </p>
      </div>

      <div className="reports-selection-container element-to-hide">
        <div className="reports-type-selector">
          <h2 className="selector-title">
            <FiFilter /> Select Report Type
          </h2>
          
          <div className="report-cards-grid">
            {reportsOptions.map((report) => (
              <div 
                key={report.id}
                className={`report-card ${selectedReport === report.id ? 'selected' : ''}`}
                onClick={() => setSelectedReport(report.id)}
                style={selectedReport === report.id ? {borderColor: report.color, backgroundColor: `${report.color}10`} : {}}
              >
                <div 
                  className="report-card-icon" 
                  style={{backgroundColor: `${report.color}20`, color: report.color}}
                >
                  {report.icon}
                </div>
                <div className="report-card-content">
                  <h3 className="report-card-title">{report.label}</h3>
                  <p className="report-card-description">{report.description}</p>
                </div>
                {selectedReport === report.id && (
                  <div className="report-card-check" style={{color: report.color}}>
                    <FiArrowRight />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

        {selectedReport && selectedReportObj?.needsDateRange && (
          <div className="reports-date-selector">
            <h2 className="selector-title">
              <FiCalendar /> Select Date Range
            </h2>
            
            <div className="date-quick-filters">
              <button onClick={() => handleQuickDateSelect('week')} className="date-quick-filter">
                <FiClock /> Past Week
              </button>
              <button onClick={() => handleQuickDateSelect('month')} className="date-quick-filter">
                <FiClock /> Past Month
              </button>
              <button onClick={() => handleQuickDateSelect('quarter')} className="date-quick-filter">
                <FiTrendingUp /> Past Quarter
              </button>
              <button onClick={() => handleQuickDateSelect('year')} className="date-quick-filter">
                <FiTrendingUp /> Past Year
              </button>
            </div>
            
            <div className="date-custom-range">
              <div className="date-input-group">
                <label htmlFor="start-date" className="date-label">Start Date</label>
                <input
                  type="date"
                  id="start-date"
                  className="date-input"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <span className="date-range-separator">to</span>
              <div className="date-input-group">
                <label htmlFor="end-date" className="date-label">End Date</label>
                <input
                  type="date"
                  id="end-date"
                  className="date-input"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        )}
        
        {selectedReport && !selectedReportObj?.needsDateRange && (
          <div className="reports-info-banner">
            <FiInfo className="info-icon" />
            <p className="info-text">This report does not require a date range selection.</p>
          </div>
        )}

        <div className="reports-actions-container">
          <button
            className="reports-action-button generate-button"
            onClick={fetchReport}
            disabled={loading || !selectedReport || (selectedReportObj?.needsDateRange && (!startDate || !endDate))}
          >
            {loading ? <FiRefreshCw className="button-icon spinner" /> : <FiFileText className="button-icon" />}
            <span>{loading ? 'Generating...' : 'Generate Report'}</span>
          </button>

          {reportData && (
            <button
              className="reports-action-button print-button"
              onClick={window.print}
            >
              <FiPrinter className="button-icon" />
              <span>Print Report</span>
            </button>
          )}
        </div>
      </div>

      {loading && (
        <div className="reports-loading-indicator element-to-hide">
          <FiRefreshCw className="loading-icon spinner" />
          <p className="loading-text">Preparing your report...</p>
        </div>
      )}

      {reportData && !loading && (
        <div className="report-result">
          <div className="report-heading">
            <div className="report-title">
              {selectedReportObj?.icon} <span style={{ marginLeft: '8px' }}>{selectedReport}</span>
            </div>
            <div className="report-date">
              <FiCalendar /> <span style={{ marginLeft: '5px' }}>{currentDate}</span>
            </div>
          </div>
          <ReportTable data={reportData} />
        </div>
      )}
    </div>
  );
};

export default ReportsPage;