import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import {
  FiFileText,
  FiDollarSign,
  FiCalendar,
  FiPrinter,
  FiRefreshCw,
  FiFilter,
  FiClock,
  FiArrowDown,
  FiArrowUp,
  FiInfo,
  FiChevronRight,
  FiChevronDown
} from 'react-icons/fi';
import '../styles/AccountsPage.css';

const AccountsReceivableLedger = () => {
  const [ledgerData, setLedgerData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [clientFilter, setClientFilter] = useState('all');
  const [sortField, setSortField] = useState('date');
  const [sortDirection, setSortDirection] = useState('desc');
  const [expandedItems, setExpandedItems] = useState({});
  const [clients, setClients] = useState([]);
  
  const { APP_URL, getCookie } = useAuth();
  const { formatCurrency, formatDate } = useUtils();
  const { error, spinner } = useNotify();

  // Fetch clients on component mount
  useEffect(() => {
    fetchClients();
    // Set default date range to last 90 days
    const today = new Date();
    setEndDate(today.toISOString().split('T')[0]);
    
    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(today.getDate() - 90);
    setStartDate(ninetyDaysAgo.toISOString().split('T')[0]);
  }, []);

  const fetchClients = async () => {
    try {
      const response = await fetch(`${APP_URL}/clients`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
      });
      
      if (response.ok) {
        const data = await response.json();
        setClients(data);
      } else {
        error(response)
      }
    } catch (e) {
      error(e);
    }
  };

  const fetchLedgerData = async () => {
    setLoading(true);
    const { complete } = spinner("Loading ledger data...");
    
    try {
      const requestBody = {
        start_date: startDate,
        end_date: endDate,
        client_id: clientFilter !== 'all' ? clientFilter : null
      };
      
      const response = await fetch(`${APP_URL}/accounts-receivable-ledger`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      
      if (response.ok) {
        const data = await response.json();
        processLedgerData(data);
        complete(true, "Data loaded successfully!");
      } else {
        complete(false);
        error(response);
      }
    } catch (e) {
      complete(false);
      error(e);
    } finally {
      setLoading(false);
    }
  };

  const processLedgerData = (data) => {
    // Combine invoices and payments into a unified timeline
    let ledgerItems = [];
    
    // Process invoices
    if (data.invoices) {
      ledgerItems = [
        ...ledgerItems,
        ...data.invoices.map(invoice => ({
          ...invoice,
          type: 'invoice',
          date: invoice.created_at,
          amount: invoice.total,
          id: invoice.id,
          clientId: invoice.client_id,
          clientName: invoice.client?.legal_name || 'Unknown Client'
        }))
      ];
    }
    
    // Process payments
    if (data.payments) {
      ledgerItems = [
        ...ledgerItems,
        ...data.payments.map(payment => ({
          ...payment,
          type: 'payment',
          date: payment.created_at,
          amount: payment.payment_amount,
          id: payment.id,
          invoiceId: payment.invoice_id,
          clientId: payment.invoice?.client_id,
          clientName: payment.invoice?.client?.legal_name || 'Unknown Client'
        }))
      ];
    }
    
    // Sort ledger items
    ledgerItems = sortLedgerItems(ledgerItems, sortField, sortDirection);
    
    setLedgerData({
      items: ledgerItems,
      summary: {
        totalInvoiced: data.summary?.total_invoiced || 0,
        totalPaid: data.summary?.total_paid || 0,
        outstandingBalance: data.summary?.outstanding_balance || 0
      }
    });
  };

  const sortLedgerItems = (items, field, direction) => {
    return [...items].sort((a, b) => {
      let comparison = 0;
      
      switch (field) {
        case 'date':
          comparison = new Date(a.date) - new Date(b.date);
          break;
        case 'amount':
          comparison = a.amount - b.amount;
          break;
        case 'client':
          comparison = a.clientName.localeCompare(b.clientName);
          break;
        default:
          comparison = new Date(a.date) - new Date(b.date);
      }
      
      return direction === 'asc' ? comparison : -comparison;
    });
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('desc');
    }
    
    if (ledgerData && ledgerData.items) {
      const sortedItems = sortLedgerItems(ledgerData.items, field, 
        sortField === field && sortDirection === 'asc' ? 'desc' : 'asc');
      
      setLedgerData({
        ...ledgerData,
        items: sortedItems
      });
    }
  };

  const toggleItemExpanded = (id) => {
    setExpandedItems({
      ...expandedItems,
      [id]: !expandedItems[id]
    });
  };

  // Calculate date ranges for quick selections
  const getDateString = (date) => date.toISOString().split('T')[0];
  
  const handleQuickDateSelect = (range) => {
    const today = new Date();
    const endDate = getDateString(today);
    let startDate;
    
    switch(range) {
      case 'month':
        const monthAgo = new Date(today);
        monthAgo.setMonth(today.getMonth() - 1);
        startDate = getDateString(monthAgo);
        break;
      case 'quarter':
        const quarterAgo = new Date(today);
        quarterAgo.setMonth(today.getMonth() - 3);
        startDate = getDateString(quarterAgo);
        break;
      case 'year':
        const yearAgo = new Date(today);
        yearAgo.setFullYear(today.getFullYear() - 1);
        startDate = getDateString(yearAgo);
        break;
      default:
        startDate = '';
    }
    
    setStartDate(startDate);
    setEndDate(endDate);
  };

  // Format ledger item details based on type
  const renderLedgerItemDetails = (item) => {
    if (item.type === 'invoice') {
      return (
        <div className="arl-item-details">
          <div className="arl-detail-row">
            <span className="arl-detail-label">Invoice Total:</span>
            <span className="arl-detail-value">{formatCurrency(item.total)}</span>
          </div>
          <div className="arl-detail-row">
            <span className="arl-detail-label">Amount Paid:</span>
            <span className="arl-detail-value">{formatCurrency(item.amount_paid || 0)}</span>
          </div>
          <div className="arl-detail-row">
            <span className="arl-detail-label">Remaining Balance:</span>
            <span className="arl-detail-value">{formatCurrency(item.remaining_total || 0)}</span>
          </div>
          <div className="arl-detail-row">
            <span className="arl-detail-label">Status:</span>
            <span className={`arl-detail-value arl-status ${item.payments_complete ? 'arl-paid' : 'arl-unpaid'}`}>
              {item.payments_complete ? 'Paid in Full' : 'Outstanding'}
            </span>
          </div>
          
          {item.payments && item.payments.length > 0 && (
            <div className="arl-payment-history">
              <h4 className="arl-payment-history-title">Payment History</h4>
              <table className="arl-payment-history-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {item.payments.map(payment => (
                    <tr key={payment.id}>
                      <td>{formatDate(payment.created_at)}</td>
                      <td>{formatCurrency(payment.payment_amount)}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      );
    } else if (item.type === 'payment') {
      return (
        <div className="arl-item-details">
          <div className="arl-detail-row">
            <span className="arl-detail-label">Applied to Invoice:</span>
            <span className="arl-detail-value">{item.invoice_id}</span>
          </div>
          {item.invoice && (
            <>
              <div className="arl-detail-row">
                <span className="arl-detail-label">Invoice Total:</span>
                <span className="arl-detail-value">{formatCurrency(item.invoice.total)}</span>
              </div>
            </>
          )}
        </div>
      );
    }
    
    return null;
  };

  // Current date for report header
  const currentDate = new Date().toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });

  return (
    <div className="arl-container">
      <div className="arl-header">
        <div className="arl-title-section">
          <FiDollarSign className="arl-header-icon" />
          <h1 className="arl-title">Accounts Receivable Ledger</h1>
        </div>
        <p className="arl-subtitle">
          Comprehensive chronological record of all invoices and payments
        </p>
      </div>

      <div className="arl-controls-container">
        <div className="arl-filters">
          <h2 className="arl-section-title">
            <FiFilter /> Filter Options
          </h2>
          
          <div className="arl-filter-section">
            <div className="arl-filter-group">
              <label className="arl-filter-label">Client:</label>
              <select 
                className="arl-filter-select"
                value={clientFilter}
                onChange={(e) => setClientFilter(e.target.value)}
              >
                <option value="all">All Clients</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>
                    {client.legal_name}
                  </option>
                ))}
              </select>
            </div>
            
            <div className="arl-date-filters">
              <button onClick={() => handleQuickDateSelect('month')} className="arl-date-filter-btn">
                <FiClock className="arl-btn-icon" /> Past Month
              </button>
              <button onClick={() => handleQuickDateSelect('quarter')} className="arl-date-filter-btn">
                <FiClock className="arl-btn-icon" /> Past Quarter
              </button>
              <button onClick={() => handleQuickDateSelect('year')} className="arl-date-filter-btn">
                <FiClock className="arl-btn-icon" /> Past Year
              </button>
            </div>
            
            <div className="arl-date-range">
              <div className="arl-date-input-group">
                <label htmlFor="start-date" className="arl-date-label">Start Date</label>
                <input
                  type="date"
                  id="start-date"
                  className="arl-date-input"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <span className="arl-date-separator">to</span>
              <div className="arl-date-input-group">
                <label htmlFor="end-date" className="arl-date-label">End Date</label>
                <input
                  type="date"
                  id="end-date"
                  className="arl-date-input"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="arl-actions">
          <button
            className="arl-button arl-generate-btn"
            onClick={fetchLedgerData}
            disabled={loading || !startDate || !endDate}
          >
            {loading ? <FiRefreshCw className="arl-btn-icon arl-spinner" /> : <FiFileText className="arl-btn-icon" />}
            <span>{loading ? 'Loading...' : 'Generate Ledger'}</span>
          </button>

          {ledgerData && (
            <button
              className="arl-button arl-print-btn"
              onClick={window.print}
            >
              <FiPrinter className="arl-btn-icon" />
              <span>Print Ledger</span>
            </button>
          )}
        </div>
      </div>

      {loading && (
        <div className="arl-loading">
          <FiRefreshCw className="arl-loading-icon arl-spinner" />
          <p className="arl-loading-text">Preparing ledger data...</p>
        </div>
      )}

      {ledgerData && !loading && (
        <div className="arl-results">
          <div className="arl-report-header">
            <div className="arl-report-title">
              <FiDollarSign className="arl-report-icon" /> 
              <span>Accounts Receivable Ledger</span>
            </div>
            <div className="arl-report-date">
              <FiCalendar className="arl-report-icon" /> 
              <span>{currentDate}</span>
            </div>
          </div>
          
          <div className="arl-summary">
            <div className="arl-summary-item">
              <div className="arl-summary-label">Total Invoiced</div>
              <div className="arl-summary-value">{formatCurrency(ledgerData.summary.totalInvoiced)}</div>
            </div>
            <div className="arl-summary-item">
              <div className="arl-summary-label">Total Payments</div>
              <div className="arl-summary-value">{formatCurrency(ledgerData.summary.totalPaid)}</div>
            </div>
            <div className="arl-summary-item arl-highlight">
              <div className="arl-summary-label">Outstanding Balance</div>
              <div className="arl-summary-value">{formatCurrency(ledgerData.summary.outstandingBalance)}</div>
            </div>
          </div>
          
          <div className="arl-table-container">
            <table className="arl-table">
              <thead>
                <tr>
                  <th className="arl-sortable" onClick={() => handleSort('date')}>
                    Date
                    {sortField === 'date' && (
                      sortDirection === 'asc' ? <FiArrowUp className="arl-sort-icon" /> : <FiArrowDown className="arl-sort-icon" />
                    )}
                  </th>
                  <th>Type</th>
                  <th className="arl-sortable" onClick={() => handleSort('client')}>
                    Client
                    {sortField === 'client' && (
                      sortDirection === 'asc' ? <FiArrowUp className="arl-sort-icon" /> : <FiArrowDown className="arl-sort-icon" />
                    )}
                  </th>
                  <th>Description</th>
                  <th className="arl-sortable arl-amount-col" onClick={() => handleSort('amount')}>
                    Amount
                    {sortField === 'amount' && (
                      sortDirection === 'asc' ? <FiArrowUp className="arl-sort-icon" /> : <FiArrowDown className="arl-sort-icon" />
                    )}
                  </th>
                  <th className="arl-expand-col"></th>
                </tr>
              </thead>
              <tbody>
                {ledgerData.items.length === 0 ? (
                  <tr>
                    <td colSpan="6" className="arl-no-data">
                      <FiInfo className="arl-no-data-icon" />
                      <span>No ledger entries found for the selected criteria</span>
                    </td>
                  </tr>
                ) : (
                  ledgerData.items.map((item) => (
                    <React.Fragment key={`${item.type}-${item.id}`}>
                      <tr className={`arl-item ${item.type === 'invoice' ? 'arl-invoice-row' : 'arl-payment-row'}`}>
                        <td>{formatDate(item.date)}</td>
                        <td className="arl-type-cell">
                          <span className={`arl-type-badge ${item.type === 'invoice' ? 'arl-invoice' : 'arl-payment'}`}>
                            {item.type === 'invoice' ? 'Invoice' : 'Payment'}
                          </span>
                        </td>
                        <td>{item.clientName}</td>
                        <td>
                          {item.type === 'invoice' 
                            ? `Invoice #${item.id.substring(0, 8)}...` 
                            : `Payment on Invoice #${item.invoiceId.substring(0, 8)}...`}
                        </td>
                        <td className={`arl-amount-col ${item.type === 'payment' ? 'arl-payment-amount' : ''}`}>
                          {formatCurrency(item.amount)}
                        </td>
                        <td className="arl-expand-col" onClick={() => toggleItemExpanded(item.id)}>
                          {expandedItems[item.id] ? <FiChevronDown /> : <FiChevronRight />}
                        </td>
                      </tr>
                      {expandedItems[item.id] && (
                        <tr className="arl-details-row">
                          <td colSpan="6">
                            {renderLedgerItemDetails(item)}
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountsReceivableLedger;