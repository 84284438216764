import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useNavigate } from 'react-router-dom';
import { useUtils } from '../context/UtilContext';
import '../styles/SettingsPage.css';
import { 
  FiLogOut, 
  FiCalendar, 
  FiSettings, 
  FiTool, 
  FiUser, 
  FiShield,
  FiSliders,
  FiBell,
  FiCheck
} from 'react-icons/fi';

const SettingsPage = () => {
  const { logout, user } = useAuth();
  const { error, success } = useNotify();
  const {settingsObj } = useUtils();

  const {
    isFormattedDatesEnabled, 
    setIsFormattedDatesEnabled, 
    isToolBarEnabled, 
    setIsToolBarEnabled,
    notifsMode,
    setNotifsMode

  } = settingsObj

  
  const navigate = useNavigate();
  const [formattedDates, setFormattedDates] = useState(false);
  const [toolBar, setToolBar] = useState(false);
  // const [notifMethod, setNotifMethod] = useState(false);

  const datesOption = localStorage.getItem('formattedDates') === 'true';
  const toolBarOption = localStorage.getItem('toolBar') === 'true';

  useEffect(() => {
    if (datesOption) {
      setFormattedDates(datesOption);
      setIsFormattedDatesEnabled(datesOption);
    }
    if (toolBarOption) {
      setToolBar(toolBarOption);
      setIsToolBarEnabled(toolBarOption);
    }
    // if(notifMethod){
    //   setNotifMethod(notifMethod)
    //   setNotifsMode(notifMethod)
    // }
  }, []); //eslint-disable-line

  const handleFormattedDatesChange = () => {
    const newValue = !formattedDates;
    localStorage.setItem('formattedDates', newValue);
    setFormattedDates(newValue);
    setIsFormattedDatesEnabled(newValue);
    success(`Date format ${newValue ? 'expanded' : 'simplified'}`);
  };
  
  const handleToolBarChange = () => {
    const newValue = !toolBar;
    localStorage.setItem('toolBar', newValue);
    setToolBar(newValue);
    setIsToolBarEnabled(newValue);
    success(`Toolbar ${newValue ? 'enabled' : 'disabled'}`);
  };

  // const handleNotificationSystemChange = (system) => {
  //   localStorage.setItem('notificationSystem', system);
  //   setNotifsMode(system);
  //   setNotifMethod(system);
  //   success(`Notification system changed to ${system}`);
  // };

  const handleLogout = () => {
    logout().then(data => {
      if (data && !(data.hasOwnProperty('error'))) {
        navigate('/login');
      } else {
        error(data);
      }
    });
  };

  return (
    <div className="settings-container">
      <div className="settings-header">
        <div className="settings-title-container">
          <FiSettings className="settings-icon" />
          <h1 className="settings-title">Settings</h1>
        </div>
        <p className="settings-subtitle">Customize your application preferences</p>
      </div>

      <div className="settings-content">
        <div className="settings-section">
          <div className="section-header">
            <FiUser className="section-icon" />
            <h2 className="section-title">Account Information</h2>
          </div>
          <div className="settings-profile-card">
            <div className="profile-avatar">
              {user?.username?.charAt(0).toUpperCase() || 'U'}
            </div>
            <div className="profile-details">
              <h3 className="profile-name">{user?.username || 'User'}</h3>
              <p className="profile-role">{user?.role || 'User'}</p>
            </div>
          </div>
        </div>

        {/* <div className="settings-section">
          <div className="section-header">
            <FiBell className="section-icon" />
            <h2 className="section-title">Notification Preferences</h2>
          </div>
          <div className="notification-options">
            <div className="notification-option-group">
              <p className="option-description">Select how notifications appear in the application</p>
              
              <div className="notification-option-buttons">
                <button 
                  className={`notification-option-button ${notifMethod === 'notistack' ? 'active' : ''}`}
                  onClick={() => handleNotificationSystemChange('notistack')}
                >
                  <div className="notification-option-icon-container">
                    {notifMethod && <FiCheck className="selected-icon" />}
                  </div>
                  <div className="notification-option-content">
                    <h4>Standard Notifications</h4>
                    <p>Simple, slide-in notifications that appear at the bottom of the screen</p>
                  </div>
                </button>
                
                <button 
                  className={`notification-option-button ${!notifMethod ? 'active' : ''}`}
                  onClick={() => handleNotificationSystemChange('custom')}
                >
                  <div className="notification-option-icon-container">
                    {notifMethod === 'custom' && <FiCheck className="selected-icon" />}
                  </div>
                  <div className="notification-option-content">
                    <h4>Modern Notifications</h4>
                    <p>Enhanced notifications with animations and improved styling</p>
                  </div>
                </button>
              </div>
              
              <div className="notification-preview">
                <h4 className="preview-title">Preview</h4>
                <div className={`notification-preview-box ${notifMethod === 'custom' ? 'custom-preview' : 'standard-preview'}`}>
                  <div className="preview-notification">
                    <div className="preview-icon">
                      <FiCheck />
                    </div>
                    <div className="preview-content">
                      <p>This is how your notifications will appear</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <div className="settings-section">
          <div className="section-header">
            <FiSliders className="section-icon" />
            <h2 className="section-title">Display Preferences</h2>
          </div>
          <div className="settings-options">
            <div className="settings-option">
              <div className="option-info">
                <FiCalendar className="option-icon" />
                <div className="option-details">
                  <h3 className="option-title">Long Format Dates</h3>
                  <p className="option-description">Display dates in full format (e.g. "January 1, 2023" instead of "01/01/2023")</p>
                </div>
              </div>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={isFormattedDatesEnabled}
                  onChange={handleFormattedDatesChange}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>

            <div className="settings-option">
              <div className="option-info">
                <FiTool className="option-icon" />
                <div className="option-details">
                  <h3 className="option-title">Tool Bar</h3>
                  <p className="option-description">Show the toolbar with quick access to common functions</p>
                </div>
              </div>
              <label className="toggle-switch">
                <input
                  type="checkbox"
                  checked={isToolBarEnabled}
                  onChange={handleToolBarChange}
                />
                <span className="toggle-slider"></span>
              </label>
            </div>
          </div>
        </div>

        <div className="settings-section">
          <div className="section-header">
            <FiShield className="section-icon" />
            <h2 className="section-title">Security</h2>
          </div>
          <div className="settings-actions">
            <button className="action-button logout-button" onClick={handleLogout}>
              <FiLogOut className="button-icon" />
              <span>Logout</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;