import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ErrorPage.css';
import { FiAlertTriangle, FiHome, FiArrowLeft, FiRefreshCw } from 'react-icons/fi';

const ErrorPage = () => {
  const handleRefresh = () => {
    window.location.reload();
  };

  const handleGoBack = () => {
    window.history.back();
  };

  return (
    <div className="error-page-container">
      <div className="error-card">
        <div className="error-icon-container">
          <FiAlertTriangle className="error-icon" />
        </div>
        
        <h1 className="error-title">404</h1>
        
        <h2 className="error-subtitle">Page Not Found</h2>
        
        <p className="error-message">
          Oops! The page you're looking for doesn't exist or might have been moved.
        </p>
        
        <div className="error-actions">
          <button 
            className="error-action-button back-button" 
            onClick={handleGoBack}
          >
            <FiArrowLeft /> Go Back
          </button>
          
          <Link to="/" className="error-action-button home-button">
            <FiHome /> Return Home
          </Link>
          
          <button 
            className="error-action-button refresh-button"
            onClick={handleRefresh}
          >
            <FiRefreshCw /> Refresh Page
          </button>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;