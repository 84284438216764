import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import Select from 'react-select';
import { useParams, useNavigate } from 'react-router-dom';
import '../styles/WIPQueue.css';
import { 
  FiX, 
  FiPlusCircle, 
  FiCheckCircle, 
  FiBriefcase, 
  FiDollarSign, 
  FiMail, 
  FiActivity,
  FiCalendar,
  FiClock,
  FiAlertTriangle,
  FiFileText
} from 'react-icons/fi';

const WIPQueue = () => {
  const [showModal, setShowModal] = useState(false);
  const [client, setClient] = useState({});
  const [invoiceTexts, setInvoiceTexts] = useState([]);
  const [selectedInvoiceTextId, setSelectedInvoiceTextId] = useState(null);
  const [totalAmount, setTotalAmount] = useState('');
  const [isClosing, setIsClosing] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  const { APP_URL, getCookie } = useAuth();
  const { success, error, spinner } = useNotify();
  const { formatCurrency } = useUtils();
  const { clientId } = useParams();
  const navigate = useNavigate();

  const getLocalDate = () => {
    const localDate = new Date();
    return localDate.toISOString().split('T')[0];
  };

  const [startDate, setStartDate] = useState(getLocalDate());

  // Fetch client and invoice texts data
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        // Fetch client data
        if (clientId) {
          const clientResponse = await fetch(`${APP_URL}/clients/${clientId}`, {
            headers: { Accept: 'application/json' },
            credentials: 'include',
          });

          if (clientResponse.ok) {
            const clientData = await clientResponse.json();
            setClient(clientData);
          } else {
            error('Failed to load client data');
          }
        }

        // Fetch invoice texts
        const textsResponse = await fetch(`${APP_URL}/invoice_texts`, { 
          credentials: 'include' 
        });

        if (textsResponse.ok) {
          const textsData = await textsResponse.json();
          setInvoiceTexts(textsData);
        } else {
          error('Failed to load invoice texts');
        }
      } catch (err) {
        error(err.message || 'Error loading data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [clientId, APP_URL, error]);

  const handleOpenModal = (closing) => {
    setIsClosing(closing);
    setShowModal(true);
  };

  const handleSubmit = () => {
    // Validate form fields
    if (!selectedInvoiceTextId) {
      error('Please select an invoice text');
      return;
    }

    if (!totalAmount) {
      error('Please enter a total amount');
      return;
    }

    if (!startDate) {
      error('Please select a start date');
      return;
    }

    setSubmitting(true);
    const { complete } = spinner(isClosing ? 'Closing out client...' : 'Creating invoice...');

    const payload = {
      invoice_text_id: selectedInvoiceTextId,
      close: isClosing,
      total: totalAmount,
      start_date: startDate,
      client_id: clientId
    };

    fetch(`${APP_URL}/invoices`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    })
      .then(async (resp) => {
        if (resp.ok) {
          const data = await resp.json();
          complete(true, isClosing ? 'Client closed out successfully' : 'Invoice created successfully');
          setShowModal(false);
          navigate(`/invoices/${data.success}`);
        } else {
          complete(false);
          const errorData = await resp.json();
          error(errorData.message || 'Failed to process request');
        }
      })
      .catch(err => {
        complete(false);
        error(err.message || 'An error occurred');
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const invoiceTextOptions = invoiceTexts.map((text) => ({
    value: text.id,
    label: text.name,
  }));

  // Calculate total amount of client's Work In Progress (WIP)
  const wipAmount = client.wip || 0;

  // Custom styles for select dropdown
  const customSelectStyles = {
    control: (base) => ({
      ...base,
      height: '42px',
      borderRadius: '10px',
      borderColor: 'rgba(0, 0, 0, 0.1)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'var(--primary-color, #4a6cf7)'
      }
    }),
    option: (base, state) => ({
      ...base,
      backgroundColor: state.isSelected 
        ? 'var(--primary-color, #4a6cf7)' 
        : state.isFocused 
          ? 'var(--primary-light, #e8effd)'
          : null,
      color: state.isSelected ? 'white' : 'inherit'
    })
  };

  return (
    <div className="wip-container">
      {loading ? (
        <div className="loading-state">
          <div className="spinner"></div>
          <p>Loading client data...</p>
        </div>
      ) : (
        <div className="wip-card">
          <div className="wip-header">
            <div className="client-title-section">
              <div className="client-title">
                <FiBriefcase className="client-icon" />
                <h1>{client.legal_name || 'Client'}</h1>
              </div>
              <p className="client-subtitle">Work In Progress (WIP) Queue</p>
            </div>
          </div>
          
          <div className="wip-summary">
            <div className="summary-card">
              <div className="summary-card-header">
                <FiDollarSign className="summary-icon" />
                <span>WIP Amount</span>
              </div>
              <div className="summary-value wip-value">
                {formatCurrency(wipAmount)}
              </div>
              <div className="summary-footer">
                Unbilled work-in-progress
              </div>
            </div>
            
            <div className="summary-card">
              <div className="summary-card-header">
                <FiFileText className="summary-icon" />
                <span>Outstanding Invoices</span>
              </div>
              <div className="summary-value invoice-value">
                {formatCurrency(client.outstanding_invoices || 0)}
              </div>
              <div className="summary-footer">
                Unpaid invoice balance
              </div>
            </div>
            
            <div className="summary-card">
              <div className="summary-card-header">
                <FiDollarSign className="summary-icon" />
                <span>Payments Received</span>
              </div>
              <div className="summary-value payment-value">
                {formatCurrency(client.payments_received || 0)}
              </div>
              <div className="summary-footer">
                Total payments to date
              </div>
            </div>
            
            <div className="summary-card">
              <div className="summary-card-header">
                <FiClock className="summary-icon" />
                <span>Hours Logged</span>
              </div>
              <div className="summary-value hours-value">
                {client.hours_logged || 0}
              </div>
              <div className="summary-footer">
                Total billable hours
              </div>
            </div>
          </div>
          
          <div className="client-details">
            <div className="details-row">
              <div className="detail-item">
                <span className="detail-label">Client ID</span>
                <span className="detail-value">{client.client_id}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Email</span>
                <span className="detail-value">{client.email || 'Not provided'}</span>
              </div>
              <div className="detail-item">
                <span className="detail-label">Status</span>
                <span className="detail-value status-badge">{client.status || 'Active'}</span>
              </div>
            </div>
          </div>
          
          <div className="wip-actions">
            <button
              className="wip-button progress-button"
              onClick={() => handleOpenModal(false)}
              // disabled={wipAmount <= 0}
            >
              <FiPlusCircle className="button-icon" />
              Create Progress Bill
            </button>
            <button
              className="wip-button closeout-button"
              onClick={() => handleOpenModal(true)}
              // disabled={wipAmount <= 0}
            >
              <FiCheckCircle className="button-icon" />
              Close Out Client
            </button>
          </div>
          
          {client.hours_logged <= 0 && (
            <div className="no-wip-message">
              <FiAlertTriangle className="alert-icon" />
              <p>There is no billable work-in-progress for this client.</p>
            </div>
          )}
        </div>
      )}

      {/* Modal for invoice creation or client close-out */}
{showModal && (
  <div className="wip-modal-overlay" onClick={() => !submitting && setShowModal(false)}>
    <div className="wip-modal" onClick={(e) => e.stopPropagation()}>
      <div className="wip-modal-header">
        <h2 className="wip-modal-title">
          {isClosing ? (
            <>
              <FiCheckCircle className="wip-modal-title-icon" />
              Close Out Client
            </>
          ) : (
            <>
              <FiPlusCircle className="wip-modal-title-icon" />
              Create Progress Bill
            </>
          )}
        </h2>
        <button
          className="wip-modal-close"
          onClick={() => setShowModal(false)}
          disabled={submitting}
          aria-label="Close modal"
        >
          <FiX />
        </button>
      </div>
      
      <div className="wip-modal-body">
        <div className="wip-form-group">
          <label className="wip-form-label">
            <FiFileText className="wip-form-icon" />
            Invoice Template
          </label>
          <Select
            options={invoiceTextOptions}
            value={invoiceTextOptions.find((option) => option.value === selectedInvoiceTextId)}
            onChange={(selectedOption) => setSelectedInvoiceTextId(selectedOption.value)}
            placeholder="Select an invoice template"
            isDisabled={submitting}
            styles={customSelectStyles}
            className="wip-form-select"
          />
          {!selectedInvoiceTextId && (
            <div className="wip-form-helper">
              Choose a template for your invoice content
            </div>
          )}
        </div>
        
        <div className="wip-form-row">
          <div className="wip-form-group">
            <label className="wip-form-label">
              <FiCalendar className="wip-form-icon" />
              Start Date
            </label>
            <input
              type="date"
              className="wip-form-control"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              disabled={submitting}
            />
          </div>
          
          <div className="wip-form-group">
            <label className="wip-form-label">
              <FiDollarSign className="wip-form-icon" />
              Total Amount
              {isClosing && <span className="wip-required-indicator">*</span>}
            </label>
            <input
              type="number"
              className="wip-form-control"
              placeholder={isClosing ? "Required for closing out" : "Required for progress bill"}
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
              required={isClosing}
              disabled={submitting}
            />
          </div>
        </div>
        
        {isClosing && (
          <div className="wip-form-alert">
            <FiAlertTriangle className="wip-alert-icon" />
            <div className="wip-alert-content">
              <span className="wip-alert-title">Closing Out Client</span>
              <p className="wip-alert-message">
                This action will close all open time entries on 
                this clients account
              </p>
            </div>
          </div>
        )}
        
        <div className="wip-client-summary">
          <div className="wip-summary-label">
            Current WIP Amount:
          </div>
          <div className="wip-summary-value">
            {formatCurrency(wipAmount)}
          </div>
        </div>
      </div>
      
      <div className="wip-modal-footer">
        <button
          className="wip-modal-button wip-cancel-button"
          onClick={() => setShowModal(false)}
          disabled={submitting}
          type="button"
        >
          <FiX className="wip-button-icon" /> 
          Cancel
        </button>
        <button
          className={`wip-modal-button wip-submit-button ${isClosing ? 'wip-closeout-button' : 'wip-invoice-button'}`}
          onClick={handleSubmit}
          disabled={submitting || (isClosing && !totalAmount)}
          type="button"
        >
          {isClosing ? (
            <FiCheckCircle className="wip-button-icon" />
          ) : (
            <FiPlusCircle className="wip-button-icon" />
          )}
          {submitting 
            ? (isClosing ? 'Closing...' : 'Creating...') 
            : (isClosing ? 'Close Out Client' : 'Create Invoice')
          }
        </button>
      </div>
    </div>
  </div>
)}
    </div>
  );
};

export default WIPQueue;