import React, { useState, useEffect } from 'react';
import { Play, X, RefreshCw, Monitor, Cloud, Terminal, Laptop, Server, AlertCircle, Activity, Cpu, HardDrive, Clock } from 'lucide-react';

const RobloxInstanceManager = () => {
  const [instances, setInstances] = useState([
    { 
      id: 'inst-001', 
      name: 'Dev Server 1', 
      status: 'running', 
      os: 'windows', 
      ip: '192.168.1.105',
      uptime: '2h 34m',
      cpu: 23,
      memory: 840,
      game: 'Roleplay World',
      logs: ['Player connection established', 'Loading map assets', 'Script compilation complete']
    },
    { 
      id: 'inst-002', 
      name: 'Test Server', 
      status: 'running', 
      os: 'windows', 
      ip: '192.168.1.105',
      uptime: '47m',
      cpu: 45,
      memory: 1240,
      game: 'Adventure Quest',
      logs: ['Instance initialized', 'World generation complete', 'Player spawning system active']
    },
    { 
      id: 'inst-003', 
      name: 'Build Environment', 
      status: 'stopped', 
      os: 'windows', 
      ip: '192.168.1.105',
      uptime: '0m',
      cpu: 0,
      memory: 0,
      game: 'Asset Creation Studio',
      logs: []
    },
  ]);
  
  const [newInstanceForm, setNewInstanceForm] = useState({
    name: '',
    game: '',
    showForm: false
  });
  
  const [selectedInstance, setSelectedInstance] = useState(instances[0]);
  const [filter, setFilter] = useState('all');
  const [logs, setLogs] = useState([
    { time: '10:23:45', message: 'System initialized, monitoring active', type: 'info' },
    { time: '10:24:12', message: 'Connected to Windows host at 192.168.1.105', type: 'success' },
    { time: '10:25:01', message: 'Dev Server 1 started successfully', type: 'success' },
    { time: '10:32:15', message: 'Test Server started successfully', type: 'success' }
  ]);

  const addLog = (message, type = 'info') => {
    const now = new Date();
    const time = `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`;
    setLogs(prev => [...prev, { time, message, type }]);
  };

  const startInstance = (id) => {
    setInstances(prev => prev.map(inst => 
      inst.id === id ? { ...inst, status: 'starting' } : inst
    ));
    
    addLog(`Starting instance ${id}...`, 'info');
    
    // Simulate startup delay
    setTimeout(() => {
      setInstances(prev => prev.map(inst => 
        inst.id === id ? { 
          ...inst, 
          status: 'running',
          uptime: '0m',
          cpu: Math.floor(Math.random() * 30) + 15,
          memory: Math.floor(Math.random() * 500) + 800,
          logs: [...inst.logs, 'Instance started', 'Connecting to game services']
        } : inst
      ));
      addLog(`Instance ${id} started successfully`, 'success');
    }, 2000);
  };

  const stopInstance = (id) => {
    setInstances(prev => prev.map(inst => 
      inst.id === id ? { ...inst, status: 'stopping' } : inst
    ));
    
    addLog(`Stopping instance ${id}...`, 'warning');
    
    // Simulate shutdown delay
    setTimeout(() => {
      setInstances(prev => prev.map(inst => 
        inst.id === id ? { 
          ...inst, 
          status: 'stopped',
          uptime: '0m',
          cpu: 0,
          memory: 0
        } : inst
      ));
      addLog(`Instance ${id} stopped successfully`, 'success');
    }, 1500);
  };

  const addNewInstance = () => {
    if (!newInstanceForm.name || !newInstanceForm.game) return;
    
    const newId = `inst-${String(instances.length + 1).padStart(3, '0')}`;
    const newInstance = {
      id: newId,
      name: newInstanceForm.name,
      game: newInstanceForm.game,
      status: 'stopped',
      os: 'windows',
      ip: '192.168.1.105',
      uptime: '0m',
      cpu: 0,
      memory: 0,
      logs: []
    };
    
    setInstances(prev => [...prev, newInstance]);
    setNewInstanceForm({ name: '', game: '', showForm: false });
    addLog(`New instance "${newInstanceForm.name}" created`, 'info');
  };

  const refreshAllStatus = () => {
    addLog('Refreshing status of all instances...', 'info');
    // Simulate status refresh
    setTimeout(() => {
      addLog('All instance statuses refreshed', 'success');
    }, 1000);
  };

  const filteredInstances = filter === 'all' 
    ? instances 
    : instances.filter(inst => inst.status === filter);

  return (
    <div className="flex flex-col w-full max-w-6xl mx-auto bg-gray-900 text-gray-200 rounded-lg overflow-hidden shadow-xl border border-gray-700 h-full">
      <div className="flex items-center justify-between p-4 bg-gray-800 border-b border-gray-700">
        <div className="flex items-center">
          <Server className="mr-2 text-blue-400" />
          <h1 className="text-xl font-bold">Roblox Instance Manager</h1>
          <span className="ml-2 px-2 py-1 text-xs bg-blue-600 rounded-full">Cross-Platform</span>
        </div>
        
        <div className="flex space-x-2">
          <button 
            className="px-3 py-1 rounded bg-blue-600 hover:bg-blue-700 flex items-center text-sm"
            onClick={refreshAllStatus}
          >
            <RefreshCw size={14} className="mr-1" /> Refresh All
          </button>
          <button 
            className="px-3 py-1 rounded bg-green-600 hover:bg-green-700 flex items-center text-sm"
            onClick={() => setNewInstanceForm({...newInstanceForm, showForm: true})}
          >
            <Play size={14} className="mr-1" /> Launch New Instance
          </button>
        </div>
      </div>
      
      <div className="flex flex-grow overflow-hidden">
        {/* Left Sidebar - Instance List */}
        <div className="w-64 bg-gray-800 border-r border-gray-700 flex flex-col">
          <div className="p-3 border-b border-gray-700">
            <div className="flex space-x-1 mb-2">
              <button 
                className={`px-2 py-1 text-xs rounded flex-1 ${filter === 'all' ? 'bg-blue-600' : 'bg-gray-700 hover:bg-gray-600'}`}
                onClick={() => setFilter('all')}
              >
                All
              </button>
              <button 
                className={`px-2 py-1 text-xs rounded flex-1 ${filter === 'running' ? 'bg-green-600' : 'bg-gray-700 hover:bg-gray-600'}`}
                onClick={() => setFilter('running')}
              >
                Running
              </button>
              <button 
                className={`px-2 py-1 text-xs rounded flex-1 ${filter === 'stopped' ? 'bg-red-600' : 'bg-gray-700 hover:bg-gray-600'}`}
                onClick={() => setFilter('stopped')}
              >
                Stopped
              </button>
            </div>
          </div>
          
          <div className="flex-grow overflow-y-auto">
            {filteredInstances.map(instance => (
              <div 
                key={instance.id} 
                className={`p-3 border-b border-gray-700 cursor-pointer hover:bg-gray-700 ${selectedInstance?.id === instance.id ? 'bg-gray-700' : ''}`}
                onClick={() => setSelectedInstance(instance)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    {instance.os === 'windows' ? <Monitor size={16} className="mr-2" /> : <Laptop size={16} className="mr-2" />}
                    <span className="font-medium">{instance.name}</span>
                  </div>
                  <span className={`px-2 py-0.5 text-xs rounded-full ${
                    instance.status === 'running' ? 'bg-green-900 text-green-300' : 
                    instance.status === 'stopped' ? 'bg-red-900 text-red-300' :
                    instance.status === 'starting' ? 'bg-yellow-900 text-yellow-300' :
                    instance.status === 'stopping' ? 'bg-orange-900 text-orange-300' :
                    'bg-gray-700 text-gray-300'
                  }`}>
                    {instance.status}
                  </span>
                </div>
                <div className="text-xs text-gray-400 mt-1">
                  {instance.game}
                </div>
              </div>
            ))}
          </div>
        </div>
        
        {/* Main Content - Selected Instance Details */}
        <div className="flex-grow flex flex-col">
          {selectedInstance ? (
            <>
              <div className="p-4 border-b border-gray-700 bg-gray-800">
                <div className="flex justify-between items-center">
                  <div>
                    <h2 className="text-lg font-semibold">{selectedInstance.name}</h2>
                    <p className="text-sm text-gray-400">{selectedInstance.game} • {selectedInstance.os === 'windows' ? 'Windows' : 'Mac'} • {selectedInstance.ip}</p>
                  </div>
                  
                  <div className="flex space-x-2">
                    {selectedInstance.status === 'stopped' && (
                      <button 
                        className="px-3 py-1 rounded bg-green-600 hover:bg-green-700 flex items-center text-sm"
                        onClick={() => startInstance(selectedInstance.id)}
                      >
                        <Play size={14} className="mr-1" /> Start
                      </button>
                    )}
                    {(selectedInstance.status === 'running' || selectedInstance.status === 'starting') && (
                      <button 
                        className="px-3 py-1 rounded bg-red-600 hover:bg-red-700 flex items-center text-sm"
                        onClick={() => stopInstance(selectedInstance.id)}
                      >
                        <X size={14} className="mr-1" /> Stop
                      </button>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="flex-grow p-4 overflow-y-auto">
                <div className="grid grid-cols-4 gap-4 mb-6">
                  <div className="bg-gray-800 p-3 rounded-lg flex items-center">
                    <Clock size={20} className="mr-2 text-blue-400" />
                    <div>
                      <div className="text-xs text-gray-400">Uptime</div>
                      <div className="font-medium">{selectedInstance.uptime}</div>
                    </div>
                  </div>
                  
                  <div className="bg-gray-800 p-3 rounded-lg flex items-center">
                    <Cpu size={20} className="mr-2 text-blue-400" />
                    <div>
                      <div className="text-xs text-gray-400">CPU Usage</div>
                      <div className="font-medium">{selectedInstance.cpu}%</div>
                    </div>
                  </div>
                  
                  <div className="bg-gray-800 p-3 rounded-lg flex items-center">
                    {/* <Memory size={20} className="mr-2 text-blue-400" /> */}
                    <div>
                      <div className="text-xs text-gray-400">Memory</div>
                      <div className="font-medium">{selectedInstance.memory} MB</div>
                    </div>
                  </div>
                  
                  <div className="bg-gray-800 p-3 rounded-lg flex items-center">
                    <HardDrive size={20} className="mr-2 text-blue-400" />
                    <div>
                      <div className="text-xs text-gray-400">Storage</div>
                      <div className="font-medium">427 MB</div>
                    </div>
                  </div>
                </div>
                
                <div className="bg-gray-800 rounded-lg p-4 mb-4">
                  <h3 className="text-sm font-medium mb-2 flex items-center">
                    <Terminal size={16} className="mr-2" /> Instance Logs
                  </h3>
                  <div className="bg-gray-900 rounded p-3 font-mono text-xs h-48 overflow-y-auto">
                    {selectedInstance.logs.length > 0 ? (
                      selectedInstance.logs.map((log, index) => (
                        <div key={index} className="mb-1 text-gray-300">
                          {log}
                        </div>
                      ))
                    ) : (
                      <div className="text-gray-500">No logs available for this instance.</div>
                    )}
                  </div>
                </div>
                
                <div className="bg-gray-800 rounded-lg p-4">
                  <h3 className="text-sm font-medium mb-2 flex items-center">
                    <Activity size={16} className="mr-2" /> Performance Monitor
                  </h3>
                  <div className="bg-gray-900 rounded p-3 h-32 flex items-center justify-center">
                    {selectedInstance.status === 'running' ? (
                      <div className="text-center w-full text-xs text-gray-400">
                        Performance graph would appear here
                      </div>
                    ) : (
                      <div className="text-center w-full text-xs text-gray-500">
                        Instance is not running
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="flex flex-col items-center justify-center h-full">
              <AlertCircle size={48} className="text-gray-600 mb-4" />
              <p className="text-gray-500">Select an instance to view details</p>
            </div>
          )}
        </div>
      </div>
      
      {/* Console/Log Area */}
      <div className="h-32 bg-gray-950 border-t border-gray-700 p-2 overflow-y-auto">
        <div className="text-xs font-mono">
          {logs.map((entry, index) => (
            <div key={index} className={`mb-1 ${
              entry.type === 'error' ? 'text-red-400' : 
              entry.type === 'warning' ? 'text-yellow-400' : 
              entry.type === 'success' ? 'text-green-400' : 'text-gray-300'
            }`}>
              {`[${entry.time}] ${entry.message}`}
            </div>
          ))}
        </div>
      </div>
      
      {/* New Instance Modal */}
      {newInstanceForm.showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 rounded-lg p-6 w-96 border border-gray-700">
            <h3 className="text-lg font-medium mb-4">Launch New Instance</h3>
            
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Instance Name</label>
              <input 
                type="text" 
                value={newInstanceForm.name} 
                onChange={(e) => setNewInstanceForm({...newInstanceForm, name: e.target.value})}
                className="w-full bg-gray-700 border border-gray-600 rounded p-2 text-sm"
                placeholder="Development Server"
              />
            </div>
            
            <div className="mb-4">
              <label className="block text-sm font-medium mb-1">Game</label>
              <input 
                type="text" 
                value={newInstanceForm.game} 
                onChange={(e) => setNewInstanceForm({...newInstanceForm, game: e.target.value})}
                className="w-full bg-gray-700 border border-gray-600 rounded p-2 text-sm"
                placeholder="My Awesome Game"
              />
            </div>
            
            <div className="flex justify-end space-x-2">
              <button 
                className="px-3 py-1 rounded bg-gray-700 hover:bg-gray-600 text-sm"
                onClick={() => setNewInstanceForm({...newInstanceForm, showForm: false})}
              >
                Cancel
              </button>
              <button 
                className="px-3 py-1 rounded bg-blue-600 hover:bg-blue-700 text-sm"
                onClick={addNewInstance}
              >
                Create & Launch
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RobloxInstanceManager;