import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useNotify } from "../context/NotificationContext";
import { 
  FiActivity, 
  FiChevronLeft, 
  FiClock, 
  FiDollarSign, 
  FiMenu, 
  FiPieChart, 
  FiSettings, 
  FiUsers,
  FiLogOut 
} from "react-icons/fi";
import { SiGoogledocs } from "react-icons/si";
import { MdAdminPanelSettings } from "react-icons/md";


const SideBar = () => {
    const { logout, user } = useAuth();
    const { success, error } = useNotify();
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const navigate = useNavigate();

    useEffect(()=>{
        // if(document.location.href)
        if(document.location.pathname.startsWith('/guide')){
            setSidebarCollapsed(true)
        }
    },[document.location.pathname])

    const handleLogout = () => {
        logout().then((data) => {
            navigate("/login");
            if (data && data.hasOwnProperty("error")) {
                error(data);
            }
        });
    };
    useEffect(()=>{
        const root = document.querySelector(':root');
        if(!user){
            root.style.setProperty('--sidebar-width', '0px')
        }else{
            root.style.setProperty('--sidebar-width', sidebarCollapsed ? '70px' : '250px')
        }   
        
       

    },[sidebarCollapsed, user])

    return user ? (
        <aside className={`dashboard-sidebar ${sidebarCollapsed ? 'collapsed' : ''} element-to-hide`}>
            <div className="sidebar-header">
                {!sidebarCollapsed ? (
                    <>
                        <h2 className="app-logo">Clockwise</h2>
                        <button
                            className="collapse-btn"
                            onClick={() => setSidebarCollapsed(true)}
                            aria-label="Collapse Sidebar"
                        >
                            <FiChevronLeft />
                        </button>
                    </>
                ) : (
                    <button
                        className="collapse-btn"
                        onClick={() => setSidebarCollapsed(false)}
                        aria-label="Expand Sidebar"
                    >
                        <FiMenu />
                    </button>
                )}
            </div>
            <nav className="sidebar-nav">
                <ul>
                    <li className="nav-item">
                        <NavLink to="/" className={({isActive}) => isActive ? "active" : ""}>
                            <FiActivity /> <span className="nav-text">Dashboard</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/profile" className={({isActive}) => isActive ? "active" : ""}>
                            <FiClock /> <span className="nav-text">Time Tracking</span>
                        </NavLink>
                    </li>
                    {user && user.role === 'Partner' && (
                        <>
                            <li className="nav-item">
                                <NavLink to="/clients/manage" className={({isActive}) => isActive ? "active" : ""}>
                                    <FiUsers /> <span className="nav-text">Clients</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/billing" className={({isActive}) => isActive ? "active" : ""}>
                                    <FiDollarSign /> <span className="nav-text">Billing</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/reports" className={({isActive}) => isActive ? "active" : ""}>
                                    <FiPieChart /> <span className="nav-text">Reports</span>
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to="/admin" className={({isActive}) => isActive ? "active" : ""}>
                                    <MdAdminPanelSettings /> <span className="nav-text">Admin</span>
                                </NavLink>
                            </li>
                        </>
                    )}
                    <li className="nav-item">
                        <NavLink to="/guide" className={({isActive}) => isActive ? "active" : ""}>
                            <SiGoogledocs /> <span className="nav-text">Guide</span>
                        </NavLink>
                    </li>
                    <li className="nav-item">
                        <NavLink to="/settings" className={({isActive}) => isActive ? "active" : ""}>
                            <FiSettings /> <span className="nav-text">Settings</span>
                        </NavLink>
                    </li>
                    <li className="nav-item logout-item">
                        <Link to="/login" onClick={handleLogout}>
                            <FiLogOut /> <span className="nav-text">Logout</span>
                        </Link>
                    </li>
                </ul>
            </nav>
        </aside>
    ) : null;
};

export default SideBar;