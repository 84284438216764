import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useUtils } from '../context/UtilContext';
import { Link, useNavigate } from 'react-router-dom';
import '../styles/Dashboard.css';

// Assuming you'll use some icons - if you don't have react-icons installed, you'll need to add it
import { 
  FiClock, FiDollarSign, FiUsers, FiFileText, 
  FiActivity, FiPieChart, FiCreditCard, FiSettings,
  FiMenu, FiChevronLeft
} from 'react-icons/fi';

const Dashboard = () => {
    const { user, APP_URL, getCookie } = useAuth();
    const { formatDate } = useUtils();
    const { error } = useNotify();
    const [activities, setActivities] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [partners, setPartners] = useState([]);
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
    const navigate = useNavigate()

    useEffect(() => {
        if(user?.id && getCookie('csrf_access_token')){
            console.log(getCookie('csrf_access_token'))
            // Fetch activities
            fetch(`${APP_URL}/activities`, {
                credentials: 'include'
            })
                .then((resp) => {
                    if (resp.ok) {
                        resp.json().then(setActivities);
                    } else {
                        console.error('Error fetching activities');
                    }
                })
                .catch((e) => error(e));
    
            // Fetch invoices
            fetch(`${APP_URL}/invoices`, {
                credentials: 'include'
            })
                .then((resp) => {
                    if (resp.ok) {
                        resp.json().then(setInvoices);
                    } else {
                        console.error('Error fetching invoices');
                    }
                })
                .catch((e) => error(e));
    
            // Fetch partners
            fetch(`${APP_URL}/users/${user?.id}`, {
                credentials: 'include'
            })
                .then((resp) => {
                    if (resp.ok) {
                        resp.json().then(data => setPartners(data.partnered_clients));
                    } else {
                        error(resp);
                    }
                })
                .catch((e) => error(e));
    
            // Update the current date and time every minute (less CPU-intensive than every second)
            const intervalId = setInterval(() => {
                setCurrentDateTime(new Date());
            }, 60000);
    
            return () => clearInterval(intervalId);
        }
    }, []);

    // Format date properly
    const formattedDate = currentDateTime.toLocaleDateString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });

    const formattedTime = currentDateTime.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
    });

    // Quick link definitions
    const partnerQuickLinks = [
        { name: 'Billing', icon: <FiDollarSign />, href: '/billing', description: 'View Client Totals' },
        { name: 'Payments', icon: <FiCreditCard />, href: '/clients/manage', description: 'Record Client Payments' },
        { name: 'Manage Clients', icon: <FiUsers />, href: '/clients/manage', description: 'Client Operations' },
        { name: 'Reports', icon: <FiPieChart />, href: '/reports', description: 'View Client Reports' }
    ];

    const commonQuickLinks = [
        { name: 'Time & Expense', icon: <FiClock />, href: '/profile', description: 'Record Your Time' }
    ];

    const quickLinks = user?.role === 'Partner' 
        ? [...partnerQuickLinks, ...commonQuickLinks] 
        : commonQuickLinks;

    if (!user || !user.id) return null;

    return (
        <div className="dashboard-container">

            {/* Main Content */}
            <main className={`dashboard-main ${sidebarCollapsed ? 'expanded' : ''}`}>
                <header className="dashboard-topbar">
                    <div className="topbar-date">
                        <span className="date">{formattedDate}</span>
                        <span className="time">{formattedTime}</span>
                    </div>
                    <div className="topbar-user">
                        <div className="user-avatar">
                            {user.username.charAt(0).toUpperCase()}
                        </div>
                        <div className="user-info">
                            <span className="user-name">{user.username}</span>
                            <span className="user-role">{user.role}</span>
                        </div>
                    </div>
                </header>

                <div className="dashboard-welcome">
                    <h1>Welcome back, {user.username}</h1>
                    <p>Here's what's happening with your account today.</p>
                </div>

                {/* Quick Links Section */}
                <section className="dashboard-quicklinks">
                    <h2 className="section-title">Quick Actions</h2>
                    <div className="quicklinks-grid">
                        {quickLinks.map((link, index) => (
                            <Link to={link.href} className="quicklink-card" key={index}>
                                <div className="quicklink-icon">{link.icon}</div>
                                <div className="quicklink-info">
                                    <h3>{link.name}</h3>
                                    <p>{link.description}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                </section>

                {/* Dashboard Statistics Grid */}
                <div className="dashboard-stats-container">
                    <section className="dashboard-partners">
                        <h2 className="section-title">Partner Accounts</h2>
                        <div className="partners-list">
                            {partners?.length > 0 ? (
                                <ul className="partners-grid">
                                    {partners.slice(0, 4).map(partner => (
                                        <li key={partner.id} className="partner-card cursor-pointer" onClick={()=>navigate(`/clients/${partner.id}`)}>
                                            <div className="partner-avatar">
                                                {partner.legal_name.charAt(0).toUpperCase()}
                                            </div>
                                            <div className="partner-details">
                                                <h3>{partner.legal_name}</h3>
                                                <p>Client ID: {partner.client_id}</p>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="empty-state">
                                    <FiUsers className="empty-icon" />
                                    <p>No partners to display</p>
                                </div>
                            )}
                        </div>
                    </section>

                    {user.role === 'Partner' && (
                        <section className="dashboard-invoices">
                            <h2 className="section-title">Latest Invoices</h2>
                            <div className="invoices-list">
                                {invoices?.length > 0 ? (
                                    <ul className="invoice-grid">
                                        {invoices.slice(0, 4).map(invoice => (
                                            <li key={invoice.id} className="invoice-card cursor-pointer" onClick={()=>navigate(`/invoices/${invoice.id}`)}>
                                                <div className="invoice-icon">
                                                    <FiFileText />
                                                </div>
                                                <div className="invoice-details">
                                                    <h3>Invoice #{invoice.id}</h3>
                                                    <p>{invoice.created_at ? formatDate(invoice.created_at) : 'No date'}</p>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                ) : (
                                    <div className="empty-state">
                                        <FiFileText className="empty-icon" />
                                        <p>No invoices to display</p>
                                    </div>
                                )}
                            </div>
                        </section>
                    )}

                    <section className="dashboard-activities">
                        <h2 className="section-title">Recent Activity</h2>
                        <div className="activities-list">
                            {activities?.length > 0 ? (
                                <ul className="activity-timeline">
                                    {activities.slice(0, 5).map(activity => (
                                        <li key={activity.id} className="activity-item">
                                            <div className="activity-dot"></div>
                                            <div className="activity-content">
                                                <p>{activity.action}</p>
                                                <span className="activity-time">
                                                    {activity.created_at ? formatDate(activity.created_at) : 'No date'}
                                                </span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            ) : (
                                <div className="empty-state">
                                    <FiActivity className="empty-icon" />
                                    <p>No activities to display</p>
                                </div>
                            )}
                            {activities?.length > 5 && (
                                <Link to="/activities" className="view-all-link">View all activities</Link>
                            )}
                        </div>
                    </section>
                </div>
            </main>
        </div>
    );
};

export default Dashboard;