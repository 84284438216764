import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';

const UserFeedbackModal = ({ isOpen, onClose, complete }) => {
  const [feedback, setFeedback] = useState('');
  const {APP_URL,getCookie} = useAuth()
  const {error} = useNotify()

  const handleSubmit = () => {
    fetch(`${APP_URL}/feedback`,{
        credentials: 'include',
        method: 'POST',
        headers:{
            'Content-Type':'application/json',
            'X-CSRF-TOKEN' : getCookie('csrf_access_token')
        },
        body: JSON.stringify({
            'text':feedback
        })
    },{active: true})
    .then(resp =>{
        if(resp.ok){
            onClose();
        }else{
            error(resp)
        }
    })
    .catch(e => error(e))
  };

  return (
    isOpen && (
      <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50">
        <div className="bg-white rounded-lg p-6 w-96">
          <h2 className="text-xl font-semibold mb-4">Submit Feedback</h2>
          <textarea
            className="w-full p-2 border border-gray-300 rounded-lg mb-4"
            placeholder="Share your feedback..."
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            rows="4"
          />
          <div className="flex justify-end space-x-2">
            <button
              className="bg-gray-300 px-4 py-2 rounded text-gray-700"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              className="bg-blue-600 px-4 py-2 rounded text-white"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    )
  );
};

export default UserFeedbackModal;
