import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import '../styles/InvoicePage.css'
import { useUtils } from '../context/UtilContext';
import companyLogo from '../assets/FesterChapman.jpg'

const InvoicePage = () => {
  const { id } = useParams();
  const { APP_URL } = useAuth();
  const { formatCurrency, formatDate, isToolBarEnabled } = useUtils()
  const { error } = useNotify();
  const [invoice, setInvoice] = useState([]);
  const [loading, setLoading] = useState(true)
  const [agingReport, setAgingReport] = useState({})
  const navigate = useNavigate();


  useEffect(() => {
    fetch(`${APP_URL}/invoices/${id}`, {
      credentials: 'include'
    })
      .then((resp) => {
        if (resp.ok) {
          resp.json().then((data) => { setInvoice(data); setAgingReport(decode(data.aging_report)) });
          setLoading(false)

        } else {
          if (resp.status === 401 && resp.json) {
            resp.json().then(data => {
              if (!data['msg']) {
                navigate('/access-denied')
              }
            })

          } else {
            navigate('/error')
            error(resp)
          }

        }
      })
      .catch((e) => error(e));
  }, [id]); //eslint-disable-line


  const decode = (encodedString) => {
    const totals = encodedString.split(';')
    let decodedObj = {}

    for (const item of totals) {
      const total = item.split(":")
      const date = total[0]
      const value = total[1]
      decodedObj[date] = value

    }

    return decodedObj

  }

  console.log(agingReport)

  return !loading ?
    (
      <>
        <div className="bg-white mx-auto max-w-3xl">
          <div className="px-6">
            <div className="flex justify-between items-center mb-6">
              <div>
                {/* <h2 className="text-3xl font-bold mb-2">FESTER &&#13; CHAMPAN <small>PLLC</small></h2>
                <h3 className=" text-lg font-bold">CERTIFIED PUBLIC ACCOUNTANTS</h3> */}
                <img src={companyLogo} alt="" className="w-64 h-auto mx-auto mb-8" />
              </div>
            </div>

            <div className="py-3 border-t border-b border-gray-400">
              <p className="text-gray-700">{invoice.client?.legal_name}<br />{invoice.client?.address}<br />{invoice.client?.city}, {invoice.client?.state} {invoice.client?.zip_code}</p>
              <p className='text-right'>ID: {invoice.id}<br />Date: {formatDate(invoice.created_at)}</p>
            </div>

            <ul>
              <li className='text-right'>
                <span className='total-label'>{invoice.text}</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Billed Time & Expenses</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Invoice Total</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Beginning Balance</span>
                {formatCurrency(agingReport['beginning_balance'])}
              </li>
              <li className='text-right'>
                <span className='total-label'>Invoices</span>
                {formatCurrency(invoice.total)}
              </li>
              <hr className='f'></hr>
              <li className='text-right'>
                <span className='total-label'>Amount Due</span>
                {formatCurrency(Number.parseFloat(agingReport['beginning_balance']))}
              </li>
              <hr className='f'></hr>
              <hr className='f'></hr>
            </ul>
            {/* <ul>
              <li>
                <span className='label'>test</span>
              </li>
            </ul> */}
            <table className="w-full text-sm text-gray-700 my-6">
              <tbody>
                <tr>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[0])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[1])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[2])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[3])}</td>
                  <td className="px-2 py-2 underline">{formatDate(Object.keys(agingReport)[4])}+</td>
                  <td className="px-2 py-2 text-right font-bold underline">Total</td>
                </tr>
                <tr>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[0]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[1]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[2]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[3]])}</td>
                  <td className="px-2 py-2">{formatCurrency(agingReport[Object.keys(agingReport)[4]])}</td>
                  <td className="px-2 py-2 text-right font-bold">{formatCurrency(agingReport['total'])}</td>
                </tr>
              </tbody>
            </table>

            <div className="text-sm mb-6">
              <p>If you would like to pay by credit card, please call our office at 602-264-3077. We will add a 3.5% processing fee for all credit card payments.</p>
            </div>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            <div className="flex justify-between items-center">
              <p className="text-sm font-bold" style={{ "color": "rgb(54, 121, 222)" }}>9019 East Bahia Drive Suite 100<br />Scottsdale, AZ 85260</p>
              <p className="text-sm font-bold" style={{ "color": "rgb(54, 121, 222)" }}>Phone: 602-264-3077</p>
            </div>
          </div>
          {!isToolBarEnabled && <div className="bottom-0 left-0 w-full py-1 flex justify-between items-center px-4 element-to-hide">
          <button></button>
          
          <button
            className="bg-green-600 text-white px-4 py-1 rounded element-to-hide"
            onClick={() => window.print()}>
            Print to Paper
          </button>
          <button
            className="bg-red-600 text-white px-4 py-1 rounded element-to-hide"
            onClick={() => navigate('/billing')}>
            Go Back
          </button>
          <button></button>
        </div>}
        </div>
      </>
    )
    :
    <h1 className="text-center text-4xl text-gray-700 font-bold mt-10">Loading...</h1>

};

export default InvoicePage;

// style={{"color":"rgb(54, 121, 222)"}}> 