import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { useNotify } from "../context/NotificationContext";
import { useUtils } from "../context/UtilContext";
import '../styles/Client.css';
import { 
  FiBriefcase, 
  FiClock, 
  FiFileText, 
  FiDollarSign, 
  FiUser, 
  FiMail,
  FiPhone, 
  FiCalendar, 
  FiMapPin, 
  FiTag, 
  FiChevronDown,
  FiChevronUp, 
  FiEdit2, 
  FiList,
  FiCheck,
  FiX,
  FiRefreshCw,
  FiPlusCircle,
  FiSettings,
  FiCreditCard,
  FiSearch
} from 'react-icons/fi';

const Client = () => {
    const [activeTab, setActiveTab] = useState('summary');
    const { id } = useParams();
    const navigate = useNavigate();
    const [client, setClient] = useState({});
    const [showClientInfo, setShowClientInfo] = useState(false);
    const { user, APP_URL } = useAuth();
    const { formatDate, formatCurrency, sum } = useUtils();
    const { error, spinner } = useNotify();
    
    // State for showing all entries
    const [showAllTimeEntries, setShowAllTimeEntries] = useState(false);
    const [showAllInvoices, setShowAllInvoices] = useState(false);
    
    // Loading states
    const [clientLoading, setClientLoading] = useState(true);
    const [timeEntriesLoading, setTimeEntriesLoading] = useState(false);
    const [invoicesLoading, setInvoicesLoading] = useState(false);
    const [paymentsLoading, setPaymentsLoading] = useState(false);
    
    // Data states
    const [timeEntries, setTimeEntries] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [payments, setPayments] = useState([]);
    
    // Search states
    const [timeEntrySearch, setTimeEntrySearch] = useState('');
    const [invoiceSearch, setInvoiceSearch] = useState('');
    const [paymentSearch, setPaymentSearch] = useState('');
    
    // Filtered data states
    const [filteredTimeEntries, setFilteredTimeEntries] = useState([]);
    const [filteredInvoices, setFilteredInvoices] = useState([]);
    const [filteredPayments, setFilteredPayments] = useState([]);

    // Fetch client data
    useEffect(() => {
        const fetchClientData = async () => {
            setClientLoading(true);
            
            try {
                const response = await fetch(`${APP_URL}/clients/${id}`, {
                    credentials: 'include'
                });
                
                if (response.ok) {
                    const data = await response.json();
                    setClient(data);
                } else {
                    if (response.status === 401) {
                        try {
                            const data = await response.json();
                            if (!data['msg']) {
                                navigate('/access-denied');
                            }
                        } catch (e) {
                            navigate('/access-denied');
                        }
                    } else {
                        navigate('/error');
                        error('Failed to load client');
                    }
                }
            } catch (e) {
                error(e);
                navigate('/error');
            } finally {
                setClientLoading(false);
            }
        };
        
        fetchClientData();
    }, [id]);

    // Fetch time entries when tab is selected
    useEffect(() => {
        if (activeTab === 'timeEntries' && !timeEntries.length) {
            fetchTimeEntries();
        }
    }, [activeTab]);

    // Fetch invoices when tab is selected
    useEffect(() => {
        if (activeTab === 'invoices' && !invoices.length) {
            fetchInvoices();
        }
    }, [activeTab]);

    // Fetch payments when tab is selected
    useEffect(() => {
        if (activeTab === 'payments' && !payments.length) {
            fetchPayments();
        }
    }, [activeTab]);

    // Filter time entries when search changes or show all changes
    useEffect(() => {
        if (!timeEntries.length) return;
        
        let filtered = timeEntries;
        
        // Filter by closed status if not showing all
        if (!showAllTimeEntries) {
            filtered = filtered.filter(entry => !entry.closed);
        }
        
        // Apply search filter
        if (timeEntrySearch) {
            const searchLower = timeEntrySearch.toLowerCase();
            filtered = filtered.filter(entry => 
                (entry.activity_type?.name && entry.activity_type.name.toLowerCase().includes(searchLower)) ||
                (entry.user?.username && entry.user.username.toLowerCase().includes(searchLower)) ||
                (entry.id && entry.id.toString().toLowerCase().includes(searchLower))
            );
        }
        
        setFilteredTimeEntries(filtered);
    }, [timeEntrySearch, showAllTimeEntries, timeEntries]);

    // Filter invoices when search changes or show all changes
    useEffect(() => {
        if (!invoices.length) return;
        
        let filtered = invoices;
        
        // Filter by payment status if not showing all
        if (!showAllInvoices) {
            filtered = filtered.filter(invoice => !invoice.payments_complete);
        }
        
        // Apply search filter
        if (invoiceSearch) {
            const searchLower = invoiceSearch.toLowerCase();
            filtered = filtered.filter(invoice => 
                (invoice.id && invoice.id.toString().toLowerCase().includes(searchLower))
            );
        }
        
        setFilteredInvoices(filtered);
    }, [invoiceSearch, showAllInvoices, invoices]);

    // Filter payments when search changes
    useEffect(() => {
        if (!payments.length) return;
        
        let filtered = payments;
        
        // Apply search filter
        if (paymentSearch) {
            const searchLower = paymentSearch.toLowerCase();
            filtered = filtered.filter(payment => 
                (payment.id && payment.id.toString().toLowerCase().includes(searchLower)) ||
                (payment.invoice && payment.invoice.id.toString().toLowerCase().includes(searchLower))
            );
        }
        
        setFilteredPayments(filtered);
    }, [paymentSearch, payments]);

    // Fetch time entries
    const fetchTimeEntries = async () => {
        setTimeEntriesLoading(true);
        const { complete } = spinner("Loading time entries...");
        
        try {
            const response = await fetch(`${APP_URL}/clients/${id}/time_entries`, {
                credentials: 'include'
            });
            
            if (response.ok) {
                const data = await response.json();
                setTimeEntries(data);
                setFilteredTimeEntries(data.filter(entry => !showAllTimeEntries ? !entry.closed : true));
                complete(true, '', true);
            } else {
                error('Failed to load time entries');
                complete(false);
            }
        } catch (e) {
            error(e);
            complete(false);
        } finally {
            setTimeEntriesLoading(false);
        }
    };

    // Fetch invoices
    const fetchInvoices = async () => {
        setInvoicesLoading(true);
        const { complete } = spinner("Loading invoices...");
        
        try {
            const response = await fetch(`${APP_URL}/clients/${id}/invoices`, {
                credentials: 'include'
            });
            
            if (response.ok) {
                const data = await response.json();
                setInvoices(data);
                setFilteredInvoices(data.filter(invoice => !showAllInvoices ? !invoice.payments_complete : true));
                complete(true, '', true);
            } else {
                error('Failed to load invoices');
                complete(false);
            }
        } catch (e) {
            error(e);
            complete(false);
        } finally {
            setInvoicesLoading(false);
        }
    };

    // Fetch payments
    const fetchPayments = async () => {
        setPaymentsLoading(true);
        const { complete } = spinner("Loading payments...");
        
        try {
            const response = await fetch(`${APP_URL}/clients/${id}/payments`, {
                credentials: 'include'
            });
            
            if (response.ok) {
                const data = await response.json();
                setPayments(data);
                setFilteredPayments(data);
                complete(true, '' , true);
            } else {
                error('Failed to load payments');
                complete(false);
            }
        } catch (e) {
            error(e);
            complete(false);
        } finally {
            setPaymentsLoading(false);
        }
    };

    // Handle time entry search
    const handleTimeEntrySearch = (e) => {
        e.preventDefault();
        // Search is already applied via useEffect
    };

    // Handle invoice search
    const handleInvoiceSearch = (e) => {
        e.preventDefault();
        // Search is already applied via useEffect
    };

    // Handle payment search
    const handlePaymentSearch = (e) => {
        e.preventDefault();
        // Search is already applied via useEffect
    };

    // Calculate total values for summary
    const totalTimeEntries = sum(timeEntries, 'total', 0);
    const totalInvoices = sum(invoices, 'total', 0);
    const totalPayments = sum(payments, 'payment_amount', 0);
    const wipAmount = client.wip || 0;

    if (clientLoading) {
        return (
            <div className="loading-container">
                <FiRefreshCw className="spinner" />
                <h2>Loading client details...</h2>
            </div>
        );
    }

    return client.legal_name ? (
        <div className="client-detail-container">
            <div className="client-header">
                <div className="client-title-section">
                    <div className="client-title">
                        <FiBriefcase className="client-icon" />
                        <h1>{client.legal_name}</h1>
                    </div>
                    <div className="client-subtitle">
                        <span className="client-id">Client ID: {client.client_id}</span>
                        {client.partner && (
                            <span className="client-partner">
                                Partner: {client.partner.username}
                            </span>
                        )}
                    </div>
                </div>
                
                {user?.role === 'Partner' && (
                    <div className="client-actions">
                        <Link to={`/clients/${id}/edit`} className="client-action-button edit-button">
                            <FiEdit2 /> Edit Client
                        </Link>
                        <Link to={`/clients/${client.id}/wip`} className="client-action-button wip-button-c">
                            <FiList /> WIP Queue
                        </Link>
                        <Link to={`/clients/${client.id}/payments`} className="client-action-button payment-button">
                            <FiCreditCard /> Record Payment
                        </Link>
                        <Link to={`/clients/${client.id}/activity-types`} className="client-action-button settings-button">
                            <FiSettings /> Manage Activities
                        </Link>
                    </div>
                )}
            </div>

            <div className="client-info-toggle" onClick={() => setShowClientInfo(!showClientInfo)}>
                <span>Client Information</span>
                {showClientInfo ? <FiChevronUp /> : <FiChevronDown />}
            </div>

            {showClientInfo && (
                <div className="client-detail-card">
                    <div className="client-info-grid">
                        <div className="info-section contact-info">
                            <h3 className="section-title"><FiUser /> Contact Information</h3>
                            <div className="info-grid">
                                <div className="info-item">
                                    <label>Main Contact</label>
                                    <span>{client.main_contact || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>Main Email</label>
                                    <span>{client.main_email || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>Billing Contact</label>
                                    <span>{client.billing_contact || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>Billing Email</label>
                                    <span>{client.billing_email || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>Phone Number</label>
                                    <span>{client.phone || 'N/A'}</span>
                                </div>
                            </div>
                        </div>

                        <div className="info-section address-info">
                            <h3 className="section-title"><FiMapPin /> Address</h3>
                            <div className="info-grid">
                                <div className="info-item">
                                    <label>Address</label>
                                    <span>{client.address || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>City</label>
                                    <span>{client.city || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>State</label>
                                    <span>{client.state || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>Zip Code</label>
                                    <span>{client.zip_code || 'N/A'}</span>
                                </div>
                            </div>
                        </div>

                        <div className="info-section business-info">
                            <h3 className="section-title"><FiTag /> Business Information</h3>
                            <div className="info-grid">
                                <div className="info-item">
                                    <label>Start Date</label>
                                    <span>{formatDate(client.start_date) || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>Status</label>
                                    <span className={`status-badge status-${client.status?.toLowerCase()}`}>
                                        {client.status || 'N/A'}
                                    </span>
                                </div>
                                <div className="info-item">
                                    <label>Group</label>
                                    <span>{client.group || 'N/A'}</span>
                                </div>
                                <div className="info-item">
                                    <label>EIN</label>
                                    <span>{client.ein || 'N/A'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className="client-tabs">
                <button 
                    className={`tab-button ${activeTab === 'summary' ? 'active' : ''}`}
                    onClick={() => setActiveTab('summary')}
                >
                    <FiDollarSign /> Summary
                </button>
                <button 
                    className={`tab-button ${activeTab === 'timeEntries' ? 'active' : ''}`}
                    onClick={() => setActiveTab('timeEntries')}
                >
                    <FiClock /> Time & Expenses
                </button>
                <button 
                    className={`tab-button ${activeTab === 'invoices' ? 'active' : ''}`}
                    onClick={() => setActiveTab('invoices')}
                >
                    <FiFileText /> Invoices
                </button>
                <button 
                    className={`tab-button ${activeTab === 'payments' ? 'active' : ''}`}
                    onClick={() => setActiveTab('payments')}
                >
                    <FiCreditCard /> Payments
                </button>
            </div>

            <div className="tab-content">
                {activeTab === 'summary' && (
                    <div className="summary-tab">
                        <div className="summary-cards">
                            <div className="summary-card">
                                <div className="summary-card-header">
                                    <FiClock className="summary-icon" />
                                    <span>Time Entries</span>
                                </div>
                                <div className="summary-value time-value">
                                    {formatCurrency(totalTimeEntries)}
                                </div>
                                <div className="summary-footer">
                                    <span>{timeEntries.length} entries</span>
                                </div>
                            </div>
                            
                            <div className="summary-card">
                                <div className="summary-card-header">
                                    <FiFileText className="summary-icon" />
                                    <span>Invoices</span>
                                </div>
                                <div className="summary-value invoice-value">
                                    {formatCurrency(totalInvoices)}
                                </div>
                                <div className="summary-footer">
                                    <span>{invoices.length} invoices</span>
                                </div>
                            </div>
                            
                            <div className="summary-card">
                                <div className="summary-card-header">
                                    <FiCreditCard className="summary-icon" />
                                    <span>Payments</span>
                                </div>
                                <div className="summary-value payment-value">
                                    {formatCurrency(totalPayments)}
                                </div>
                                <div className="summary-footer">
                                    <span>{payments.length} payments</span>
                                </div>
                            </div>
                            
                            <div className="summary-card">
                                <div className="summary-card-header">
                                    <FiDollarSign className="summary-icon" />
                                    <span>WIP</span>
                                </div>
                                <div className="summary-value wip-value">
                                    {formatCurrency(wipAmount)}
                                </div>
                                <div className="summary-footer">
                                    <span>Outstanding balance</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {activeTab === 'timeEntries' && (
                    <div className="time-entries-tab">
                        <div className="tab-header">
                            <h2 className="tab-title">Time Entries</h2>
                            <div className="tab-actions">
                                <button 
                                    className="refresh-button"
                                    onClick={fetchTimeEntries}
                                    disabled={timeEntriesLoading}
                                >
                                    <FiRefreshCw className={timeEntriesLoading ? "spinner" : ""} /> 
                                    Refresh
                                </button>
                                
                                <button 
                                    className="toggle-button-c"
                                    onClick={() => setShowAllTimeEntries(!showAllTimeEntries)}
                                >
                                    {showAllTimeEntries ? (
                                        <>
                                            <FiClock /> Show Active Entries
                                        </>
                                    ) : (
                                        <>
                                            <FiList /> Show All Entries
                                        </>
                                    )}
                                </button>
                                
                                <Link to={`/time_entries/create`} className="add-button">
                                    <FiPlusCircle /> New Entry
                                </Link>
                            </div>
                        </div>
                        
                        <div className="client-search-container">
                            <form onSubmit={handleTimeEntrySearch}>
                                <div className="search-input-group">
                                    <FiSearch className="search-icon" />
                                    <input
                                        type="text"
                                        placeholder="Search by activity, user, or ID..."
                                        value={timeEntrySearch}
                                        onChange={(e) => setTimeEntrySearch(e.target.value)}
                                        className="search-input"
                                    />
                                    {timeEntrySearch && (
                                        <button 
                                            type="button" 
                                            className="clear-search" 
                                            onClick={() => setTimeEntrySearch('')}
                                        >
                                            <FiX />
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                        
                        {timeEntriesLoading ? (
                            <div className="loading-container">
                                <FiRefreshCw className="spinner" />
                                <p>Loading time entries...</p>
                            </div>
                        ) : timeEntries.length === 0 ? (
                            <div className="empty-state">
                                <FiClock className="empty-icon" />
                                <h3>No Time Entries</h3>
                                <p>There are no time entries for this client.</p>
                            </div>
                        ) : filteredTimeEntries.length === 0 ? (
                            <div className="empty-state">
                                {timeEntrySearch ? (
                                    <>
                                        <FiSearch className="empty-icon" />
                                        <h3>No Matching Time Entries</h3>
                                        <p>Try adjusting your search criteria.</p>
                                        <button 
                                            className="show-all-button"
                                            onClick={() => setTimeEntrySearch('')}
                                        >
                                            Clear Search
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <FiCheck className="empty-icon" />
                                        <h3>No Active Time Entries</h3>
                                        <p>There are no time entries with open contracts.</p>
                                        <button 
                                            className="show-all-button"
                                            onClick={() => setShowAllTimeEntries(true)}
                                        >
                                            Show All Entries
                                        </button>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="table-container">
                                <table className="data-table time-table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Activity</th>
                                            <th>Date</th>
                                            <th>User</th>
                                            <th>Status</th>
                                            {user?.role === 'Partner' && <th>Actions</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredTimeEntries.map(entry => (
                                            <tr key={entry.id} className={`table-row ${entry.invoice?.id ? 'closed-row' : ''}`}>
                                                <td>{entry.id}</td>
                                                <td>
                                                    <span className="activity-badge">
                                                        <FiTag className="badge-icon" />
                                                        {entry.activity_type?.name}
                                                    </span>
                                                </td>
                                                <td>{formatDate(entry.start_date) || 'N/A'}</td>
                                                <td>
                                                    <span className="user-badge">
                                                        <FiUser className="badge-icon" />
                                                        {entry.user?.username}
                                                    </span>
                                                </td>
                                                <td>
                                                    <span className={`status-pill ${entry.invoice?.id ? 'billed' : 'open'}`}>
                                                        {entry.invoice?.id ? 'Billed' : 'Open'}
                                                    </span>
                                                </td>
                                                {user?.role === 'Partner' && (
                                                    <td>
                                                        <button 
                                                            className="table-action-button"
                                                            disabled={entry.invoice?.id}
                                                            onClick={() => navigate(`/time_entries/${entry.id}/edit`)}
                                                        >
                                                            <FiEdit2 /> Edit
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}

                {activeTab === 'invoices' && (
                    <div className="invoices-tab">
                        <div className="tab-header">
                            <h2 className="tab-title">Invoices</h2>
                            <div className="tab-actions">
                                <button 
                                    className="refresh-button"
                                    onClick={fetchInvoices}
                                    disabled={invoicesLoading}
                                >
                                    <FiRefreshCw className={invoicesLoading ? "spinner" : ""} /> 
                                    Refresh
                                </button>
                                
                                <button 
                                    className="toggle-button-c"
                                    onClick={() => setShowAllInvoices(!showAllInvoices)}
                                >
                                    {showAllInvoices ? (
                                        <>
                                            <FiClock /> Show Active Invoices
                                        </>
                                    ) : (
                                        <>
                                            <FiList /> Show All Invoices
                                        </>
                                    )}
                                </button>
                                
                                {user?.role === 'Partner' && (
                                    <Link to={`/clients/${client.id}/wip`} className="add-button">
                                        <FiPlusCircle /> New Invoice
                                    </Link>
                                )}
                            </div>
                        </div>
                        
                        <div className="client-search-container">
                            <form onSubmit={handleInvoiceSearch}>
                                <div className="search-input-group">
                                    <FiSearch className="search-icon" />
                                    <input
                                        type="text"
                                        placeholder="Search by invoice ID..."
                                        value={invoiceSearch}
                                        onChange={(e) => setInvoiceSearch(e.target.value)}
                                        className="search-input"
                                    />
                                    {invoiceSearch && (
                                        <button 
                                            type="button" 
                                            className="clear-search" 
                                            onClick={() => setInvoiceSearch('')}
                                        >
                                            <FiX />
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                        
                        {invoicesLoading ? (
                            <div className="loading-container">
                                <FiRefreshCw className="spinner" />
                                <p>Loading invoices...</p>
                            </div>
                        ) : invoices.length === 0 ? (
                            <div className="empty-state">
                                <FiFileText className="empty-icon" />
                                <h3>No Invoices</h3>
                                <p>There are no invoices for this client.</p>
                            </div>
                        ) : filteredInvoices.length === 0 ? (
                            <div className="empty-state">
                                {invoiceSearch ? (
                                    <>
                                        <FiSearch className="empty-icon" />
                                        <h3>No Matching Invoices</h3>
                                        <p>Try adjusting your search criteria.</p>
                                        <button 
                                            className="show-all-button"
                                            onClick={() => setInvoiceSearch('')}
                                        >
                                            Clear Search
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <FiCheck className="empty-icon" />
                                        <h3>No Active Invoices</h3>
                                        <p>There are no invoices with open contracts.</p>
                                        <button 
                                            className="show-all-button"
                                            onClick={() => setShowAllInvoices(true)}
                                        >
                                            Show All Invoices
                                        </button>
                                    </>
                                )}
                            </div>
                        ) : (
                            <div className="table-container">
                                <table className="data-table invoice-table">
                                    <thead>
                                        <tr>
                                            <th>Invoice ID</th>
                                            <th>Status</th>
                                            <th>Total</th>
                                            <th colSpan="2">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredInvoices.map(invoice => (
                                            <tr key={invoice.id} className="table-row">
                                                <td>{invoice.id}</td>
                                                <td>
                                                    <span className={`status-pill ${invoice.payments_complete ? 'completed' : 'in-progress'}`}>
                                                        {invoice.payments_complete ? 'Completed' : 'In Progress'}
                                                    </span>
                                                </td>
                                                <td className="amount-cell">
                                                    {invoice.total !== null ? formatCurrency(invoice.total) : 'N/A'}
                                                </td>
                                                <td>
                                                    <button 
                                                        className="table-action-button view-button"
                                                        onClick={() => navigate(`/invoices/${invoice.id}`)}
                                                    >
                                                        <FiFileText /> View
                                                    </button>
                                                </td>
                                                <td>
                                                    <Link to={`/invoices/${invoice.id}/edit`} className="table-action-button">
                                                        <FiEdit2 /> Edit
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}

                {activeTab === 'payments' && (
                    <div className="payments-tab">
                        <div className="tab-header">
                            <h2 className="tab-title">Payments</h2>
                            <div className="tab-actions">
                                <button 
                                    className="refresh-button"
                                    onClick={fetchPayments}
                                    disabled={paymentsLoading}
                                >
                                    <FiRefreshCw className={paymentsLoading ? "spinner" : ""} /> 
                                    Refresh
                                </button>
                                
                                {user?.role === 'Partner' && (
                                    <Link to={`/clients/${client.id}/payments`} className="add-button">
                                        <FiPlusCircle /> New Payment
                                    </Link>
                                )}
                            </div>
                        </div>
                        
                        <div className="client-search-container">
                            <form onSubmit={handlePaymentSearch}>
                                <div className="search-input-group">
                                    <FiSearch className="search-icon" />
                                    <input
                                        type="text"
                                        placeholder="Search by payment ID or invoice ID..."
                                        value={paymentSearch}
                                        onChange={(e) => setPaymentSearch(e.target.value)}
                                        className="search-input"
                                    />
                                    {paymentSearch && (
                                        <button 
                                            type="button" 
                                            className="clear-search" 
                                            onClick={() => setPaymentSearch('')}
                                        >
                                            <FiX />
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                        
                        {paymentsLoading ? (
                            <div className="loading-container">
                                <FiRefreshCw className="spinner" />
                                <p>Loading payments...</p>
                            </div>
                        ) : payments.length === 0 ? (
                            <div className="empty-state">
                                <FiCreditCard className="empty-icon" />
                                <h3>No Payments</h3>
                                <p>There are no payments on any invoice.</p>
                            </div>
                        ) : filteredPayments.length === 0 ? (
                            <div className="empty-state">
                                <FiSearch className="empty-icon" />
                                <h3>No Matching Payments</h3>
                                <p>Try adjusting your search criteria.</p>
                                <button 
                                    className="show-all-button"
                                    onClick={() => setPaymentSearch('')}
                                >
                                    Clear Search
                                </button>
                            </div>
                        ) : (
                            <div className="table-container">
                                <table className="data-table payment-table">
                                    <thead>
                                        <tr>
                                            <th>Payment ID</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th>Invoice ID</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPayments.map(payment => (
                                            <tr key={payment.id} className="table-row">
                                                <td>{payment.id}</td>
                                                <td className="amount-cell">
                                                    {formatCurrency(payment.payment_amount)}
                                                </td>
                                                <td>{formatDate(payment.created_at)}</td>
                                                <td>
                                                    <Link to={`/invoices/${payment.invoice.id}/edit`} className="invoice-link">
                                                        #{payment.invoice.id}
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={`/payments/${payment.id}/edit`} className="table-action-button">
                                                        <FiEdit2 /> Edit
                                                    </Link>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    ) : null;
};

export default Client;