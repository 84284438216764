import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNotify } from '../context/NotificationContext';
import '../styles/UserForm.css';
import {
  FiUser,
  FiMail,
  FiLock,
  FiCalendar,
  FiDollarSign,
  FiPlus,
  FiTrash2,
  FiSave,
  FiArrowLeft,
  FiUnlock,
  FiAlertTriangle,
  FiShield,
  FiUserCheck,
  FiTag
} from 'react-icons/fi';

const UserForm = () => {
  const { userId } = useParams();
  const { error, success, spinner } = useNotify();
  const { getCookie, APP_URL } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      id: userId || '',
      username: '',
      password: '',
      email: '',
      firstName: '',
      lastName: '',
      status: 'Active',
      startDate: '',
      permissions: 'Staff',
      locked: false,
      lockedReason: '',
      loginAttempts: 0,
      billingRates: [],
    },
    validationSchema: Yup.object({
      id: Yup.string().required('ID is required'),
      username: Yup.string()
        .min(5, 'Username must be at least 5 characters')
        .max(25, 'Username must be at most 25 characters')
        .required('Username is required'),
      password: userId
        ? Yup.string().min(5, 'Password must be at least 5 characters').max(25, 'Password must be at most 25 characters')
        : Yup.string()
          .min(5, 'Password must be at least 5 characters')
          .max(25, 'Password must be at most 25 characters')
          .required('Password is required'),
      email: Yup.string().email('Invalid email address').required('Email is required'),
      firstName: Yup.string(),
      lastName: Yup.string(),
      status: Yup.string(),
      startDate: Yup.date(),
      permissions: Yup.string().oneOf(['Staff', 'Staff Mgr', 'Partner'], 'Invalid permissions').required('Permissions are required'),
      locked: Yup.boolean(),
      loginAttempts: Yup.number().min(0, 'Login attempts cannot be negative'),
      billingRates: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().required('Rate name is required'),
            value: Yup.number().min(0, 'Value must be a positive number').required('Value is required'),
          })
        )
        .min(1, 'At least one billing rate is required'),
    }),
    onSubmit: (values) => {
      setIsLoading(true);
      const userData = {
        id: values.id,
        username: values.username,
        password: values.password,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        status: values.status,
        start_date: values.startDate,
        role: values.permissions,
        billing_rates: values.billingRates,
        locked: values.locked,
      };

      const method = userId ? 'PATCH' : 'POST';
      const url = userId ? `${APP_URL}/users/${userId}` : `${APP_URL}/users`;
      const message = userId ? 'Updating user...' : 'Creating user...';

      const { complete } = spinner(message);

      fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token'),
        },
        body: JSON.stringify(userData),
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then(() => {
              complete(true, userId ? 'User updated successfully!' : 'User created successfully!');
              setTimeout(() => {
                navigate('/users/manage');
              }, 1000);
            });
          } else {
            complete(false);
            error(resp);
          }
          setIsLoading(false);
        })
        .catch((e) => {
          complete(false);
          error(e);
          setIsLoading(false);
        });
    },
  });

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      const { complete } = spinner('Loading user data...');

      fetch(`${APP_URL}/users/${userId}`, {
        credentials: 'include',
      })
        .then((resp) => {
          if (resp.ok) {
            resp.json().then((data) => {
              formik.setValues({
                id: data.id,
                username: data.username,
                email: data.email,
                firstName: data.first_name || '',
                lastName: data.last_name || '',
                status: data.status,
                startDate: data.start_date || '',
                permissions: data.role,
                billingRates: data.rates || [],
                locked: data.locked || false,
                loginAttempts: data.login_attempts || 0,
                password: '' // Clear password field for security
              });
              complete(true, 'User data loaded');
              setIsLoading(false);
            });
          } else {
            complete(false);
            error(resp);
            setIsLoading(false);
            navigate('/users/manage');
          }
        })
        .catch((e) => {
          complete(false);
          error(e);
          setIsLoading(false);
          navigate('/users/manage');
        });
    } else {
      // Initialize with at least one billing rate for new users
      if (formik.values.billingRates.length === 0) {
        handleAddRate();
      }
    }
  }, [userId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleAddRate = () => {
    formik.setValues({
      ...formik.values,
      billingRates: [...formik.values.billingRates, { name: '', value: 0 }],
    });
  };

  const handleRemoveRate = (indexToRemove) => {
    formik.setValues({
      ...formik.values,
      billingRates: formik.values.billingRates.filter((_, index) => index !== indexToRemove),
    });
  };

  const handleToggleLock = () => {
    formik.setValues({
      ...formik.values,
      locked: !formik.values.locked,
      lockedReason: formik.values.locked ? '' : formik.values.lockedReason,
    });
  };


  return (
    <div className="user-form-container">
      <div className="user-form-header">
        <button
          className="back-button"
          onClick={() => navigate('/users/manage')}
          aria-label="Go back"
        >
          <FiArrowLeft />
        </button>
        <h2 className="user-form-title">
          {userId ? 'Edit User' : 'Create New User'}
        </h2>
      </div>

      <div className="user-form-card">
        <form onSubmit={formik.handleSubmit}>
          <div className="form-sections">
            <div className="form-section">
              <div className="form-section account-security-section">
                <h3 className="section-title">
                  <FiLock className="section-icon" />
                  Account Security
                </h3>

                <div className="form-group account-lock-toggle">
                  <label className="form-label">
                    <FiShield className="form-icon" />
                    Account Status
                  </label>
                  <div className="lock-toggle-container">
                    <button
                      type="button"
                      className={`lock-toggle-button ${formik.values.locked ? 'locked' : 'unlocked'}`}
                      onClick={handleToggleLock}
                    >
                      {formik.values.locked ? (
                        <>
                          <FiLock className="lock-icon" /> Locked
                        </>
                      ) : (
                        <>
                          <FiUnlock className="unlock-icon" /> Unlocked
                        </>
                      )}
                    </button>
                    {formik.values.loginAttempts > 0 && (
                      <div className="login-attempts-badge">
                        <FiAlertTriangle /> {formik.values.loginAttempts} Failed Attempts
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <h3 className="section-title">User Information</h3>

              <div className="form-group">
                <label htmlFor="id" className="form-label">
                  <FiTag className="form-icon" />
                  User ID
                </label>
                <input
                  type="text"
                  className={`form-input ${formik.touched.id && formik.errors.id ? 'input-error' : ''}`}
                  id="id"
                  name="id"
                  value={formik.values.id}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={userId ? true : false}
                  placeholder="Enter user ID"
                />
                {formik.touched.id && formik.errors.id && (
                  <div className="error-message">{formik.errors.id}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="username" className="form-label">
                  <FiUser className="form-icon" />
                  Username
                </label>
                <input
                  type="text"
                  className={`form-input ${formik.touched.username && formik.errors.username ? 'input-error' : ''}`}
                  id="username"
                  name="username"
                  value={formik.values.username}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter username"
                />
                {formik.touched.username && formik.errors.username && (
                  <div className="error-message">{formik.errors.username}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="password" className="form-label">
                  <FiLock className="form-icon" />
                  Password
                </label>
                <input
                  type="password"
                  className={`form-input ${formik.touched.password && formik.errors.password ? 'input-error' : ''}`}
                  id="password"
                  name="password"
                  placeholder={userId ? 'Leave blank to keep current password' : 'Enter password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="error-message">{formik.errors.password}</div>
                )}
              </div>

              <div className="form-group">
                <label htmlFor="email" className="form-label">
                  <FiMail className="form-icon" />
                  Email Address
                </label>
                <input
                  type="email"
                  className={`form-input ${formik.touched.email && formik.errors.email ? 'input-error' : ''}`}
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Enter email address"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="error-message">{formik.errors.email}</div>
                )}
              </div>
            </div>

            <div className="form-section">
              <h3 className="section-title">Personal Details</h3>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="firstName" className="form-label">
                    First Name
                  </label>
                  <input
                    type="text"
                    className={`form-input ${formik.touched.firstName && formik.errors.firstName ? 'input-error' : ''}`}
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="First name"
                  />
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className="error-message">{formik.errors.firstName}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    className={`form-input ${formik.touched.lastName && formik.errors.lastName ? 'input-error' : ''}`}
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Last name"
                  />
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className="error-message">{formik.errors.lastName}</div>
                  )}
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="startDate" className="form-label">
                    <FiCalendar className="form-icon" />
                    Start Date
                  </label>
                  <input
                    type="date"
                    className={`form-input ${formik.touched.startDate && formik.errors.startDate ? 'input-error' : ''}`}
                    id="startDate"
                    name="startDate"
                    value={formik.values.startDate}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.startDate && formik.errors.startDate && (
                    <div className="error-message">{formik.errors.startDate}</div>
                  )}
                </div>

                <div className="form-group">
                  <label htmlFor="status" className="form-label">
                    <FiUserCheck className="form-icon" />
                    Status
                  </label>
                  <select
                    className={`form-select ${formik.touched.status && formik.errors.status ? 'input-error' : ''}`}
                    id="status"
                    name="status"
                    value={formik.values.status}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  {formik.touched.status && formik.errors.status && (
                    <div className="error-message">{formik.errors.status}</div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label htmlFor="permissions" className="form-label">
                  <FiShield className="form-icon" />
                  Role & Permissions
                </label>
                <select
                  className={`form-select ${formik.touched.permissions && formik.errors.permissions ? 'input-error' : ''}`}
                  id="permissions"
                  name="permissions"
                  value={formik.values.permissions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="Staff">Staff</option>
                  <option value="Staff Mgr">Staff Manager</option>
                  <option value="Partner">Partner</option>
                </select>
                {formik.touched.permissions && formik.errors.permissions && (
                  <div className="error-message">{formik.errors.permissions}</div>
                )}
              </div>
            </div>
          </div>

          <div className="form-section billing-rates-section">
            <h3 className="section-title">
              <FiDollarSign className="section-icon" />
              Billing Rates
            </h3>

            {formik.values.billingRates.length > 0 ? (
              <div className="billing-rates-list">
                <div className="billing-rate-header">
                  <div className="rate-name-header">Rate Name</div>
                  <div className="rate-value-header">Rate Value</div>
                  <div className="rate-action-header"></div>
                </div>

                {formik.values.billingRates.map((rate, index) => (
                  <div key={index} className="billing-rate-item">
                    <div className="rate-name-field">
                      <input
                        type="text"
                        className={`form-input ${formik.touched.billingRates?.[index]?.name &&
                            formik.errors.billingRates?.[index]?.name ? 'input-error' : ''
                          }`}
                        placeholder="Rate Name"
                        name={`billingRates[${index}].name`}
                        value={rate.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    <div className="rate-value-field">
                      <input
                        type="number"
                        className={`form-input ${formik.touched.billingRates?.[index]?.value &&
                            formik.errors.billingRates?.[index]?.value ? 'input-error' : ''
                          }`}
                        placeholder="Value"
                        name={`billingRates[${index}].value`}
                        value={rate.value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>

                    <div className="rate-action-field">
                      <button
                        type="button"
                        className="remove-rate-button"
                        onClick={() => handleRemoveRate(index)}
                        disabled={formik.values.billingRates.length <= 1}
                        aria-label="Remove rate"
                      >
                        <FiTrash2 />
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            ) : null}

            {formik.touched.billingRates && formik.errors.billingRates && (
              <div className="error-message billing-rates-error">
                {typeof formik.errors.billingRates === 'string'
                  ? formik.errors.billingRates
                  : 'Please check your billing rates information'}
              </div>
            )}

            <button
              type="button"
              className="add-rate-button"
              onClick={handleAddRate}
            >
              <FiPlus className="button-icon" />
              Add Billing Rate
            </button>
          </div>

          <div className="form-actions">
            <button
              type="button"
              className="cancel-button"
              onClick={() => navigate('/users/manage')}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="submit-button"
              disabled={isLoading}
            >
              <FiSave className="button-icon" />
              {userId ? 'Update User' : 'Create User'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserForm;