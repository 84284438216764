import React, { useState } from 'react';
import { useUtils } from '../context/UtilContext';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';

const ReportTable = ({ data }) => {
  const { formatCurrency, formatDate } = useUtils();
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  // Helper function to determine if a value should be formatted as currency
  const isCurrency = (key, value) => {
    const notNan = !isNaN(value) && key !== 'Client Name';
    
    return notNan;
  };

  // Helper function to determine if a value should be formatted as a date
  const isDate = (key, value) => {
    const dateTerms = ['date', 'created', 'modified', 'timestamp'];
    const hasDateTerm = dateTerms.some(term => key.toLowerCase().includes(term));
    
    // Simple check for ISO date format or similar
    const datePattern = /^\d{4}-\d{2}-\d{2}|^\d{2}\/\d{2}\/\d{4}/;
    return hasDateTerm && typeof value === 'string' && datePattern.test(value);
  };

  // Format cell value based on content type
  const formatCellValue = (key, value) => {
    if (value === null || value === undefined || value === "") return '-';
    
    if (isCurrency(key, value)) {
      return formatCurrency(value);
    } else if (isDate(key, value)) {
      return formatDate(value);
    } else if (typeof value === 'boolean') {
      return value ? 'Yes' : 'No';
    } else {
      return value;
    }
  };

  // Format table header from snake_case or camelCase to Title Case
  const formatHeader = (header) => {
    return header;
  };

  // Determine if a value is numeric for sorting purposes
  const isNumeric = (value) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };

  // Compare function for sorting
  const compareValues = (a, b, headerIndex, headers) => {
    const headerName = headers[headerIndex];
    
    // Get the raw values
    const valueA = a[headerIndex];
    const valueB = b[headerIndex];
    
    // Handle null/undefined/empty string cases
    if (valueA === null || valueA === undefined || valueA === "") return 1;
    if (valueB === null || valueB === undefined || valueB === "") return -1;
    
    // Check if the values are dates
    if (isDate(headerName, valueA) && isDate(headerName, valueB)) {
      return new Date(valueA) - new Date(valueB);
    }
    
    // Check if the values are numeric
    if (isNumeric(valueA) && isNumeric(valueB)) {
      return parseFloat(valueA) - parseFloat(valueB);
    }
    
    // Default string comparison
    return String(valueA).localeCompare(String(valueB));
  };

  // Request a sort by a particular column
  const requestSort = (key) => {
    let direction = 'asc';
    
    // If already sorted by this key, toggle the direction
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    
    setSortConfig({ key, direction });
  };
  
  // Get the sorted data
  const getSortedData = () => {
    if (!data || !data.data || !data.headers) {
      return [];
    }
    
    const sortableData = [...data.data];
    
    if (sortConfig.key !== null) {
      const headerIndex = data.headers.indexOf(sortConfig.key);
      
      if (headerIndex !== -1) {
        sortableData.sort((a, b) => {
          const comparison = compareValues(a, b, headerIndex, data.headers);
          return sortConfig.direction === 'asc' ? comparison : -comparison;
        });
      }
    }
    
    return sortableData;
  };

  // Calculate column widths as percentages for print layout
  const calculateColumnWidths = () => {
    if (!data || !data.headers) return [];
    
    const totalColumns = data.headers.length;
    const baseWidth = 100 / totalColumns;
    
    // Create an array of column width percentages
    return data.headers.map((header, index) => {
      // Adjust widths based on column content if needed
      // For example, give more space to text columns, less to numeric
      const isCurrencyColumn = data.data && data.data.length > 0 && 
                              isCurrency(header, data.data[0][index]);
      
      // Give currency columns slightly less width, text columns more
      if (isCurrencyColumn) {
        return baseWidth * 0.8; // 20% less than base
      } else if (header.toLowerCase().includes('name') || 
                header.toLowerCase().includes('description')) {
        return baseWidth * 1.3; // 30% more than base
      }
      
      return baseWidth; // Default width
    });
  };

  // Determine if we should render a standard table or a special layout
  const renderTable = () => {
    if (!data) {
      return <p className="no-data">No data available for this report.</p>;
    }

    const headers = data.headers;
    const sortedData = getSortedData();
    const columnWidths = calculateColumnWidths();

    return (
      <div className="report-table-container">
        <table className="report-table">
          <colgroup>
            {columnWidths.map((width, index) => (
              <col key={`col-${index}`} style={{ width: `${width}%` }} />
            ))}
          </colgroup>
          <thead>
            <tr>
              {headers.map((header, index) => (
                <th 
                  key={header} 
                  onClick={() => requestSort(header)}
                  className={sortConfig.key === header ? 'sort-active' : ''}
                  style={{ maxWidth: `${columnWidths[index]}%` }}
                >
                  <div className="th-content">
                    {formatHeader(header)}
                    {sortConfig.key === header ? (
                      sortConfig.direction === 'asc' ? 
                        <FiArrowUp className="sort-icon" /> : 
                        <FiArrowDown className="sort-icon" />
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sortedData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {headers.map((header, headerIndex) => (
                  <td 
                    key={`${rowIndex}-${header}`} 
                    className={isCurrency(header, row[headerIndex]) ? 'currency-cell' : ''}
                    style={{ maxWidth: `${columnWidths[headerIndex]}%` }}
                  >
                    {formatCellValue(header, row[headerIndex])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <div className="report-table-wrapper">
      {renderTable()}
    </div>
  );
};

export default ReportTable;