import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FiAlertCircle, FiDollarSign, FiCheckCircle, FiSearch, FiX } from 'react-icons/fi';
import { useNotify } from '../context/NotificationContext';
import { useAuth } from '../context/AuthContext';
import '../styles/InvoicePayment.css';

const InvoicePaymentPage = () => {
  const { clientId } = useParams();
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();
  const { error: notifyError, success } = useNotify();
  const { getCookie, APP_URL } = useAuth();
  const navigate = useNavigate();
  
  // Search functionality
  const [searchTerm, setSearchTerm] = useState('');
  const [showInvoiceResults, setShowInvoiceResults] = useState(false);
  const searchRef = useRef(null);

  // Handle clicks outside the search results
  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowInvoiceResults(false);
      }
    }
    
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Fetch invoices for the client
  const fetchClientInvoices = useCallback(async () => {
    try {
      setIsLoading(true);
      setError(null);
      const response = await fetch(`${APP_URL}/clients/${clientId}/invoices`, {
        credentials: 'include'
      });

      if (!response.ok) {
        error(response)
      }

      const data = await response.json();
      const fetchedInvoices = data || []; 

      
      if (fetchedInvoices.length === 0) {
        setError('No invoices available for this client.');
      }
      
      setInvoices(fetchedInvoices);

      // If invoice_id is in search params, pre-select the invoice
      const invoice_id = searchParams.get('invoice_id');
      if (invoice_id) {
        const invoice = fetchedInvoices.find(inv => inv.id == invoice_id);
        if (invoice) {
          setSelectedInvoice(invoice);
        }
      }
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
      notifyError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setIsLoading(false);
    }
  }, [clientId, APP_URL, notifyError, searchParams]);

  // Fetch invoices on component mount
  useEffect(() => {
    fetchClientInvoices();
  }, [fetchClientInvoices]);

  // Handle payment submission
  const handlePayment = async () => {
    if (!selectedInvoice || paymentAmount <= 0) {
      setError('Please select an invoice and enter a valid payment amount');
      return;
    }

    try {
      const response = await fetch(`${APP_URL}/payments`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-TOKEN': getCookie('csrf_access_token')
        },
        body: JSON.stringify({
          invoice_id: selectedInvoice.id,
          amount: parseFloat(paymentAmount.toFixed(2))
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        error(response)
      }

      success('Payment processed successfully!');
      
      // Reset form and navigate
      setSelectedInvoice(null);
      setPaymentAmount(0);
      navigate(`/clients/${clientId}`);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred during payment');
      notifyError(err instanceof Error ? err.message : 'An error occurred during payment');
    }
  };

  // Filter invoices based on search term
  const filteredInvoices = invoices.filter(invoice => 
    `Invoice ${invoice.id}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    invoice.total.toString().includes(searchTerm) ||
    invoice.remaining_total.toString().includes(searchTerm)
  );

  // Format currency
  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  // Format date (assuming there's a date property)
  const formatDate = (dateString) => {
    if (!dateString) return '';
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Handle selecting an invoice
  const handleSelectInvoice = (invoice) => {
    setSelectedInvoice(invoice);
    setShowInvoiceResults(false);
    // Optionally set the payment amount to the remaining balance
    setPaymentAmount(invoice.remaining_total);
  };

  // Clear selected invoice
  const clearSelectedInvoice = () => {
    setSelectedInvoice(null);
    setPaymentAmount(0);
    setSearchTerm('');
  };

  // Prevent payment if no invoice selected or payment amount is invalid
  const isPaymentDisabled = !selectedInvoice || paymentAmount <= 0 || 
    (selectedInvoice && paymentAmount > selectedInvoice.remaining_total);

  if (isLoading) {
    return (
      <div className="invoice-payment-loading">
        <FiDollarSign size={48} className="loading-icon" />
        <span>Loading invoices...</span>
      </div>
    );
  }

  return (
    <div className="invoice-payment-container">
      <div className="invoice-payment-header">
        <h2>Make a Payment</h2>
        <p>Search for an invoice and enter the payment amount</p>
      </div>

      {error && (
        <div className="invoice-payment-error">
          <FiAlertCircle size={20} />
          <span>{error}</span>
        </div>
      )}

      <form className="invoice-payment-form" onSubmit={(e) => {
        e.preventDefault();
        handlePayment();
      }}>
        <div className="invoice-payment-form-group search-group" ref={searchRef}>
          <label htmlFor="invoice-search">Search Invoice</label>
          
          {selectedInvoice ? (
            <div className="selected-invoice">
              <div className="selected-invoice-details">
                <div className="selected-invoice-header">
                  <span className="invoice-number">Invoice #{selectedInvoice.id}</span>
                  {selectedInvoice.date && (
                    <span className="invoice-date">{formatDate(selectedInvoice.date)}</span>
                  )}
                </div>
                <div className="selected-invoice-amounts">
                  <div className="amount-group">
                    <span className="amount-label">Total:</span>
                    <span className="amount-value">{formatCurrency(selectedInvoice.total)}</span>
                  </div>
                  <div className="amount-group">
                    <span className="amount-label">Remaining:</span>
                    <span className="amount-value remaining">{formatCurrency(selectedInvoice.remaining_total)}</span>
                  </div>
                </div>
              </div>
              <button 
                type="button" 
                className="clear-invoice-btn"
                onClick={clearSelectedInvoice}
                aria-label="Clear selected invoice"
              >
                <FiX />
              </button>
            </div>
          ) : (
            <div className="search-container">
              <div className="search-input-wrapper">
                <FiSearch className="search-icon" />
                <input
                  type="text"
                  id="invoice-search"
                  className="invoice-search-input"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onFocus={() => setShowInvoiceResults(true)}
                  placeholder="Search by invoice number or amount..."
                />
              </div>
              
              {showInvoiceResults && (
                <div className="invoice-search-results">
                  {filteredInvoices.length > 0 ? (
                    filteredInvoices.map(invoice => (
                      <div 
                        key={invoice.id} 
                        className="invoice-result-item"
                        onClick={() => handleSelectInvoice(invoice)}
                      >
                        <div className="invoice-result-header">
                          <span className="invoice-number">Invoice #{invoice.id}</span>
                          {invoice.date && (
                            <span className="invoice-date">{formatDate(invoice.date)}</span>
                          )}
                        </div>
                        <div className="invoice-result-amounts">
                          <span>Total: {formatCurrency(invoice.total)}</span>
                          <span>Remaining: {formatCurrency(invoice.remaining_total)}</span>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-results">No invoices match your search</div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>

        <div className="invoice-payment-form-group">
          <label htmlFor="payment-amount">Payment Amount</label>
          <div className="payment-input-wrapper">
            <FiDollarSign className="currency-icon" />
            <input
              type="number"
              id="payment-amount"
              className="invoice-payment-input"
              value={paymentAmount || ''}
              min="0"
              step="0.01"
              onChange={(e) => {
                const value = parseFloat(e.target.value);
                setPaymentAmount(isNaN(value) ? 0 : value);
              }}
              placeholder="Enter payment amount"
              disabled={!selectedInvoice}
            />
          </div>
          
          {selectedInvoice && (
            <div className="payment-controls">
              <p className="invoice-payment-hint">
                Max available: {formatCurrency(selectedInvoice.remaining_total)}
              </p>
              <button 
                type="button" 
                className="max-amount-btn"
                onClick={() => setPaymentAmount(selectedInvoice.remaining_total)}
              >
                Pay Full Amount
              </button>
            </div>
          )}
          
          {selectedInvoice && paymentAmount > selectedInvoice.remaining_total && (
            <p className="payment-error">
              Payment amount exceeds the remaining balance
            </p>
          )}
        </div>

        <button
          type="submit"
          className="invoice-payment-button"
          disabled={isPaymentDisabled}
        >
          <FiCheckCircle size={20} />
          Make Payment
        </button>
      </form>
    </div>
  );
};

export default InvoicePaymentPage;