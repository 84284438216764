import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/UserGuide.css';
import {
    FiSearch,
    FiChevronDown,
    FiChevronRight,
    FiUser,
    FiUsers,
    FiFileText,
    FiDollarSign,
    FiPieChart,
    FiSettings,
    FiLock,
    FiHome,
    FiClipboard,
    FiCreditCard,
    FiPrinter,
    FiBell,
    FiArrowUp,
    FiClock
} from 'react-icons/fi';
import { useAuth } from '../context/AuthContext';

const UserGuide = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const { user } = useAuth()
    const [expandedSections, setExpandedSections] = useState({
        'getting-started': true,
        'user-management': false,
        'client-management': false,
        'invoice-management': false,
        'reports': false,
        'settings': false,
        'time-tracking': false,
        'billing': false
    });

    const toggleSection = (section) => {
        setExpandedSections(prev => ({
            ...prev,
            [section]: !prev[section]
        }));
    };

    const formatId = (title) => {
        return title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
    };

    // Fixed filterContent function
    const filterContent = (searchTerm) => {
        if (!searchTerm) return true;

        const allElements = document.querySelectorAll('.guide-section h3, .guide-subsection h4, .guide-section p, .guide-subsection p, .step-content');
        let matchFound = false;

        // First pass: determine which sections contain matches
        const matchingSections = new Set();

        allElements.forEach(element => {
            if (!element || !element.textContent) return; // Skip if element is not valid

            const text = element.textContent.toLowerCase();
            const matches = text.includes(searchTerm.toLowerCase());

            if (matches) {
                // Get closest section or subsection
                let section = element;
                while (section && !section.classList.contains('guide-section') && !section.classList.contains('guide-subsection')) {
                    section = section.parentElement;
                }

                if (section) {
                    matchingSections.add(section);
                    matchFound = true;

                    // Expand parent section if it's a subsection
                    if (section.classList.contains('guide-subsection')) {
                        const parentSection = section.closest('.guide-section');
                        if (parentSection) {
                            const sectionId = parentSection.id;
                            matchingSections.add(parentSection);
                            setExpandedSections(prev => ({
                                ...prev,
                                [sectionId]: true
                            }));
                        }
                    }
                }
            }
        });

        // Second pass: show/hide sections and highlight text
        document.querySelectorAll('.guide-section, .guide-subsection').forEach(section => {
            if (matchingSections.has(section)) {
                section.style.display = 'block';
            } else {
                section.style.display = 'none';
            }
        });

        // Only highlight text in visible sections to avoid DOM manipulation issues
        allElements.forEach(element => {
            if (!element || !element.textContent) return; // Skip if element is not valid

            // Only process elements in visible sections
            let parentSection = element.closest('.guide-section, .guide-subsection');
            if (!parentSection || parentSection.style.display === 'none') return;

            const originalText = element.textContent;
            if (!originalText) return; // Skip if no text content

            // Check if this element's text contains the search term
            if (originalText.toLowerCase().includes(searchTerm.toLowerCase())) {
                try {
                    // Create a document fragment to build the highlighted content
                    const fragment = document.createDocumentFragment();
                    const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
                    const regex = new RegExp(escapedSearchTerm, 'gi');

                    let lastIdx = 0;
                    let match;

                    // Use a string copy for the regex operations to avoid modifying the element during iterations
                    const textCopy = originalText.slice();

                    while ((match = regex.exec(textCopy)) !== null) {
                        // Add text before match
                        if (match.index > lastIdx) {
                            fragment.appendChild(
                                document.createTextNode(textCopy.substring(lastIdx, match.index))
                            );
                        }

                        // Add highlighted match
                        const span = document.createElement('span');
                        span.className = 'search-highlight';
                        span.textContent = match[0];
                        fragment.appendChild(span);

                        lastIdx = match.index + match[0].length;
                    }

                    // Add any remaining text
                    if (lastIdx < textCopy.length) {
                        fragment.appendChild(
                            document.createTextNode(textCopy.substring(lastIdx))
                        );
                    }

                    // Clear and replace content
                    element.innerHTML = '';
                    element.appendChild(fragment);
                } catch (error) {
                    console.error('Error highlighting text:', error);
                    // If highlighting fails, restore original text
                    element.textContent = originalText;
                }
            }
        });

        return matchFound;
    };

    // Handle search function with appropriate error handling
    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchQuery(query);

        try {
            // Reset display of all sections
            document.querySelectorAll('.guide-section, .guide-subsection').forEach(section => {
                section.style.display = 'block';
            });

            // Clear previous highlights by restoring original text content
            document.querySelectorAll('.search-highlight').forEach(highlight => {
                if (highlight && highlight.parentNode) {
                    const parent = highlight.parentNode;
                    // Use textContent instead of innerHTML to prevent XSS
                    const text = highlight.textContent || '';
                    const textNode = document.createTextNode(text);
                    parent.replaceChild(textNode, highlight);
                }
            });

            if (query) {
                filterContent(query);
            }
        } catch (error) {
            console.error('Search error:', error);
            // In case of error, reset the display to show everything
            document.querySelectorAll('.guide-section, .guide-subsection').forEach(section => {
                section.style.display = 'block';
            });
        }
    };

    // Clear search function with improved error handling
    const clearSearch = () => {
        setSearchQuery('');

        try {
            // Reset display of all sections
            document.querySelectorAll('.guide-section, .guide-subsection').forEach(section => {
                section.style.display = 'block';
            });

            // Clear highlights by restoring original text
            document.querySelectorAll('.search-highlight').forEach(highlight => {
                if (highlight && highlight.parentNode) {
                    const parent = highlight.parentNode;
                    const text = highlight.textContent || '';
                    parent.replaceChild(document.createTextNode(text), highlight);
                    // Normalize the parent to merge adjacent text nodes
                    parent.normalize();
                }
            });
        } catch (error) {
            console.error('Error clearing search:', error);
            // Force page refresh as a last resort if clearing fails
            window.location.reload();
        }
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const isAdmin = (user?.role === "Partner" || user?.role === 'Staff Mgr')

    return (
        <div className="user-guide-container">
            <div className="guide-sidebar">
                <div className="guide-logo">
                    <FiClipboard className="guide-logo-icon" />
                    <h2>User Guide</h2>
                </div>

                <div className="guide-search">
                    <div className="search-input-container">
                        <FiSearch className="search-icon" />
                        <input
                            type="text"
                            placeholder="Search the guide..."
                            value={searchQuery}
                            onChange={handleSearch}
                            className="search-input"
                        />
                        {searchQuery && (
                            <button className="clear-search" onClick={clearSearch}>
                                ×
                            </button>
                        )}
                    </div>
                </div>

                <nav className="guide-nav">
                    <ul className="nav-list">
                        <li className="nav-item">
                            <a href="#getting-started" className="nav-link" onClick={() => toggleSection('getting-started')}>
                                {expandedSections['getting-started'] ? <FiChevronDown /> : <FiChevronRight />}
                                <FiHome className="nav-icon" />
                                <span>Getting Started</span>
                            </a>
                            {expandedSections['getting-started'] && (
                                <ul className="subnav-list">
                                    <li><a href="#logging-in">Logging In</a></li>
                                    <li><a href="#resetting-your-password">Resetting Your Password</a></li>
                                    <li><a href="#dashboard-overview">Dashboard Overview</a></li>
                                </ul>
                            )}
                        </li>

                        <li className="nav-item">
                            <a href="#time-tracking" className="nav-link" onClick={() => toggleSection('time-tracking')}>
                                {expandedSections['time-tracking'] ? <FiChevronDown /> : <FiChevronRight />}
                                <FiClock className="nav-icon" />
                                <span>Time Tracking</span>
                            </a>
                            {expandedSections['time-tracking'] && (
                                <ul className="subnav-list">
                                    <li><a href="#creating-time-entries">Creating Time Entries</a></li>
                                </ul>
                            )}
                        </li>

                        {isAdmin &&
                            <>

                                <li className="nav-item">
                                    <a href="#user-management" className="nav-link" onClick={() => toggleSection('user-management')}>
                                        {expandedSections['user-management'] ? <FiChevronDown /> : <FiChevronRight />}
                                        <FiUser className="nav-icon" />
                                        <span>User Management</span>
                                    </a>
                                    {expandedSections['user-management'] && (
                                        <ul className="subnav-list">
                                            <li><a href="#viewing-users">Viewing Users</a></li>
                                            <li><a href="#creating-a-new-user">Creating a New User</a></li>
                                            <li><a href="#editing-user-information">Editing User Information</a></li>
                                        </ul>
                                    )}
                                </li>


                                <li className="nav-item">
                                    <a href="#client-management" className="nav-link" onClick={() => toggleSection('client-management')}>
                                        {expandedSections['client-management'] ? <FiChevronDown /> : <FiChevronRight />}
                                        <FiUsers className="nav-icon" />
                                        <span>Client Management</span>
                                    </a>
                                    {expandedSections['client-management'] && (
                                        <ul className="subnav-list">
                                            <li><a href="#viewing-client-information">Viewing Client Information</a></li>
                                            <li><a href="#work-in-progress-wip-queue">Work in Progress (WIP) Queue</a></li>
                                            <li><a href="#creating-invoices">Creating Invoices</a></li>
                                        </ul>
                                    )}
                                </li>

                                <li className="nav-item">
                                    <a href="#billing" className="nav-link" onClick={() => toggleSection('billing')}>
                                        {expandedSections['billing'] ? <FiChevronDown /> : <FiChevronRight />}
                                        <FiDollarSign className="nav-icon" />
                                        <span>Client Billing</span>
                                    </a>
                                    {expandedSections['billing'] && (
                                        <ul className="subnav-list">
                                            <li><a href="#accessing-billing">Accessing Billing</a></li>
                                            <li><a href="#filtering-clients">Filtering Clients</a></li>
                                            <li><a href="#sorting-client-data">Sorting Data</a></li>
                                            <li><a href="#refreshing-data">Refreshing Data</a></li>
                                            <li><a href="#viewing-client-details">Client WIP Details</a></li>
                                            <li><a href="#managing-wip">Managing WIP</a></li>
                                        </ul>
                                    )}
                                </li>


                                <li className="nav-item">
                                    <a href="#invoice-management" className="nav-link" onClick={() => toggleSection('invoice-management')}>
                                        {expandedSections['invoice-management'] ? <FiChevronDown /> : <FiChevronRight />}
                                        <FiFileText className="nav-icon" />
                                        <span>Invoice Management</span>
                                    </a>
                                    {expandedSections['invoice-management'] && (
                                        <ul className="subnav-list">
                                            <li><a href="#creating-invoice-text-templates">Creating Invoice Templates</a></li>
                                            <li><a href="#managing-invoice-templates">Managing Templates</a></li>
                                            <li><a href="#recording-payments">Recording Payments</a></li>
                                            <li><a href="#editing-payment-information">Editing Payments</a></li>
                                        </ul>
                                    )}
                                </li>

                                <li className="nav-item">
                                    <a href="#reports" className="nav-link" onClick={() => toggleSection('reports')}>
                                        {expandedSections['reports'] ? <FiChevronDown /> : <FiChevronRight />}
                                        <FiPieChart className="nav-icon" />
                                        <span>Reports</span>
                                    </a>
                                    {expandedSections['reports'] && (
                                        <ul className="subnav-list">
                                            <li><a href="#generating-financial-reports">Generating Reports</a></li>
                                            <li><a href="#using-report-filters">Using Report Filters</a></li>
                                            <li><a href="#printing-and-saving-reports">Printing Reports</a></li>
                                        </ul>
                                    )}
                                </li>


                            </>
                        }


                        <li className="nav-item">
                            <a href="#settings" className="nav-link" onClick={() => toggleSection('settings')}>
                                {expandedSections['settings'] ? <FiChevronDown /> : <FiChevronRight />}
                                <FiSettings className="nav-icon" />
                                <span>Settings and Preferences</span>
                            </a>
                            {expandedSections['settings'] && (
                                <ul className="subnav-list">
                                    <li><a href="#customizing-your-experience">Customizing Experience</a></li>
                                    <li><a href="#notification-preferences">Notification Preferences</a></li>
                                    <li><a href="#account-settings">Account Settings</a></li>
                                </ul>
                            )}
                        </li>
                    </ul>
                </nav>
            </div>

            <div className="guide-content">
                <div className="guide-header">
                    <h1>Clockwise Application User Guide</h1>
                    <p className="guide-intro">Welcome to the Clockwise user guide. This comprehensive guide will help you navigate and use all features of the application efficiently.</p>
                </div>

                <section id="getting-started" className="guide-section">
                    <h2>
                        <FiHome className="section-icon" />
                        Getting Started
                    </h2>

                    <div id="logging-in" className="guide-subsection">
                        <h3>Logging In</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the login page by visiting the application URL or clicking the "Login" button from the landing page</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Enter your username and password in the respective fields</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Click the "Sign In" button to access your account</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Finally you'll be prompted to enter the verification code sent to your device, once entered you should be logged in</div>
                            </div>
                        </div>
                        <div className="info-note">
                            For security reasons, your account may be locked after multiple failed login attempts. Contact your administrator if you need to reset your account.
                        </div>
                    </div>

                    <div id="resetting-your-password" className="guide-subsection">
                        <h3>Resetting Your Password</h3>
                        <p>If you've forgotten your password, follow these steps:</p>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">From the login page, click the "Forgot Password?" link</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Enter your email address in the provided field</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Click "Send Reset Link"</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Check your email for the password reset link</div>
                            </div>
                            <div className="step">
                                <div className="step-number">5</div>
                                <div className="step-content">Click the link in your email to open the password reset page</div>
                            </div>
                            <div className="step">
                                <div className="step-number">6</div>
                                <div className="step-content">
                                    Create a new password that meets the strength requirements:
                                    <ul className="substep-list">
                                        <li>At least 5 characters long</li>
                                        <li>Include at least one uppercase letter</li>
                                        <li>Include at least one number</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">7</div>
                                <div className="step-content">Confirm your new password and click "Reset Password"</div>
                            </div>
                            <div className="step">
                                <div className="step-number">8</div>
                                <div className="step-content">You'll be redirected to the login page where you can sign in with your new password</div>
                            </div>
                        </div>
                    </div>

                    <div id="dashboard-overview" className="guide-subsection">
                        <h3>Dashboard Overview</h3>
                        <p>Upon logging in, you'll be taken to the dashboard, which provides an overview of your activities and data:</p>
                        <div className="feature-grid">
                            <div className="feature-card">
                                <div className="feature-icon"><FiHome /></div>
                                <div className="feature-title">Quick Actions</div>
                                <div className="feature-desc">Cards for frequently used functions such as time tracking, billing, and client management</div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon"><FiUsers /></div>
                                <div className="feature-title">Partners Section</div>
                                <div className="feature-desc">Displays your associated clients/partners</div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon"><FiClipboard /></div>
                                <div className="feature-title">Recent Activity</div>
                                <div className="feature-desc">Shows your most recent actions in the system</div>
                            </div>
                            <div className="feature-card">
                                <div className="feature-icon"><FiFileText /></div>
                                <div className="feature-title">Invoices</div>
                                <div className="feature-desc">Lists your latest invoices (for users with Partner role)</div>
                            </div>
                        </div>
                        <div className="tip-note">
                            Navigation options are available in the sidebar, which can be collapsed for a more compact view.
                        </div>
                    </div>
                </section>


                <section id="time-tracking" className="guide-section">
                    <h2>
                        <FiClock className="section-icon" />
                        Time Tracking
                    </h2>

                    <div id="creating-time-entries" className="guide-subsection">
                        <h3>Creating Time Entries</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Click the "Time Tracking" button in the sidebar to navigate to your time entries</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Press the "Create Time Entry" button located in the top right corner of the page</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">
                                    Fill in the required time entry information:
                                    <ul className="substep-list">
                                        <li>Select the client you performed work for from the dropdown menu</li>
                                        <li>Choose the appropriate activity type for the work completed</li>
                                        <li>Enter the date when the work was completed</li>
                                        <li>Input the number of hours spent on the task</li>
                                        <li>Select the billing rate to use for this time entry</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">If this is a chargeable time entry, the calculated total amount will appear automatically</div>
                            </div>
                            <div className="step">
                                <div className="step-number">5</div>
                                <div className="step-content">Review all information to ensure it is correct, paying special attention to the calculated total</div>
                            </div>
                            <div className="step">
                                <div className="step-number">6</div>
                                <div className="step-content">Click "Create Time Entry" to save the entry to the system</div>
                            </div>
                        </div>
                        <div className="tip-note">
                            Time entries should be created promptly to ensure accurate billing and work-in-progress (WIP) tracking.
                        </div>
                    </div>
                </section>

                <section id="user-management" className="guide-section">
                    <h2>
                        <FiUser className="section-icon" />
                        User Management
                    </h2>

                    <div id="viewing-users" className="guide-subsection">
                        <h3>Viewing Users</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the Users section via the sidebar or dashboard</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">The User Management page displays all users in a table format with key information</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Use the search bar at the top to filter users by name, ID, email, or role</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Toggle between table and card view using the view toggle button</div>
                            </div>
                        </div>
                    </div>

                    <div id="creating-a-new-user" className="guide-subsection">
                        <h3>Creating a New User</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">From the User Management page, click the "Create User" button</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">
                                    Fill in the required user information:
                                    <ul className="substep-list">
                                        <li>User ID (unique identifier)</li>
                                        <li>Username</li>
                                        <li>Password</li>
                                        <li>Role (Staff, Staff Manager, or Partner)</li>
                                        <li>Email address</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">
                                    Add optional information such as:
                                    <ul className="substep-list">
                                        <li>First and last name</li>
                                        <li>Start date</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">
                                    Set up billing rates:
                                    <ul className="substep-list">
                                        <li>Click "Add Billing Rate" to add a new rate</li>
                                        <li>Enter the rate name and value</li>
                                        <li>Add multiple rates if needed</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">5</div>
                                <div className="step-content">Click "Create User" to save the new user</div>
                            </div>
                        </div>
                    </div>

                    <div id="editing-user-information" className="guide-subsection">
                        <h3>Editing User Information</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">From the User Management page, locate the user you want to edit</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Click the "Edit" button for that user</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Update the user information as needed</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">
                                    To change the password, enter a new value in the password field
                                    <ul className="substep-list">
                                        <li>Leave blank to keep the current password</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">5</div>
                                <div className="step-content">Modify billing rates as needed</div>
                            </div>
                            <div className="step">
                                <div className="step-number">6</div>
                                <div className="step-content">Click "Update User" to save your changes</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="client-management" className="guide-section">
                    <h2>
                        <FiUsers className="section-icon" />
                        Client Management
                    </h2>

                    <div id="viewing-client-information" className="guide-subsection">
                        <h3>Viewing Client Information</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the Clients section via the sidebar</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Browse the list of clients displayed as cards or in a table</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Use the search functionality to find specific clients</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Click on a client card or the "View" button to see detailed information</div>
                            </div>
                        </div>
                    </div>

                </section>

                <section id="billing" className="guide-section">
                    <h2>
                        <FiDollarSign className="section-icon" />
                        Client Billing
                    </h2>

                    <div id="accessing-billing" className="guide-subsection">
                        <h3>Accessing the Billing Page</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the Billing section by clicking "Billing" in the sidebar menu</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">The billing page will display a list of all clients with their associated WIP amounts</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">At the top of the page, you'll see summary cards showing the total WIP amount and the total number of clients</div>
                            </div>
                        </div>
                    </div>

                    <div id="filtering-clients" className="guide-subsection">
                        <h3>Filtering and Searching Clients</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Use the Partner filter dropdown to view clients associated with a specific partner</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Enter text in the search box to filter clients by name, ID, or WIP amount</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Click the search button or press Enter to apply the search filter</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">The table will update to show only clients that match your search criteria</div>
                            </div>
                        </div>
                        <div className="tip-note">
                            You can combine the Partner filter with the search function to narrow down results further.
                        </div>
                    </div>

                    <div id="sorting-client-data" className="guide-subsection">
                        <h3>Sorting Client Data</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Click on any column header to sort the data by that column</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">The first click will sort in ascending order (A-Z, lowest to highest)</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Click the same column header again to sort in descending order (Z-A, highest to lowest)</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">The current sort column and direction is indicated by an arrow icon next to the column name</div>
                            </div>
                        </div>
                    </div>

                    <div id="refreshing-data" className="guide-subsection">
                        <h3>Refreshing and Reloading Data</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Use the "Refresh" button to update client data while maintaining your current filters and sort order</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">The refresh function retrieves the latest client data from the server and applies your current view settings</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Use the "Reload" button to completely reset the page and load fresh data from scratch</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">The "Load All" button can be used to load all clients, including those that might be paginated or limited by default</div>
                            </div>
                        </div>
                        <div className="info-note">
                            The "Last updated" timestamp at the top of the page shows when the data was last retrieved from the server.
                        </div>
                    </div>

                    <div id="viewing-client-details" className="guide-subsection">
                        <h3>Viewing Client WIP Details</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Click on any row in the client table to navigate to that client's detailed WIP page</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">On the client WIP page, you'll see a breakdown of time entries and charges</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Use the client WIP page to review totals and create invoices</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Return to the main billing page by using the sidebar menu</div>
                            </div>
                        </div>
                    </div>

                    <div id="viewing-client-details" className="guide-subsection">
                        <h3>Invoice Creation</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Once you have clicked a row on the billing page, it should take you to the clients wip queue</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">From here you can choose to progress bill or close out the client</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Progress billing and closing out a client both make invoices for a specified amount,
                                 the only difference is that closing out a client marks all of the time entries made since the last close bill marked as closed (Time entries are no longer included in the wip total after closeouts)</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Fill out the neccessary info (Invoice text, date, total) and then press the progress bill/ closeout bill button to create the invoice</div>
                            </div>
                            <div className="step">
                                <div className="step-number">5</div>
                                <div className="step-content">After creating an invoice you should be redirected to the invoice page where you can view the newly created invoice</div>
                            </div>
                        </div>
                    </div>

                    <div id="managing-wip" className="guide-subsection">
                        <h3>Managing Work in Progress (WIP)</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Regularly review the Client Billing page to monitor unbilled work across all clients</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Use the sorting function to identify clients with the highest WIP amounts that may need immediate billing</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Sort by Partner to review WIP amounts by responsible partner</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">The total WIP amount shown in the summary card helps track overall unbilled work</div>
                            </div>
                        </div>
                        <div className="tip-note">
                            Best practice is to review and manage WIP on a regular schedule to maintain consistent cash flow and avoid large unbilled balances.
                        </div>
                    </div>
                </section>

                <section id="invoice-management" className="guide-section">
                    <h2>
                        <FiFileText className="section-icon" />
                        Invoice Management
                    </h2>

                    <div id="creating-invoice-text-templates" className="guide-subsection">
                        <h3>Creating Invoice Text Templates</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to Invoice Texts section</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Click "Create Template" button</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">
                                    Enter the following information:
                                    <ul className="substep-list">
                                        <li>Template Name: A descriptive name for the template</li>
                                        <li>Template Text: The content to appear on invoices</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Click "Save Template" to create the template</div>
                            </div>
                        </div>
                    </div>

                    <div id="managing-invoice-templates" className="guide-subsection">
                        <h3>Managing Invoice Templates</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the Invoice Texts management page</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">View all available templates in the list</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Use the search bar to find specific templates</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">
                                    Use the action buttons to:
                                    <ul className="substep-list">
                                        <li>Preview: View the full template text</li>
                                        <li>Edit: Modify the template</li>
                                        <li>Delete: Remove the template</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="recording-payments" className="guide-subsection">
                        <h3>Recording Payments</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the relevant client</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Click "Record Payment"</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Select the correct invoice from the dropdown</div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Enter the payment amount</div>
                            </div>
                            {/* <div className="step">
                <div className="step-number">5</div>
                <div className="step-content">Select the payment date</div>
              </div> */}
                            <div className="step">
                                <div className="step-number">5</div>
                                <div className="step-content">Click "Save Payment" to record the transaction</div>
                            </div>
                        </div>
                    </div>

                    <div id="editing-payment-information" className="guide-subsection">
                        <h3>Editing Payment Information</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the payments section or find the payment through the associated invoice</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Click on the payment you want to edit</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">
                                    Update the payment information:
                                    <ul className="substep-list">
                                        <li>Payment amount</li>
                                        <li>Payment date</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Review the invoice summary information for context</div>
                            </div>
                            <div className="step">
                                <div className="step-number">5</div>
                                <div className="step-content">Click "Save Changes" to update the payment</div>
                            </div>
                            <div className="step">
                                <div className="step-number">6</div>
                                <div className="step-content">To delete a payment, click "Delete Payment" and confirm the deletion</div>
                            </div>
                        </div>
                    </div>
                </section>

                <section id="reports" className="guide-section">
                    <h2>
                        <FiPieChart className="section-icon" />
                        Reports
                    </h2>

                    <div id="generating-financial-reports" className="guide-subsection">
                        <h3>Generating Financial Reports</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the Reports section via the sidebar</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">
                                    Select the type of report you want to generate:
                                    <ul className="substep-list">
                                        <li>Accounts Receivable Aging</li>
                                        <li>Invoice Journal</li>
                                        <li>Activity Type</li>
                                        <li>Profit and Loss</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">
                                    For most reports, specify a date range:
                                    <ul className="substep-list">
                                        <li>Use the quick filter buttons for common periods (week, month, quarter, year)</li>
                                        <li>Or set custom start and end dates</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">4</div>
                                <div className="step-content">Click "Generate Report" to create the report</div>
                            </div>
                        </div>
                    </div>

                    <div id="using-report-filters" className="guide-subsection">
                        <h3>Using Report Filters</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">After generating a report, you can sort the data by clicking on column headers</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Click once to sort ascending, click again to sort descending</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">Use the search function at the top to filter for specific information</div>
                            </div>
                        </div>
                    </div>

                    <div id="printing-and-saving-reports" className="guide-subsection">
                        <h3>Printing and Saving Reports</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">After generating a report, click the "Print Report" button</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">
                                    This will open the print dialog where you can:
                                    <ul className="substep-list">
                                        <li>Select your printer</li>
                                        <li>Save as PDF</li>
                                        <li>Adjust print settings</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">The printed version will exclude UI elements and navigation for a clean output</div>
                            </div>
                        </div>
                    </div>
                </section>

                <div id="accounts-receivable-ledger" className="guide-subsection">
                    <h3>Accounts Receivable Ledger</h3>
                    <p>The Accounts Receivable Ledger provides a detailed view of all client transactions, including invoices and payments.</p>
                    <div className="step-by-step">
                        <div className="step">
                            <div className="step-number">1</div>
                            <div className="step-content">Navigate to the Client Management section and select a client</div>
                        </div>
                        <div className="step">
                            <div className="step-number">2</div>
                            <div className="step-content">Click on the "AR Ledger" tab in the client detail view</div>
                        </div>
                        <div className="step">
                            <div className="step-number">3</div>
                            <div className="step-content">
                                Review the ledger entries which include:
                                <ul className="substep-list">
                                    <li>Invoice dates and numbers</li>
                                    <li>Invoice amounts</li>
                                    <li>Payment dates and amounts</li>
                                    <li>Running balance</li>
                                </ul>
                            </div>
                        </div>
                        <div className="step">
                            <div className="step-number">4</div>
                            <div className="step-content">Use the date filter to view transactions for a specific period</div>
                        </div>
                    </div>
                    <div className="info-note">
                        The Accounts Receivable Ledger is particularly useful for resolving payment discrepancies and providing clients with a complete transaction history.
                    </div>
                </div>

                <section id="settings" className="guide-section">
                    <h2>
                        <FiSettings className="section-icon" />
                        Settings and Preferences
                    </h2>

                    <div id="customizing-your-experience" className="guide-subsection">
                        <h3>Customizing Your Experience</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Navigate to the Settings page via the sidebar</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">
                                    In the Display Preferences section, you can toggle various options:
                                    <ul className="substep-list">
                                        <li>Long Format Dates: Switch between full date format and shorter format</li>
                                        <li>Tool Bar: Enable or disable the toolbar with quick access functions</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="account-settings" className="guide-subsection">
                        <h3>Account Settings</h3>
                        <div className="step-by-step">
                            <div className="step">
                                <div className="step-number">1</div>
                                <div className="step-content">Review your account information in the Account Information section</div>
                            </div>
                            <div className="step">
                                <div className="step-number">2</div>
                                <div className="step-content">Your username and role are displayed here</div>
                            </div>
                            <div className="step">
                                <div className="step-number">3</div>
                                <div className="step-content">To log out, use the Logout button in the Security section</div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="guide-footer">
                    <div className="footer-content">
                        <p>This walkthrough covers the main processes within the Clockwise application. For more detailed information on specific features, refer to the complete documentation or contact your system administrator.</p>
                        <p className="copyright">© 2025 Clockwise Application - All Rights Reserved</p>
                    </div>
                    <button className="back-to-top" onClick={scrollToTop}>
                        <FiArrowUp /> Back to Top
                    </button>
                </div>
            </div>
        </div>
    );
};

export default UserGuide;