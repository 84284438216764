import React, { useEffect, useState, useRef } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useNotify } from '../context/NotificationContext';
import Select from 'react-select';
import { useUtils } from '../context/UtilContext';
import '../styles/BillingPage.css';
import { 
  FiDollarSign, 
  FiRefreshCw, 
  FiSearch, 
  FiArrowUp, 
  FiArrowDown, 
  FiClock,
  FiFilter
} from 'react-icons/fi';

const Billing = () => {
    const { APP_URL } = useAuth();
    const { formatCurrency } = useUtils();
    const { error, spinner } = useNotify();
    const [clients, setClients] = useState([]);
    const [allClients, setAllClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [sortedData, setSortedData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { refresh } = useParams();
    const [selectedPartner, setSelectedPartner] = useState(null);
    const [sortConfig, setSortConfig] = useState({ key: 'wipAmount', direction: 'desc' });
    const [noEntriesMessage, setNoEntriesMessage] = useState('');
    const [lastFetched, setLastFetched] = useState(null);
    const isInitialLoadRef = useRef(true);
    const navigate = useNavigate();
    
    // Load initial data and restore filters
    useEffect(() => {
        if (isInitialLoadRef.current) {
            isInitialLoadRef.current = false;
            
            // First, restore any saved filter state
            const storedFilterState = sessionStorage.getItem('billingFilterState');
            if (storedFilterState) {
                const filterState = JSON.parse(storedFilterState);
                setSearchQuery(filterState.searchQuery || '');
                setSortConfig(filterState.sortConfig || { key: 'wipAmount', direction: 'desc' });
                if (filterState.selectedPartner) {
                    setSelectedPartner(filterState.selectedPartner);
                }
            }

            // Then load stored client data if available
            const storedSortedData = sessionStorage.getItem('billingFilteredData');
            const storedAllClients = sessionStorage.getItem('billingAllClients');
            const storedTimestamp = sessionStorage.getItem('lastFetched');
            
            if (storedSortedData && storedAllClients) {
                // We have both filtered and all data stored
                const parsedSortedData = JSON.parse(storedSortedData);
                const parsedAllClients = JSON.parse(storedAllClients);
                
                // Set the state with stored data first
                setSortedData(parsedSortedData);
                setClients(parsedAllClients);
                setAllClients(parsedAllClients);
                setLastFetched(storedTimestamp);
                setLoading(false);
                
                // Then fetch new data in the background to update the WIP amounts
                fetchAndUpdateExistingClients(parsedSortedData, parsedAllClients);
            } else {
                // No stored data, get fresh data
                getBillingData();
            }
        }
    }, []);
    
    // Save filter state when it changes
    useEffect(() => {
        if (searchQuery || sortConfig) {
            const filterState = {
                searchQuery,
                selectedPartner,
                sortConfig
            };
            sessionStorage.setItem('billingFilterState', JSON.stringify(filterState));
        }
    }, [searchQuery, selectedPartner, sortConfig]);
    
    // Save filtered data when it changes
    useEffect(() => {
        if (sortedData.length > 0) {
            sessionStorage.setItem('billingFilteredData', JSON.stringify(sortedData));
        }
    }, [sortedData]);

    // Main data fetch function for full reload
    const getBillingData = async (fetchAll = false) => {
        setLoading(true);
        const url = `${APP_URL}/clients?total=true${fetchAll ? '&all=true' : ''}`;
        
        const { complete } = spinner("Loading client data...");
        
        try {
            const resp = await fetch(url, { credentials: 'include' });
            if (!resp.ok) {
                error(resp)
            }
            
            const data = await resp.json();
            
            const formattedData = data.map(client => ({
                id: client.id,
                clientId: client.client_id,
                name: client.legal_name,
                partner: client.partner ? client.partner.username : 'N/A',
                wipAmount: client.wip
            }));

            // Store all clients for future filtering
            setClients(formattedData);
            setAllClients(formattedData);
            
            // Sort data with current sort configuration
            const sortedArray = [...formattedData].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            
            setSortedData(sortedArray);
            
            // Update sessionStorage
            sessionStorage.setItem('billingAllClients', JSON.stringify(formattedData));
            sessionStorage.setItem('billingFilteredData', JSON.stringify(sortedArray));
            
            const timestamp = new Date().toLocaleString();
            sessionStorage.setItem('lastFetched', timestamp);
            setLastFetched(timestamp);
            
            setLoading(false);
            complete(true, "Client data loaded successfully!");
        } catch (e) {
            error(e);
            complete(false);
            setLoading(false);
        }
    };

    // This function fetches new data but only updates existing clients
    const fetchAndUpdateExistingClients = async (filteredData, allClientsData) => {
        if (!filteredData || filteredData.length === 0) return;

        try {
            const url = `${APP_URL}/clients?total=true`;
            const response = await fetch(url, { credentials: 'include' });
            
            if (!response.ok) {
                error(response)
            }
            
            const data = await response.json();
            
            if (data.length === 0) {
                return;
            }
            
            // Format the new data
            const freshData = data.map(client => ({
                id: client.id,
                clientId: client.client_id,
                name: client.legal_name,
                partner: client.partner ? client.partner.username : 'N/A',
                wipAmount: client.wip
            }));
            
            // Update only the WIP amounts for existing clients
            const updatedFilteredData = filteredData.map(client => {
                const freshClient = freshData.find(c => c.id === client.id);
                if (freshClient) {
                    return { ...client, wipAmount: freshClient.wipAmount };
                }
                return client;
            });
            
            const updatedAllClients = allClientsData.map(client => {
                const freshClient = freshData.find(c => c.id === client.id);
                if (freshClient) {
                    return { ...client, wipAmount: freshClient.wipAmount };
                }
                return client;
            });
            
            // Sort the updated data according to the current sort config
            const sortedUpdatedData = [...updatedFilteredData].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
                if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
                return 0;
            });
            
            // Update state with the refreshed data
            setSortedData(sortedUpdatedData);
            setClients(updatedAllClients);
            setAllClients(updatedAllClients);
            
            // Update sessionStorage
            sessionStorage.setItem('billingFilteredData', JSON.stringify(sortedUpdatedData));
            sessionStorage.setItem('billingAllClients', JSON.stringify(updatedAllClients));
            
            const timestamp = new Date().toLocaleString();
            sessionStorage.setItem('lastFetched', timestamp);
            setLastFetched(timestamp);
            
        } catch (e) {
            console.error("Error updating client data:", e);
        }
    };

    const handleFetchAll = () => {
        getBillingData(true);
    };

    const handleFilterAndSort = (data) => {
        if (!data || data.length === 0) return;
        
        let filteredData = [...data];
        
        if (searchQuery) {
            filteredData = filteredData.filter(client =>
                (client.wipAmount?.toString() || '').includes(searchQuery) ||
                (client.name || '').toLowerCase().includes(searchQuery.toLowerCase()) ||
                (client.clientId || '').toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        if (selectedPartner && selectedPartner.value !== null) {
            filteredData = filteredData.filter(client => client.partner === selectedPartner.value);
        }

        const sortedArray = [...filteredData].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) return sortConfig.direction === 'asc' ? -1 : 1;
            if (a[sortConfig.key] > b[sortConfig.key]) return sortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });

        setSortedData(sortedArray);
        sessionStorage.setItem('billingFilteredData', JSON.stringify(sortedArray));
        setNoEntriesMessage(sortedArray.length === 0 ? 'No entries found' : '');
    };

    const handleSort = (key) => {
        const direction = sortConfig.key === key && sortConfig.direction === 'asc' ? 'desc' : 'asc';
        setSortConfig({ key, direction });
        
        // Apply the new sort configuration to the current data
        const newSortConfig = { key, direction };
        
        const sortedArray = [...sortedData].sort((a, b) => {
            if (a[newSortConfig.key] < b[newSortConfig.key]) return newSortConfig.direction === 'asc' ? -1 : 1;
            if (a[newSortConfig.key] > b[newSortConfig.key]) return newSortConfig.direction === 'asc' ? 1 : -1;
            return 0;
        });
        
        setSortedData(sortedArray);
        sessionStorage.setItem('billingFilteredData', JSON.stringify(sortedArray));
    };

    const handleSearch = () => {
        handleFilterAndSort(clients);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };

    // Generate partner options from all clients, not just filtered ones
    const partnerOptions = [
        { value: null, label: 'All Partners' },
        ...Array.from(new Set(allClients.map(client => client.partner)))
            .filter(partner => partner !== 'N/A' && partner)
            .map(partner => ({ value: partner, label: partner }))
    ];

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === 'asc' ? <FiArrowUp className="sort-icon" /> : <FiArrowDown className="sort-icon" />;
        }
        return null;
    };

    const handleReload = () => {
        // Use a completely different approach that doesn't depend on state or useEffect
        window.sessionStorage.removeItem('billingFilteredData');
        window.sessionStorage.removeItem('billingAllClients');
        window.sessionStorage.removeItem('billingFilterState');
        
        // Force browser reload to the same page to completely reset everything
        window.location.reload();
    };

    const handleRefresh = () => {
        // Refresh data without changing the filtered view
        fetchAndUpdateExistingClients(sortedData, clients);
    };

    // Calculate total WIP amount
    const totalWipAmount = sortedData.reduce((total, client) => total + (client.wipAmount || 0), 0);

    // Custom styles for the Select component
    const customSelectStyles = {
        control: (base) => ({
            ...base,
            height: '42px',
            borderRadius: '10px',
            borderColor: 'rgba(0, 0, 0, 0.1)',
            boxShadow: 'none',
            '&:hover': {
                borderColor: 'var(--primary-color, #4a6cf7)'
            }
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected 
                ? 'var(--primary-color, #4a6cf7)' 
                : state.isFocused 
                    ? 'var(--primary-light, #e8effd)'
                    : null,
            color: state.isSelected ? 'white' : 'inherit'
        })
    };

    return (
        <div className="billing-container">
            <div className="billing-header">
                <div className="billing-title-section">
                    <div className="billing-title-container">
                        <FiDollarSign className="billing-icon" />
                        <h1 className="billing-title">Client Billing</h1>
                    </div>
                    <p className="billing-subtitle">Manage and view all client WIP amounts</p>
                </div>
                
                <div className="billing-actions">
                    <button 
                        className="refresh-button" 
                        onClick={handleReload}
                        disabled={loading}
                    >
                        <FiRefreshCw className="button-icon" /> Reload
                    </button>
                    <button 
                        className="refresh-button"
                        onClick={handleRefresh}
                        disabled={loading}
                    >
                        <FiRefreshCw className="button-icon" /> Refresh
                    </button>
                    <button 
                        className="refresh-button" 
                        onClick={handleFetchAll}
                        disabled={loading}
                    >
                        {loading ? "Loading..." : "Load All"}
                    </button>
                </div>
            </div>
            
            {lastFetched && (
                <div className="last-updated">
                    <FiClock /> Last updated: {lastFetched}
                </div>
            )}

            <div className="billing-filters">
                <div className="filter-container">
                    <div className="filter-group">
                        <label className="filter-label">
                            <FiFilter /> Partner
                        </label>
                        <Select
                            options={partnerOptions}
                            value={selectedPartner || partnerOptions[0]}
                            onChange={(option) => {
                                setSelectedPartner(option);
                                // Apply filter immediately when partner changes
                                setTimeout(() => handleFilterAndSort(clients), 0);
                            }}
                            placeholder="Select Partner"
                            className="partner-select"
                            styles={customSelectStyles}
                        />
                    </div>
                    
                    <div className="filter-group search-group">
                        <label className="filter-label">
                            <FiSearch /> Search
                        </label>
                        <div className="search-container">
                            <input
                                type="text"
                                className="search-input"
                                placeholder="Search by name, ID or amount"
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <button className="search-button" onClick={handleSearch}>
                                <FiSearch />
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {noEntriesMessage ? (
                <div className="no-data-message">
                    <div className="message-icon">!</div>
                    <p>{noEntriesMessage}</p>
                </div>
            ) : (
                <div className="billing-data">
                    <div className="billing-summary">
                        <div className="summary-card">
                            <div className="summary-title">Total WIP Amount</div>
                            <div className="summary-value">{formatCurrency(totalWipAmount)}</div>
                        </div>
                        <div className="summary-card">
                            <div className="summary-title">Total Clients</div>
                            <div className="summary-value">{sortedData.length}</div>
                        </div>
                    </div>

                    <div className="billing-table-container">
                        <table className="billing-table">
                            <thead>
                                <tr>
                                    <th onClick={() => handleSort('clientId')}>
                                        <div className="th-content">
                                            Client ID {getSortIcon('clientId')}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('name')}>
                                        <div className="th-content">
                                            Name {getSortIcon('name')}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('partner')}>
                                        <div className="th-content">
                                            Partner {getSortIcon('partner')}
                                        </div>
                                    </th>
                                    <th onClick={() => handleSort('wipAmount')}>
                                        <div className="th-content">
                                            WIP Amount {getSortIcon('wipAmount')}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {sortedData.map((entry) => (
                                    <tr 
                                        key={entry.clientId} 
                                        onClick={() => navigate(`/clients/${entry.id}/wip`)}
                                        className="data-row"
                                    >
                                        <td>{entry.clientId}</td>
                                        <td>{entry.name}</td>
                                        <td>{entry.partner}</td>
                                        <td className="amount-cell">{formatCurrency(entry.wipAmount)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Billing;