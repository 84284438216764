import React from "react";
import { useNavigate } from "react-router-dom";
import { FiLock, FiAlertTriangle, FiArrowRight, FiHome } from "react-icons/fi";
import "../styles/AccessDenied.css";

const AccessDenied = () => {
  const navigate = useNavigate();

  return (
    <div className="access-denied-container">
      <div className="access-denied-card">
        <div className="access-denied-icon">
          <FiLock size={48} />
        </div>
        
        <div className="access-denied-content">
          <h1 className="access-denied-title">Access Denied</h1>
          
          <div className="access-denied-message">
            <FiAlertTriangle className="alert-icon" />
            <p>You don't have permission to view this page</p>
          </div>
          
          <p className="access-denied-description">
            This area requires administrative privileges. Please log in with an 
            administrator account or contact your system administrator to request access.
          </p>
          
          <div className="access-denied-actions">
            <button 
              className="login-button-a"
              onClick={() => navigate('/login?popup=true')}
            >
              <span>Log In</span>
              <FiArrowRight />
            </button>
            
            <button 
              className="home-button"
              onClick={() => navigate('/')}
            >
              <FiHome />
              <span>Return to Home</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessDenied;