import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useNotify } from '../context/NotificationContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { useUtils } from '../context/UtilContext';
import '../styles/TimeEntryForm.css';
import {
    FiClock,
    FiCalendar,
    FiDollarSign,
    FiUser,
    FiTag,
    FiSave,
    FiTrash2,
    FiAlertTriangle
} from 'react-icons/fi';

const TimeEntryForm = () => {
    const { id } = useParams();
    const { error, success, choice, spinner } = useNotify();
    const [clients, setClients] = useState([]);
    const [activityTypes, setActivityTypes] = useState([]);
    const [isCompanyAccount, setIsCompanyAccount] = useState(false);
    const [rates, setRates] = useState([]);
    const { getCookie, APP_URL, user } = useAuth();
    const { formatDate, formatCurrency } = useUtils();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    // Create validation schema
    const validationSchema = Yup.object({
        hours: Yup.number()
            .min(0, 'Hours Spent must be greater than or equal to 0')
            .required('Hours Spent is required'),
        startDate: Yup.date()
            .required('Start Date is required'),
        client: Yup.object()
            .nullable()
            .required('Client is required'),
        activityType: Yup.object()
            .nullable()
            .required('Activity Type is required'),
        rate: Yup.object()
            .nullable()
            .required('Rate is required')
    });

    // Initialize formik
    const formik = useFormik({
        initialValues: {
            hours: '',
            startDate: null, // Set today as default
            client: null,
            activityType: null,
            rate: null
        },
        validationSchema,
        onSubmit: handleFormSubmit
    });

    // Form submission logic
    function handleFormSubmit(values) {
        const payload = {
            client_id: values.client.value,
            hours: values.hours,
            start_date: values.startDate,
            activity_type_id: values.activityType.value,
            rate_id: values.rate.value
        };

        setIsLoading(true);
        const { complete } = spinner(id ? 'Updating time entry...' : 'Creating time entry...');

        fetch(`${APP_URL}/time_entries${id ? '/' + id : ''}`, {
            method: id ? 'PATCH' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-TOKEN': getCookie('csrf_access_token')
            },
            body: JSON.stringify(payload),
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    error(resp)
                }
            })
            .then(data => {
                complete(true, id ? 'Time entry updated!' : 'Time entry created!');
                navigate('/profile');
            })
            .catch(err => {
                complete(false);
                error(err.message || err);
            })
            .finally(() => setIsLoading(false));
    }

    // Check if entry date is within the current sheet week
    const handleSubmit = (e) => {
        e.preventDefault();

        // Validate form
        const errors = Object.keys(formik.errors);
        if (errors.length > 0 && formik.touched[errors[0]]) {
            return formik.handleSubmit(e);
        }

        const startDate = new Date(formik.values.startDate);
        const currentFriday = new Date();
        currentFriday.setDate(currentFriday.getDate() + (5 - currentFriday.getDay()));

        const previousSaturday = new Date(currentFriday);
        previousSaturday.setDate(currentFriday.getDate() - 6);

        if (startDate < previousSaturday || startDate > currentFriday) {
            choice(
                'This entry is outside of the current sheet week. Do you want to continue?',
                () => formik.handleSubmit(e)
            );
        } else {
            formik.handleSubmit(e);
        }
    };

    // Handle time entry deletion
    const handleDeleteTimeEntry = () => {
        choice('Are you sure you want to delete this time entry?', confirmDelete);
    };

    const confirmDelete = () => {
        setIsLoading(true);
        const { complete } = spinner('Deleting time entry...');

        fetch(`${APP_URL}/time_entries/${id}`, {
            method: 'DELETE',
            headers: {
                'X-CSRF-TOKEN': getCookie('csrf_access_token'),
            },
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    complete(true, 'Time entry deleted!');;
                    navigate('/profile');
                } else {
                    error(resp)
                }
            })
            .catch(err => {
                complete(false);
                error(err.message || err);
            })
            .finally(() => setIsLoading(false));
    };

    // Load existing time entry data
    useEffect(() => {
        if (id && window.location.pathname.endsWith('/edit')) {
            setIsEditing(true);
            const { complete } = spinner('Loading time entry...');
            setIsLoading(true);

            fetch(`${APP_URL}/time_entries/${id}`, {
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json();
                    } else {
                        error(resp)
                    }
                })
                .then(data => {
                    formik.setValues({
                        startDate: data.start_date,
                        hours: data.hours,
                        client: {
                            value: data.client.id,
                            label: `${data.client.legal_name} - ${data.client.client_id}`
                        },
                        activityType: {
                            value: data.activity_type.id,
                            label: data.activity_type.name,
                            chargeType: data.activity_type.charge_type
                        },
                        rate: {
                            value: data.rate.id,
                            label: `${data.rate.name} - ${formatCurrency(data.rate.value)}`
                        }
                    });
                    complete(true, '', true);
                })
                .catch(err => {
                    complete(false);
                    error(err.message || err);
                    navigate('/error');
                })
                .finally(() => setIsLoading(false));
        }

        // Load all clients
        fetchClients();

        // Load user rates
        if (user?.id) {
            fetchRates();
        }
    }, [id, user?.id]);

    // Fetch clients
    const fetchClients = () => {
        fetch(`${APP_URL}/clients`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    error(resp)
                }
            })
            .then(data => {
                setClients(data);
                success(isEditing ? 'Time entry loaded' : 'Clients loaded');

            })
            .catch(err => {
                error(err.message || err);
                navigate('/error');
            });
    };

    // Fetch rates
    const fetchRates = () => {
        fetch(`${APP_URL}/users/${user.id}`, {
            credentials: 'include'
        })
            .then(resp => {
                if (resp.ok) {
                    return resp.json();
                } else {
                    error(resp)
                }
            })
            .then(data => {
                setRates(data.rates);
            })
            .catch(err => {
                error(err.message || err);
            });
    };

    // Fetch activity types when client changes
    // Replace the activity types fetch effect with this updated code
    useEffect(() => {
        if (formik.values.client?.value !== undefined) {

            fetch(`${APP_URL}/clients/${formik.values.client.value}/activity-types`, {
                credentials: 'include'
            })
                .then(resp => {
                    if (resp.ok) {
                        return resp.json();
                    } else {
                        error(resp)
                    }
                })
                .then(data => {
                    // Make sure received data is an array
                    const activities = Array.isArray(data) ? data : [];
                    setActivityTypes(activities);

                    // Reset activity type when client changes, but only if we have a valid activity type
                    if (formik.values.activityType && formik.values.activityType.value) {
                        // Check if the current activity type exists in the new client's activity types
                        const activityExists = activities.length > 0 &&
                            activities.some(type => type.id === formik.values.activityType.value);

                        if (!activityExists) {
                            formik.setFieldValue('activityType', null);
                        }
                    }
                })
                .catch(err => {
                    error(err.message || err);
                    setActivityTypes([]); // Set empty array on error
                });
        } else {
            setActivityTypes([]);
        }
    }, [formik.values.client]);

    // Prepare options for select dropdowns
    const clientOptions = clients?.map(client => ({
        value: client.id,
        label: `${client.legal_name} - ${client.client_id}`
    }));

    // Move company account to the top if it exists
    if (clientOptions && clientOptions.length > 0) {
        const companyIndex = clientOptions.findIndex(option => option.value === 0);
        if (companyIndex !== -1) {
            const companyOption = clientOptions.splice(companyIndex, 1)[0];
            clientOptions.unshift(companyOption);
        }
    }

    const activityTypeOptions = activityTypes?.map(type => ({
        value: type.id,
        label: type.name,
        chargeType: type.charge_type
    }));

    const rateOptions = rates?.map(rate => ({
        value: rate.id,
        label: `${rate.name} - ${formatCurrency(rate.value)}`
    }));

    // Calculate total amount
    const calculateTotal = () => {
        if (
            formik.values.activityType && formik.values.activityType.chargeType &&
            formik.values.rate &&
            formik.values.hours
        ) {
            const selectedRate = rates.find(rate => rate.id === formik.values.rate.value);
            if (selectedRate) {
                return formatCurrency(formik.values.hours * selectedRate.value);
            }
        }
        return 'N/A';
    };

    // Custom styles for react-select
    const customSelectStyles = {
        control: (base, state) => ({
            ...base,
            borderColor: state.isFocused
                ? 'var(--primary-color, #4a6cf7)'
                : base.borderColor,
            boxShadow: state.isFocused
                ? '0 0 0 1px var(--primary-color, #4a6cf7)'
                : base.boxShadow,
            '&:hover': {
                borderColor: state.isFocused
                    ? 'var(--primary-color, #4a6cf7)'
                    : 'var(--primary-color, #4a6cf7)'
            }
        }),
        option: (base, state) => ({
            ...base,
            backgroundColor: state.isSelected
                ? 'var(--primary-color, #4a6cf7)'
                : state.isFocused
                    ? 'var(--primary-light, #e8effd)'
                    : base.backgroundColor,
            color: state.isSelected ? 'white' : base.color
        })
    };

    return (
        <div className="time-entry-container">
            <div className="time-entry-header">
                <div className="header-title-container">
                    <FiClock className="header-icon" />
                    <h1 className="header-title">
                        {isEditing ? 'Edit Time Entry' : 'Create Time Entry'}
                    </h1>
                </div>
                <p className="header-subtitle">
                    {isEditing
                        ? 'Update the details of your time entry'
                        : 'Record time spent on client activities'}
                </p>
            </div>

            <form onSubmit={handleSubmit} className="time-entry-form">
                <div className="form-grid">
                    <div className="form-group">
                        <label htmlFor="client" className="form-label">
                            <FiUser className="field-icon" /> Client
                        </label>
                        <Select
                            id="client"
                            name="client"
                            options={clientOptions}
                            value={formik.values.client}
                            onChange={(selectedOption) => {
                                formik.setFieldValue('client', selectedOption);
                                formik.setFieldValue('activityType', null);
                            }}
                            onBlur={() => formik.setFieldTouched('client', true)}
                            placeholder="Select a client"
                            isDisabled={isLoading}
                            className={`form-select ${formik.touched.client && formik.errors.client ? 'is-invalid' : ''}`}
                            styles={customSelectStyles}
                        />
                        {formik.touched.client && formik.errors.client && (
                            <div className="error-message">{formik.errors.client}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="activityType" className="form-label">
                            <FiTag className="field-icon" /> Activity Type
                        </label>
                        <Select
                            id="activityType"
                            name="activityType"
                            options={activityTypeOptions}
                            value={formik.values.activityType}
                            onChange={(selectedOption) =>
                                formik.setFieldValue('activityType', selectedOption)
                            }
                            onBlur={() => formik.setFieldTouched('activityType', true)}
                            placeholder="Select an activity type"
                            isDisabled={!formik.values.client || isLoading}
                            className={`form-select ${formik.touched.activityType && formik.errors.activityType ? 'is-invalid' : ''}`}
                            styles={customSelectStyles}
                        />
                        {formik.touched.activityType && formik.errors.activityType && (
                            <div className="error-message">{formik.errors.activityType}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="rate" className="form-label">
                            <FiDollarSign className="field-icon" /> Rate
                        </label>
                        <Select
                            id="rate"
                            name="rate"
                            options={rateOptions}
                            value={formik.values.rate}
                            onChange={(selectedOption) =>
                                formik.setFieldValue('rate', selectedOption)
                            }
                            onBlur={() => formik.setFieldTouched('rate', true)}
                            placeholder="Select a rate"
                            isDisabled={isLoading}
                            className={`form-select ${formik.touched.rate && formik.errors.rate ? 'is-invalid' : ''}`}
                            styles={customSelectStyles}
                        />
                        {formik.touched.rate && formik.errors.rate && (
                            <div className="error-message">{formik.errors.rate}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="hours" className="form-label">
                            <FiClock className="field-icon" /> Hours Spent
                        </label>
                        <input
                            type="number"
                            id="hours"
                            name="hours"
                            className={`form-input ${formik.touched.hours && formik.errors.hours ? 'is-invalid' : ''}`}
                            value={formik.values.hours}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="Enter hours"
                            min="0"
                            step="0.25"
                            disabled={isLoading}
                        />
                        {formik.touched.hours && formik.errors.hours && (
                            <div className="error-message">{formik.errors.hours}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="startDate" className="form-label">
                            <FiCalendar className="field-icon" /> Date
                        </label>
                        <input
                            type="date"
                            id="startDate"
                            name="startDate"
                            className={`form-input ${formik.touched.startDate && formik.errors.startDate ? 'is-invalid' : ''}`}
                            value={formik.values.startDate}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            disabled={isLoading}
                        />
                        {formik.touched.startDate && formik.errors.startDate && (
                            <div className="error-message">{formik.errors.startDate}</div>
                        )}
                    </div>

                    <div className="form-group">
                        <label htmlFor="total" className="form-label">
                            <FiDollarSign className="field-icon" /> Total Amount
                        </label>
                        <input
                            type="text"
                            id="total"
                            name="total"
                            className="form-input total-input"
                            value={calculateTotal()}
                            readOnly
                            disabled
                        />
                    </div>
                </div>

                <div className="form-actions">
                    <button
                        type="submit"
                        className="submit-button"
                        disabled={isLoading}
                    >
                        <FiSave /> {isEditing ? 'Update Time Entry' : 'Create Time Entry'}
                    </button>

                    {isEditing && (
                        <button
                            type="button"
                            className="delete-button"
                            onClick={handleDeleteTimeEntry}
                            disabled={isLoading}
                        >
                            <FiTrash2 /> Delete Time Entry
                        </button>
                    )}
                </div>
            </form>
        </div>
    );
};

export default TimeEntryForm;